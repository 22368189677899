import React, { Component } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FastForwardIcon from "@material-ui/icons/FastForward";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import Nav from "../appNav";
import { AiFillBackward, AiFillForward } from "react-icons/ai";
import { withCookies } from "react-cookie";
import AirplayIcon from "@material-ui/icons/Airplay";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Cta from "../cta";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { getAllCampaigns } from "../../actions/campaigns";
import { loginFulflled } from "../../actions/login";
import { bindActionCreators } from "redux";
import CodeBlock from "react-copy-code";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Settings from "./settings";
import "highlight.js/styles/github-gist.css";
toast.configure();
function redirectToHome(props) {
  props.history.replace("/");
  toast.warn(
    "Hm. Seems like you don't have access yet. Please contact support."
  );
}

class AppSettings extends Component {
  constructor(props) {
    super();
    this.state = {
      shouldToggleAppear: false,
    };
  }
  componentDidMount() {
    const { isLoading, cookies } = this.props;
    if (
      !isLoading &&
      (!cookies.get("fd_account_guid") || !cookies.get("fd_account_api_key"))
    ) {
      redirectToHome(this.props);
    } else if (!isLoading) {
      // TODO - Make a wrapper function for this.
      let appOwner = {
        apiKey: cookies.get("fd_account_api_key"),
        guid: cookies.get("fd_account_guid"),
        appName: cookies.get("fd_account_name"),
        domain: cookies.get("fd_account_domain"),
      };

      let user = {
        email: cookies.get("fd_user_email"),
        givenName: cookies.get("fd_user_name"),
        imageUrl: cookies.get("fd_user_dp"),
      };
      this.props.loginFulflled({ data: appOwner, userDetails: user });
      // this.props.getAllCampaigns(appOwner);
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated, isLoading, cookies, user, appOwner } = this.props;
    if (!isLoading && !isAuthenticated) {
      redirectToHome(this.props);
    }
    if (!prevProps.isAuthenticated && isAuthenticated) {
      cookies.set("fd_user_email", user.email);
      cookies.set("fd_user_name", user.givenName);
      cookies.set("fd_user_dp", user.imageUrl);
      cookies.set("fd_account_name", appOwner.appName);
      cookies.set("fd_account_guid", appOwner.guid);
      cookies.set("fd_account_api_key", appOwner.apiKey);
      cookies.set("fd_account_domain", appOwner.domainName);
    }
  }

  render() {
    let { isLoading } = this.props;
    let width = this.props.currentState ? "6%" : "18%";
    let maxWidth = this.props.currentState ? "80px" : "260px";
    let mainWidth = "100%";
    return (
      <div className="container">
        {isLoading ? (
          <div>
            <Loader
              type="TailSpin"
              color="#6ad7dd"
              style={{ textAlign: "center" }}
              height={"60vh"}
              width={60}
            />
          </div>
        ) : (
          <>
            <Nav arena />
            <div>
              <main>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100vh",
                  }}
                >
                  <div
                    className="app-sidenav"
                    style={{ transition: "width 200ms, max-width 200ms", width: width, maxWidth: maxWidth }}
                    onMouseEnter={() =>
                      this.setState({
                        shouldToggleAppear: true,
                      })
                    }
                    onMouseLeave={() =>
                      this.setState({
                        shouldToggleAppear: false,
                      })
                    }
                  >
                    {this.state.shouldToggleAppear &&
                      (this.props.currentState ? (
                        <AiFillForward
                          style={{
                            fontSize: "1.2rem",
                            float: "right",
                            color: "rgb(87, 143, 255)",
                            fontSize: "1.2rem",
                            padding: 5,
                            background: "#fafafa",
                            borderRadius: 5,
                            margin: 5,
                            cursor: "pointer"
                          }}
                          onClick={this.props.toggle}
                        />
                      ) : (
                        <AiFillBackward
                          style={{
                            fontSize: "1.2rem",
                            float: "right",
                            color: "rgb(87, 143, 255)",
                            fontSize: "1.2rem",
                            padding: 5,
                            background: "#fafafa",
                            borderRadius: 5,
                            margin: 5,
                            cursor: "pointer"
                          }}
                          onClick={this.props.toggle}
                        />
                      ))}
                    <ul
                      style={{
                        listStyle: "none",
                        padding: "0px",
                        marginLeft: "-1px",
                      }}
                    >
                      <li
                        style={{
                          borderColor: "#578fff",
                          borderLeftWidth: "4px",
                          borderLeftStyle: "solid",
                          padding: "6px",
                        }}
                      >
                        <a
                          href="/app"
                          to="discover"
                          smooth={true}
                          spy={true}
                          className="docs"
                          style={{
                            color: "#757575",
                            display: "flex",
                            padding: "2px",
                          }}
                          activeClass="active"
                          offset={-70}
                          duration={500}
                        >
                          <img
                            style={{
                              fontSize: "1.2rem",
                              color: "rgb(43 109 185)",
                            }}
                            src="https://res.cloudinary.com/dfcnic8wq/image/upload/v1614076996/FlowDash/CampaignsIcon_wu8cuw.svg"
                          />
                          <span
                            style={{
                              marginLeft: "10px",
                              display: this.props.currentState
                                ? "none"
                                : "inline",
                            }}
                          >
                            Product tours
                          </span>
                        </a>
                      </li>
                      <li>
                        <div className="account-section">
                          <li
                            style={{
                              padding: "6px",
                            }}
                          >
                            <RouterLink
                              to="/app/settings/snippets"
                              className="docs"
                              style={{
                                color: "#757575",
                                display: "flex",
                                padding: "2px",
                              }}
                              activeClass="active"
                            >
                              <SettingsIcon
                                style={{
                                  fontSize: "1.2rem",
                                  color: "#757575",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "10px",
                                  display: this.props.currentState
                                    ? "none"
                                    : "inline",
                                }}
                              >
                                Settings
                              </span>
                            </RouterLink>
                          </li>
                          <li
                            style={{
                              padding: "6px",
                            }}
                          >
                            <RouterLink
                              to="/"
                              className="docs"
                              style={{
                                color: "#757575",
                                display: "flex",
                                padding: "2px",
                              }}
                              activeClass="active"
                            >
                              <ExitToAppIcon
                                style={{
                                  fontSize: "1.2rem",
                                  color: "#757575",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "10px",
                                  display: this.props.currentState
                                    ? "none"
                                    : "inline",
                                }}
                              >
                                Logout
                              </span>
                            </RouterLink>
                          </li>
                          {/* {!this.props.currentState && (
                            <li
                              style={{
                                padding: "6px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Cta />
                            </li>
                          )} */}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    style={{
                      height: "100%",
                      width: mainWidth,
                      transition: "width 200ms",
                      background: "rgb(250 250 250)",
                      overflowY: "scroll",
                    }}
                  >
                    <section className="topics-list">
                      <h3
                        style={{
                          textAlign: "left",
                          fontSize: "1.5em",
                          padding: "20px 20px 0px 65px",
                          color: "rgb(9, 7, 36)",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Settings
                      </h3>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Settings
                          toRender={this.props.toRender}
                          appOwner={this.props.appOwner}
                          user={this.props.user}
                        />
                      </div>
                    </section>
                  </div>
                </div>
              </main>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.loginData.isAuthenticated,
  isLoading: state.loginData.isLoading,
  user: state.loginData.user,
  appOwner: state.loginData.appOwner,
  currentState: state.sidebarState.currentState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllCampaigns,
      loginFulflled,
      toggle: () => ({
        type: "TOGGLE",
      }),
    },
    dispatch
  );

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AppSettings))
);
