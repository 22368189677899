import React from "react";
import { GoogleLogin } from "react-google-login";
import { useCookies } from "react-cookie";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Logo from "../../assets/FlowDash_Icon_2x.png";
import Footer from "../loginFooter";

import { login } from "../../actions/login";

function Login(props) {
  const [cookies, setCookie] = useCookies([
    "fd_user_email",
    "fd_user_name",
    "fd_user_dp",
    "fb_account_guid",
    "fd_account_api_key",
  ]);

  const seen = cookies.circuital_user_email;

  console.log(seen);

  if (seen && props.location.state) {
    let to = props.location.state.to;
    console.log(to);
    if (window.Acute) {
      window.Acute("identify", {
        user: {
          id: seen,
          email: seen,
        },
      });
    }
    if (window.analytics) {
      window.analytics.identify(cookies.circuital_user_id, {
        email: cookies.circuital_user_email,
        name: cookies.circuital_user_name,
        displayName: cookies.circuital_user_name,
        imageUrl:cookies.circuital_user_dp
      });
    }

    if (to === "/") {
      to = "/foundry";
    }
    if (typeof to !== "string") {
      to.state = { isAuthenticated: true };
    }
    return <Redirect to={to} />;
  }

  return (
    <div>
      <main className="login-container">
        <section
          className="intro"
          style={{
            background: "white",
            margin: "150px auto",
            borderRadius: "5px",
            gridTemplateColumns: "1fr",
            padding: "10px",
            boxShadow: "rgba(0,0,0,0.1) 0 5px 22px",
          }}
        >
          <h1
            style={{
              textAlign: "left",
              fontSize: "1.6em",
              margin: "15px 5px",
              color: "#67696b",
            }}
          >
            <span>
              <img
                src={Logo}
                style={{
                  width: "50px",
                  height: "50px",
                  margin: "2px 10px -10px",
                  padding: "0px 2px",
                }}
              ></img>
            </span>
          </h1>
          <h2
            style={{
              fontSize: "1.6em",
              color: "#18181a",
              lineHeight: "1",
              textAlign: "left",
            }}
          >
            Lets fix your long term retention ⚡️
          </h2>
          <div style={{ textAlign: "center", margin: "40px" }}>
            <GoogleLogin
              clientId="364913923871-rc3p0pqfa6u0f91o8tn27hcm7ccudp0a.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={(resp) => {
                props.signIn(() => {
                  props.login(resp, setCookie);
                  props.history.push("/app");
                });
              }}
              theme="dark"
              onFailure={(e) => console.log(e)}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
    },
    dispatch
  );

  export default connect(
    () => { },
    mapDispatchToProps
  )(Login);
