import React from "react";
import { Link } from "react-scroll";

import "../Styles/Header.css";

function Header() {
  return (
    <div className="header">
      <img
        src="https://res.cloudinary.com/dfcnic8wq/image/upload/v1583572454/FlowDash/FlowDash_Icon_2x_jdkq3s.png"
        alt=""
        className="logo__image"
      />
      <div className="title">Flowdash</div>
      <div className="nav">
        {/* <p className="nav__item">Docs</p> */}
        <a
          href="https://blog.flowdash.ai/onboarding-is-growth/"
          className="docs nav__item"
          activeClass="active"
        >
          Blog
        </a>
        {/* <p className='nav__item'>Contact Us</p> */}
        <a
          href="mailto:team@flowdash.ai?subject=I'm interested in FlowDash!"
          className="sign-up nav__item"
          activeClass="active"
        >
          Contact us
        </a>
        <Link
          className="nav__item1"
          to="CTA"
          smooth={true}
          spy={true}
          activeClass="active"
          offset={-70}
          duration={500}
        >
          Get Early Access
        </Link>
        {/* <p className="nav__item1"></p> */}
      </div>
    </div>
  );
}

export default Header;
