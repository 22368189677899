import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from '@material-ui/icons/Help';

export default function ContentEditor(props) {
  const {
    classes,
    currentCampaignDraft,
    currentStepIndex,
    handleStateChange,
    handleTextChange,
    currentScreenIndex
  } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
      }}
    >
      <div
        style={{
          flex: 1,
          padding: "0px 20px",
        }}
      >

        <FormGroup>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={currentCampaignDraft[currentStepIndex].isSpotlight}
                onChange={handleStateChange}
                color="default"
                name="isSpotlight"
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
              />
            }
            label="Spotlight type view"
          /> */}
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={currentCampaignDraft[currentStepIndex].isLinked}
                onChange={handleStateChange}
                color="default"
                name="isLinked"
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
              />
            }
            label="Attach to a View"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentCampaignDraft[currentStepIndex].isSkippable}
                onChange={handleStateChange}
                name="isSkippable"
                color="default"
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
              />
            }
            label="Skippable"
          /> */}
          {(currentStepIndex != 0 || currentScreenIndex != 0) &&

            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentCampaignDraft[currentStepIndex].showStepNumber}
                    onChange={handleStateChange}
                    name="showStepNumber"
                    color="default"
                    checkedIcon={
                      <span className={clsx(classes.icon, classes.checkedIcon)} />
                    }
                    icon={<span className={classes.icon} />}
                  />
                }
                label="Show current step number"
              />
              <Tooltip arrow title="Enable this to show the step number of the walkthrough">
                <HelpIcon style={{
                  padding: "5px",
                  color: 'rgb(172 172 172)',
                  cursor: "help",
                }} />
              </Tooltip>
            </div>}

          <div style={{
            display: "flex",
            alignItems: "center"
          }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentCampaignDraft[currentStepIndex].scrollToView}
                  onChange={handleStateChange}
                  name="scrollToView"
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                />
              }
              label="Scroll to this view"
            />
            <Tooltip arrow title="Enable this to scroll to the view which was selected">
              <HelpIcon style={{
                padding: "5px",
                color: 'rgb(172 172 172)',
                cursor: "help",
              }} />
            </Tooltip>
          </div>
        </FormGroup>
      </div>
      <div
        style={{
          flex: 1,
          margin: "40px 20px",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <TextField
          label="Primary text"
          onChange={(text) => {
            handleTextChange("primaryText", text);
          }}
          defaultValue={currentCampaignDraft[currentStepIndex].primaryText}
          helperText="Some nice words here"
          variant="outlined"
          value={currentCampaignDraft[currentStepIndex].primaryText}
          size="small"
          style={{
            margin: "20px 0px",
          }}
        />
        <TextField
          label="Secondary text"
          onChange={(text) => {
            handleTextChange("secondaryText", text);
          }}
          defaultValue={
            currentCampaignDraft[currentStepIndex].secondaryText ||
            "Default Value"
          }
          value={currentCampaignDraft[currentStepIndex].secondaryText}
          helperText="Nicer words here"
          variant="outlined"
          size="small"
          style={{
            margin: "20px 0px",
          }}
        />
        <TextField
          label="Skip text"
          onChange={(text) => {
            handleTextChange("skipAction", text);
          }}
          defaultValue={
            currentCampaignDraft[currentStepIndex].skipAction || "Default Value"
          }
          value={currentCampaignDraft[currentStepIndex].skipAction}
          size="small"
          variant="outlined"
          style={{
            margin: "20px 0px",
          }}
        />
        <TextField
          label="CTA text"
          onChange={(text) => {
            handleTextChange("actionText", text);
          }}
          defaultValue={
            currentCampaignDraft[currentStepIndex].actionText || "Default Value"
          }
          value={currentCampaignDraft[currentStepIndex].actionText}
          size="small"
          variant="outlined"
          style={{
            margin: "20px 0px",
          }}
        />
      </div>
    </div>
  );
}
