import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppNav from "../appNav";
import { withCookies, Cookies } from "react-cookie";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import PhoneMock from "../../assets/phoneMock2.png";
import EditCampaignTab from "../edit-campaign-tab/index";
import PreviewText from "./previewText";
import PreviewDiv from "./previewDiv";
import PreviewTooltip from "./previewTooltip";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from "@material-ui/core/Tooltip";

import {
  selectCampaign,
  selectCampaignStep,
  setCampaignClean,
  setCampaignDirty,
  saveCampaign,
  getAllCampaigns,
  editCurrentCampaignDraft,
  selectCampaignScreen,
  addOrRemoveIntroView
} from "../../actions/campaigns";

toast.configure();

class EditCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignData: null,
      pendingPublish: false,
    };
  }

  componentWillMount() {
    let { campaignId } = this.props.match.params;
    this.props.selectCampaign(campaignId);
  }

  componentDidUpdate(prevProps) {
    // On refresh, take it to the campaigns listing
    if (prevProps.isLoading && !this.props.isLoading && !this.props.campaign) {
      this.props.history.replace("/app");
    }
  }

  evaluate = (style) => {
    if (!style) return;
    for (let [key, value] of Object.entries(style)) {
      if (key == "top" || key == "left" || key == "right" || key == "bottom") {
        if (typeof value === "string") {
          value = value.replace(
            "this.state.focusedView",
            "this.props.currentCampaignDraft[this.props.currentStepIndex].style.focussedView"
          );
          value = value.replace(
            "this.state.focusedView",
            "this.props.currentCampaignDraft[this.props.currentStepIndex].style.focussedView"
          );

          console.log(key, value);
          console.log(this.props.currentCampaignDraft[this.props.currentStepIndex])
          style[key] = eval(value);
        }
      }
    }
    return style;
  };

  render() {
    const {
      campaign,
      isLoading,
      currentStepIndex,
      currentScreenIndex,
      isDirty,
      currentCampaignDraft,
      editCurrentCampaignDraft,
      selectCampaignScreen,
      addOrRemoveIntroView,
      type,
      pendingPublish
    } = this.props;

    let { campaignId } = this.props.match.params;
    // let tooltipStyle = { "actionView": { "backgroundColor": "#3d72dc", "display": "flex", "alignItems": "center", "justifyContent": "center", "width": 90, "height": 30, "borderRadius": 10 }, "wrapper": { "borderColor": "#65f4d4", "borderWidth": 2, "borderRadius": 5, "zIndex": 4 }, "actionText": { "textTransform": "uppercase", "color": "black", "fontWeight": "bold" }, "skipActionText": { "fontSize": 16, "color": "#65f4d4", "fontWeight": "bold" }, "skipAction": { "bottom": 58, "left": 15, "zIndex": 2, "fontSize": 16, "fontWeight": "bold" }, "primaryText": { "top": 113.61904999999999, "left": 17.142857, "color": "#747474", "fontSize": 16 }, "secondaryText": { "top": 113.61904999999999, "left": 17.142857, "color": "#747474", "fontSize": 16 }, "focussedView": { "top": 143.61905, "left": 17.142857, "width": 115.04762, "height": 115.04762 }, "indexView": { color: '#747474' } };

    return (
      <div className="container">
        <AppNav arena signOut={this.props.signOut} showPublish={true} pendingPublish={pendingPublish} />
        <div>
          <div style={{ margin: "auto" }}>
            {isLoading && (
              <Loader
                type="TailSpin"
                color="#6ad7dd"
                style={{ textAlign: "center" }}
                height={"60vh"}
                width={60}
              />
            )}
          </div>
          <main>
            {!isLoading && campaign && campaign.details && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr .8fr",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100vh",
                    background: "#fafcff",
                    marginTop: "35px",
                  }}
                >
                  <section className="topics-list">
                    {currentScreenIndex == 0 &&
                      <span className="footer-contact"
                        style={{
                          width: '135px',
                          background: 'rgb(181, 101, 91)',
                          borderRadius: '4px',
                          color: 'white',
                          marginLeft: 'auto',
                          marginRight: '45px',
                          textAlign: 'center'
                        }}
                        onClick={(campaign.details[0].steps[0].viewIndex != -5679) ?
                          () => addOrRemoveIntroView("add", campaignId) :
                          () => addOrRemoveIntroView("remove", campaignId)
                        }
                      >
                        {(campaign.details[0].steps[0].viewIndex != -5679) ? "Add intro view" : "Remove intro view"}
                      </span>
                    }
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '20px 20px'
                      }}
                    >
                      {currentScreenIndex > 0 ? <Tooltip arrow title={"Go to previous screen."}>
                        <ArrowBackIcon
                          style={{
                            cursor: 'pointer',
                            color: 'rgb(43 52 68)',
                            background: 'rgb(255 255 255)',
                            borderRadius: '50%',
                          }}
                          onClick={() => {
                            selectCampaignScreen(currentScreenIndex - 1);
                          }} />
                      </Tooltip> : <div> &nbsp;</div>}
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "1em",
                          padding: "10px 20px 10px 20px",
                          color: "rgb(9, 7, 36)",
                          fontWeight: "900",
                          background: '#e6e8eb',
                          borderRadius: '5px'
                        }}
                      >
                        {currentCampaignDraft[currentStepIndex].screenName}
                      </span>
                      {currentScreenIndex < (campaign.details.length - 1) ? <Tooltip arrow title={"Go to next screen."}>
                        <ArrowForwardIcon
                          style={{
                            cursor: 'pointer',
                            color: 'rgb(43 52 68)',
                            background: 'rgb(255 255 255)',
                            borderRadius: '50%',
                          }}
                          onClick={() => {
                            console.log("Next screen");
                            selectCampaignScreen(currentScreenIndex + 1);
                          }} />
                      </Tooltip> : <div> &nbsp;</div>}
                    </span>
                    {campaign.details && campaign.details[currentScreenIndex].steps.length > 1 && <div className="question-timeline">
                      <h4
                        style={{
                          color: "#999999",
                        }}
                      >
                        Step
                      </h4>
                      {
                        campaign.details[currentScreenIndex].steps.map((_, index) => {
                          return index == currentStepIndex ? (
                            <div className="question-index active-ques">
                              {index + 1}
                            </div>
                          ) : (
                              <div
                                onClick={() =>
                                  this.props.selectCampaignStep(index)
                                }
                                className={
                                  true
                                    ? "question-index"
                                    : this.state.selected[index]
                                      ? "question-index visited"
                                      : "question-index visited-not-selected"
                                }
                              >
                                {index + 1}
                              </div>
                            );
                        })}
                    </div>}
                    <div
                      style={{
                        margin: "40px auto 200px auto",
                        position: "relative",
                        width: "360px",
                        height: "560px",
                      }}
                    >
                      {type == 0 ?
                        <>
                          <PreviewTooltip
                            showIndexView={currentCampaignDraft[currentStepIndex].showStepNumber}
                            direction={currentCampaignDraft[currentStepIndex].tooltipDirection}
                            styleTooltip={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .tooltip
                              )
                            }
                            styleIndexView={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .indexView
                              )
                            }
                            stylePrimaryText={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .primaryText
                              )
                            }
                            styleSecondaryText={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .secondaryText
                              )
                            }
                            styleActionView={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .actionView
                              )
                            }
                            styleActionText={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .actionText
                              )
                            }
                            secondaryText={
                              currentCampaignDraft[currentStepIndex].secondaryText
                            }
                            primaryText={
                              currentCampaignDraft[currentStepIndex].primaryText
                            }
                            actionText={
                              currentCampaignDraft[currentStepIndex].actionText
                            }
                            focusedView={currentCampaignDraft[currentStepIndex].style
                              .focussedView}
                            currentStepIndex={currentStepIndex}
                          />
                          <PreviewDiv
                            text={currentCampaignDraft[currentStepIndex].skipAction}
                            containerStyle={this.evaluate(
                              currentCampaignDraft[currentStepIndex].tooltipStyle
                                .skipAction
                            )}
                            style={
                              currentCampaignDraft[currentStepIndex].tooltipStyle
                                .skipActionText
                            }
                          />
                          {currentCampaignDraft[currentStepIndex].style
                            .focussedView &&
                            currentCampaignDraft[currentStepIndex].style
                              .wrapper && (
                              <PreviewDiv
                                containerStyle={{
                                  position: "absolute",
                                  ...currentCampaignDraft[currentStepIndex].style
                                    .focussedView,
                                  ...currentCampaignDraft[currentStepIndex].tooltipStyle
                                    .wrapper,
                                  background: "rgb(255 255 255 / 39%)",
                                  borderStyle: "solid",
                                }}
                              />
                            )}
                        </> : <>
                          <PreviewText
                            text={
                              currentCampaignDraft[currentStepIndex].primaryText
                            }
                            style={this.evaluate(
                              currentCampaignDraft[currentStepIndex].style
                                .primaryText
                            )}
                          />
                          <PreviewText
                            text={
                              currentCampaignDraft[currentStepIndex].secondaryText
                            }
                            style={this.evaluate(
                              currentCampaignDraft[currentStepIndex].style
                                .secondaryText
                            )}
                          />
                          <PreviewDiv
                            text={currentCampaignDraft[currentStepIndex].actionText}
                            containerStyle={this.evaluate(
                              currentCampaignDraft[currentStepIndex].style
                                .actionView
                            )}
                            style={
                              currentCampaignDraft[currentStepIndex].style
                                .actionText
                            }
                          />
                          <PreviewDiv
                            text={currentCampaignDraft[currentStepIndex].skipAction}
                            containerStyle={this.evaluate(
                              currentCampaignDraft[currentStepIndex].style
                                .skipAction
                            )}
                            style={
                              currentCampaignDraft[currentStepIndex].style
                                .skipActionText
                            }
                          />

                          {currentCampaignDraft[currentStepIndex].showStepNumber
                            && currentStepIndex > 0 &&
                            <PreviewText
                              text={currentStepIndex}
                              style={this.evaluate(
                                currentCampaignDraft[currentStepIndex].style
                                  .indexView
                              )}
                            />}
                          {currentCampaignDraft[currentStepIndex].style
                            .focussedView &&
                            currentCampaignDraft[currentStepIndex].style
                              .wrapper && (
                              <PreviewDiv
                                containerStyle={{
                                  position: "absolute",
                                  ...currentCampaignDraft[currentStepIndex].style
                                    .focussedView,
                                  ...currentCampaignDraft[currentStepIndex].style
                                    .wrapper,
                                  background: "rgb(255 255 255 / 39%)",
                                  borderStyle: "solid",
                                }}
                              />
                            )}
                        </>}
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          background: "black",
                          width: "100%",
                          zIndex: 1,
                          height: "100%",
                          opacity: 0.6,
                        }}
                      ></div>
                      <img
                        src={PhoneMock}
                        style={{
                          width: "100%",
                          height: "100%",
                          boxShadow: "0px 0px 5px 6px rgba(0, 0, 0, 0.1)",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      />
                    </div>
                  </section>
                </div>
                {campaign && (
                  <div className="app-sidenav" key={currentStepIndex}>
                    <EditCampaignTab
                      setCampaignData={(data) => {
                        this.setState({
                          campaignData: { [currentStepIndex]: data },
                        });
                        this.props.setCampaignDirty();
                      }}
                      details={JSON.parse(
                        campaign.details[currentScreenIndex].steps[currentStepIndex].view
                      )}
                    />
                  </div>
                )}
                {isDirty && (
                  <div
                    style={{
                      position: "fixed",
                      left: "0px",
                      bottom: "0px",
                      zIndex: 9,
                      textAlign: "right",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      background: "white",
                      height: "80px",
                      boxShadow: "0px 18px 13px 13px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <span>You have unsaved changes.</span>
                    <ul
                      style={{
                        display: "flex",
                        listStyle: "none",
                      }}
                    >
                      <li
                        className="footer-contact"
                        style={{
                          background: "#b5655b",
                          color: "white",
                          margin: "10px",
                        }}
                      >
                        <a
                          onClick={() => {
                            this.setState({
                              campaignData: null,
                            });
                            this.props.setCampaignClean();
                          }}
                        >
                          Discard
                        </a>
                      </li>
                      <li
                        className="footer-contact"
                        style={{
                          color: "white",
                          margin: "10px",
                        }}
                      >
                        <a
                          onClick={() => {
                            this.props.saveCampaign(
                              campaignId,
                              currentScreenIndex,
                              currentStepIndex,
                              currentCampaignDraft
                            );
                          }}
                        >
                          Save
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.campaigns.isLoading,
  pendingPublish: state.campaigns.pendingPublish,
  campaign: state.campaigns.currentCampaign,
  currentStepIndex: state.campaigns.currentStepIndex,
  currentCampaignDraft: state.campaigns.currentCampaignDraft,
  currentScreenIndex: state.campaigns.currentScreenIndex,
  isDirty: state.campaigns.currentCampignIsDirty,
  type: state.campaigns.currentCampaignDraft[state.campaigns.currentStepIndex] ? state.campaigns.currentCampaignDraft[state.campaigns.currentStepIndex].type : 0,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectCampaign,
      selectCampaignStep,
      setCampaignClean,
      setCampaignDirty,
      saveCampaign,
      getAllCampaigns,
      editCurrentCampaignDraft,
      selectCampaignScreen,
      addOrRemoveIntroView,
    },
    dispatch
  );

export default withCookies(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditCampaign)
  )
);
