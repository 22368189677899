import React from "react";
import { CustomPicker } from "react-color";
import { Hue, Saturation } from "react-color/lib/components/common";
import {
  CloseButton,
  ColorCard,
  ColorCardHeader,
  ColorCardheading,
  Caret
} from "./StaticComponents";
export const MyPicker = ({ hex, hsl, hsv, onChange, handleClose }) => {

  const overrideColorChange = (evt) => {

    let color = evt.target.value;
    onChange({
      hex: color
    })
  }

  const styles = {
    hue: {
      height: 10,
      position: "relative",
      width: "100%",
      marginTop: 10,
      borderRadius: "20px",
    },
    saturation: {
      width: "100%",
      height: 100,
      position: "relative",
      marginTop: 10,
    },
    input: {
      height: 34,
      paddingLeft: 10,
      width: "70%",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
    },
    swatch: {
      width: 54,
      height: 38,
      background: hex,
    },
  };
  return (
    <>
      <ColorCard>
        <ColorCardHeader>
          <ColorCardheading>Color</ColorCardheading>
          <CloseButton onClick={handleClose}>   <svg fill="#969696" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>
          </CloseButton>

        </ColorCardHeader>
        <div style={styles.saturation}>
          <Saturation hsl={hsl} hsv={hsv} onChange={onChange} />
        </div>
        <div style={styles.hue}>
          <Hue hsl={hsl} onChange={onChange} />
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
        >
          <p>Hex:{"  "}</p>
          <input style={styles.input} value={hex} onChange={overrideColorChange} />
        </div>
      </ColorCard>
      <Caret />
    </>
  );
};

export default CustomPicker(MyPicker);
