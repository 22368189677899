import React from "react";
import TextField from "@material-ui/core/TextField";

import {
  TITLE,
  DESCRIPTION
} from "../../constants/android"

export default function ContentEditor(props) {
  const {
    currentCampaignDraft,
    currentStepIndex,
    handleCampaignChange,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
      }}
    >
      <div
        style={{
          flex: 1,
          margin: "40px 20px",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <TextField
          label="Primary text"
          onChange={(ev) => {
            handleCampaignChange({
              stepIndex: currentStepIndex,
              changeType: TITLE,
              changeValue: ev.target.value
            });
          }}
          defaultValue={currentCampaignDraft.details[currentStepIndex].stepTitle}
          helperText="Some nice words here"
          variant="outlined"
          size="small"
          style={{
            margin: "20px 0px",
          }}
        />
        <TextField
          label="Secondary text"
          onChange={(ev) => {
            handleCampaignChange({
              stepIndex: currentStepIndex,
              changeType: DESCRIPTION,
              changeValue: ev.target.value
            });
          }}
          defaultValue={
            currentCampaignDraft.details[currentStepIndex].stepDescription ||
            "Default Value"
          }
          helperText="Nicer words here"
          variant="outlined"
          size="small"
          style={{
            margin: "20px 0px",
          }}
        />
      </div>
    </div>
  );
}
