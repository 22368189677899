import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppNav from "../appNav";
import { withCookies, Cookies } from "react-cookie";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import PhoneMock from "../../assets/phoneMock2.png";
import EditCampaignTabAndroid from "../edit-campaign-tab-android/index";
import EditCampaignTab from "../edit-campaign-tab/index";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from "@material-ui/core/Tooltip";
import PreviewText from "../edit-campaign/previewText";
import PreviewDiv from "../edit-campaign/previewDiv";
import PreviewTooltip from "../edit-campaign/previewTooltip";

import {
  selectCampaign,
  selectCampaignStepAndroid,
  selectCampaignAndroid,
  setCampaignClean,
  setCampaignDirty,
  saveCampaign,
  saveCampaignAndroid,
  getAllCampaigns,
  editCurrentCampaignDraft,
  selectCampaignScreen,
  addOrRemoveIntroView
} from "../../actions/campaigns";

toast.configure();

class EditCampaignAndroid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignData: null,
      pendingPublish: false,
    };
  }

  componentWillMount() {
    let { campaignId } = this.props.match.params;
    this.props.selectCampaignAndroid(campaignId);
  }

  render() {
    const {
      campaign,
      isLoading,
      isDirty,
      currentStepIndex,
      currentCampaignDraft,
      type,
      pendingPublish
    } = this.props;

    let { campaignId } = this.props.match.params;

    return (
        <div className="container">
            <AppNav arena signOut={this.props.signOut} showPublish={true} pendingPublish={pendingPublish} />
            <div>
                <div style={{ margin: "auto" }}>
                {isLoading && (
                <Loader
                    type="TailSpin"
                    color="#6ad7dd"
                    style={{ textAlign: "center" }}
                    height={"60vh"}
                    width={60}
                />
                )}
                </div>
            <main>
            {!isLoading && campaign && campaign.details && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr .8fr",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100vh",
                    background: "#fafcff",
                    marginTop: "35px",
                  }}
                >
                  <section className="topics-list">

                    {campaign.details && campaign.details.length > 1 && <div className="question-timeline">
                      <h4
                        style={{
                          color: "#999999",
                        }}
                      >
                        Step
                      </h4>
                      {
                        campaign.details.map((_, index) => {
                          return index == currentStepIndex ? (
                            <div className="question-index active-ques">
                              {index + 1}
                            </div>
                          ) : (
                              <div
                                onClick={() =>
                                  this.props.selectCampaignStepAndroid(index)
                                }
                                className={
                                  true
                                    ? "question-index"
                                    : this.state.selected[index]
                                      ? "question-index visited"
                                      : "question-index visited-not-selected"
                                }
                              >
                                {index + 1}
                              </div>
                            );
                        })}
                    </div>}
                    <div
                      style={{
                        margin: "40px auto 200px auto",
                        position: "relative",
                        width: "360px",
                        height: "560px",
                      }}
                    >
                    <>
                      </> : <>
                        <PreviewText
                          text={
                            currentCampaignDraft.details[currentStepIndex].stepTitle
                          }
                        />
                        <PreviewText
                          text={
                            currentCampaignDraft.details[currentStepIndex].stepDescription
                          }
                        />
                      </>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          background: "black",
                          width: "100%",
                          zIndex: 1,
                          height: "100%",
                          opacity: 0.6,
                        }}
                      ></div>
                      <img
                        src={PhoneMock}
                        style={{
                          width: "100%",
                          height: "100%",
                          boxShadow: "0px 0px 5px 6px rgba(0, 0, 0, 0.1)",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      />
                    </div>
                  </section>
                </div>
                {campaign && (
                  <div className="app-sidenav" key={currentStepIndex}>
                    <EditCampaignTabAndroid
                      setCampaignData={(data) => {
                        this.setState({
                          campaignData: { [currentStepIndex]: data },
                        });
                        this.props.setCampaignDirty();
                      }}
                      details={currentCampaignDraft.details[currentStepIndex]}
                    />
                  </div>
                )}
                {isDirty && (
                  <div
                    style={{
                      position: "fixed",
                      left: "0px",
                      bottom: "0px",
                      zIndex: 9,
                      textAlign: "right",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      background: "white",
                      height: "80px",
                      boxShadow: "0px 18px 13px 13px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <span>You have unsaved changes.</span>
                    <ul
                      style={{
                        display: "flex",
                        listStyle: "none",
                      }}
                    >
                      <li
                        className="footer-contact"
                        style={{
                          background: "#b5655b",
                          color: "white",
                          margin: "10px",
                        }}
                      >
                        <a
                          onClick={() => {
                            this.setState({
                              campaignData: null,
                            });
                            this.props.setCampaignClean();
                          }}
                        >
                          Discard
                        </a>
                      </li>
                      <li
                        className="footer-contact"
                        style={{
                          color: "white",
                          margin: "10px",
                        }}
                      >
                        <a
                          onClick={() => {
                            this.props.saveCampaignAndroid(
                              campaignId,
                              currentCampaignDraft
                            );
                          }}
                        >
                          Save
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </main>
        </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.campaigns.isLoading,
  pendingPublish: state.campaigns.pendingPublish,
  campaign: state.campaigns.currentCampaign,
  currentStepIndex: state.campaigns.currentStepIndex,
  currentCampaignDraft: state.campaigns.currentCampaignDraft,
  isDirty: state.campaigns.currentCampignIsDirty,
  type: 0,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectCampaignAndroid,
      selectCampaignStepAndroid,
      setCampaignClean,
      setCampaignDirty,
      saveCampaign,
      saveCampaignAndroid,
      getAllCampaigns,
      editCurrentCampaignDraft,
      selectCampaignScreen,
      addOrRemoveIntroView,
    },
    dispatch
  );

export default withCookies(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditCampaignAndroid)
  )
);
