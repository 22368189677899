import React, { Component } from "react";
import { Link } from "react-scroll";
import { makeStyles, fade } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import { Link as RouterLink } from "react-router-dom";
import LandingPage from '../landing-page/src/App';
import Nav from "./nav";

toast.configure();

const useStyles = makeStyles((theme) => ({
  nextIcon: {
    // marginLeft: theme.spacing(1),
    fontSize: "2rem !important",
    paddingLeft: "20%",
    paddingRight: "10px",
    color: "white",
  },
}));

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(51, 129, 218, 0.25)",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: "red",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function RedditTextField(props) {
  const classes = useStylesReddit();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChangeEmail = (evt) => {
    console.log(evt.target.value);
    this.setState({
      email: evt.target.value,
    });
  };

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleSubmit = () => {
    const { email } = this.state;
    if (this.validateEmail(email)) {
      window.analytics.identify(email, {
        email: email,
        flowdash: true,
      });

      // todo - Add code to save email addresses here.
      const saveEmailUrl =
        "https://asia-east2-ciruital-prod-254903.cloudfunctions.net/qmsapi/email";
      fetch(saveEmailUrl, {
        mode: "cors",
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((resp) => resp.json())
        .then(() => {
          toast.success(
            "Thanks for your interest! We'll get in touch with you soon."
          );
        })
        .catch(() => {
          toast.error(
            "Bummer. Seems to be an issue with the email. Please try again."
          );
        });
    } else {
      toast.error(
        "Bummer. Seems to be an issue with the email. Please try again."
      );
    }
  };

  render() {
    return (
      <header>
        {/* <Nav /> */}
        <LandingPage/>
        {/* <div className="head">
          <div>
            <h1>
            Create personalised in-app experiences in minutes.
              <span>
                {" "}
                <br />
                Drive adoption in the language your users understand.{" "}
              </span>
            </h1>
            <a
              href="https://github.com/Flowdash-ai/react-native-flowdash"
              className="github"
              smooth={true}
              spy={true}
              activeClass="active"
              offset={-70}
              duration={500}
            >
              <img
                width="30"
                src="https://res.cloudinary.com/dfcnic8wq/image/upload/v1590214203/FlowDash/GitHub-Mark-120px-plus_k1jc3w.png"
              />
              <span style={{ margin: "4px 10px" }}>View on Github</span>
            </a>
            <div className="request-access-form">
              <RedditTextField
                id="standard-basic"
                variant="filled"
                label="Enter your work email"
                className="request-access"
                color="white"
                onChange={this.handleChangeEmail}
              />
              {/* <input className="request-access" type="text" />  */}
              {/* <Link
                onClick={this.handleSubmit}
                className="contact"
                smooth={true}
                spy={true}
                activeClass="active"
                offset={-70}
                duration={500}
              >
                <span>Get Early Access</span>
              </Link>
            </div>
            <div
              style={{
                textAlign: "left",
                fontSize: "1rem",
                color: "rgb(150, 149, 149)",
                padding: "10px",
              }}
            >
              Already using Flowdash?
              <RouterLink
                to="/login"
                style={{
                  color: "rgb(255, 176, 0)",
                  padding: "5px",
                }}
              >
                Sign In
              </RouterLink>
            </div>
          </div>
          <div>
            <div class="phone-hero">
              <video
                preload="true"
                autoplay="autoplay"
                loop="loop"
                muted={true}
              >
                <source src="https://res.cloudinary.com/dfcnic8wq/video/upload/v1592725941/FlowDash/flowdash_animation-fast_n3pgis.mp4"></source>
              </video>
            </div>
          </div>
        </div> */}
      </header> 
    );
  }
}
