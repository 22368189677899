import React from "react";

export default function PreviewDiv(props) {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 2,
        width: "100%",
        height: "100%",
      }}
    >
      <div style={props.containerStyle}>
        <span style={props.style}>{props.text}</span>
      </div>
    </div>
  );
}
