var tinycolor = require("tinycolor2");

export const checkValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const checkVaildBusinessEmail = () => {
  const email = "kkr1196@gmail.com";
  const listOfDomains = [
    "gmail",
    "hotmail",
    "yahoo",
    "ymail",
    "outlook",
    "wannadoo",
    "live",
    "msn",
    "aol",
    "rocketmail",
  ];

  const afterAt = email.substring(email.indexOf("@") + 1);
  const domain = afterAt.substring(0, afterAt.indexOf("."));
  if (listOfDomains.includes(domain)) {
    return false;
  }
  return true;
};

export const isBackgroundDark = (color) => {
  let c = tinycolor(color);
  return c.isLight();
};
