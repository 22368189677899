export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_FULFILLED = "LOGIN_FULFILLED";
export const LOGIN_REJECTED = "LOGIN_REJECTED";

export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_FULFILLED = "LOGOUT_FULFILLED";
export const LOGOUT_REJECTED = "LOGOUT_REJECTED";

export const FETCH_CAMPAIGNS_REQUESTED = "FETCH_CAMPAIGNS_REQUESTED";
export const FETCH_CAMPAIGNS_FULFILLED = "FETCH_CAMPAIGNS_FULFILLED";
export const FETCH_CAMPAIGNS_REJECTED = "FETCH_CAMPAIGNS_REJECTED";

export const SELECT_CAMPAIGN_REQUESTED = "SELECT_CAMPAIGN_REQUESTED";
export const SELECT_CAMPAIGN_REJECTED = "SELECT_CAMPAIGN_REJECTED";
export const SELECT_CAMPAIGN_FULFILLED = "SELECT_CAMPAIGN_FULFILLED";

export const SELECT_CAMPAIGN_REQUESTED_WEB = "SELECT_CAMPAIGN_REQUESTED_WEB";
export const SELECT_CAMPAIGN_REJECTED_WEB = "SELECT_CAMPAIGN_REJECTED_WEB";
export const SELECT_CAMPAIGN_FULFILLED_WEB = "SELECT_CAMPAIGN_FULFILLED_WEB";


export const SELECT_CAMPAIGN_REQUESTED_ANDROID = "SELECT_CAMPAIGN_REQUESTED_ANDROID";
export const SELECT_CAMPAIGN_REJECTED_ANDROID = "SELECT_CAMPAIGN_REJECTED_ANDROID";
export const SELECT_CAMPAIGN_FULFILLED_ANDROID = "SELECT_CAMPAIGN_FULFILLED_ANDROID";

export const SELECT_STEP_FULFILLED = "SELECT_STEP_FULFILLED";

export const SELECT_STEP_FULFILLED_ANDROID = "SELECT_STEP_FULFILLED_ANDROID";

export const SELECT_STEP_FULFILLED_WEB = "SELECT_STEP_FULFILLED_WEB";

export const SELECT_SCREEN_FULFILLED = "SELECT_SCREEN_FULFILLED";

export const SET_CAMPAIGN_DIRTY = "SET_CAMPAIGN_DIRTY";
export const SET_CAMPAIGN_CLEAN = "SET_CAMPAIGN_CLEAN";

export const SAVE_CAMPAIGN_REQUESTED = "SAVE_CAMPAIGN_REQUESTED";
export const SAVE_CAMPAIGN_REJECTED = "SAVE_CAMPAIGN_REJECTED";
export const SAVE_CAMPAIGN_FULFILLED = "SAVE_CAMPAIGN_FULFILLED";

export const PUBLISH_CAMPAIGN_REQUESTED = "PUBLISH_CAMPAIGN_REQUESTED";
export const PUBLISH_CAMPAIGN_REJECTED = "PUBLISH_CAMPAIGN_REJECTED";
export const PUBLISH_CAMPAIGN_FULFILLED = "PUBLISH_CAMPAIGN_FULFILLED";

export const INIT_CURRENT_CAMPAIGN_DRAFT = "INIT_CURRENT_CAMPAIGN_DRAFT";
export const EDIT_CURRENT_CAMPAIGN_DRAFT = "EDIT_CURRENT_CAMPAIGN_DRAFT";

export const INIT_CURRENT_CAMPAIGN_DRAFT_WEB = "INIT_CURRENT_CAMPAIGN_DRAFT_WEB";
export const EDIT_CURRENT_CAMPAIGN_DRAFT_WEB = "EDIT_CURRENT_CAMPAIGN_DRAFT_WEB";

export const INIT_CURRENT_CAMPAIGN_DRAFT_ANDROID = "INTI_CURRENT_CAMPAIGN_DRAFT_ANDROID";
export const EDIT_CURRENT_CAMPAIGN_DRAFT_ANDROID = "EDIT_CURRENT_CAMPAIGN_DRAFT_ANDROID";

export const EDIT_CAMPAIGN_STATUS_REQUESTED = "EDIT_CAMPAIGN_STATUS_REQUESTED";
export const EDIT_CAMPAIGN_STATUS_REJECTED = "EDIT_CAMPAIGN_STATUS_REJECTED";
export const EDIT_CAMPAIGN_STATUS_FULFILLED = "EDIT_CAMPAIGN_STATUS_FULFILLED";
