import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppNav from "../appNav";
import { withCookies, Cookies } from "react-cookie";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import PhoneMock from "../../assets/phoneMock2.png";
import EditCampaignTab from "../edit-campaign-tab-web/index";
import PreviewText from "./previewText";
import PreviewDiv from "./previewDiv";
import PreviewTooltip from "./previewTooltip";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import EdiText from 'react-editext';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';

import {
  selectCampaignWeb,
  selectCampaignStepWeb,
  setCampaignClean,
  setCampaignDirty,
  saveCampaignWeb,
  getAllCampaigns,
  editCurrentCampaignDraftWeb,
  selectCampaignScreen,
  addOrRemoveIntroViewWeb,
  initialiseCurrentCampaignDraftWeb,
} from "../../actions/campaigns";

toast.configure();

class EditCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignData: null,
      pendingPublish: false,
    };
  }

  componentWillMount() {
    let { campaignId } = this.props.match.params;
    this.props.selectCampaignWeb(campaignId);
  }

  componentDidUpdate(prevProps) {
    // On refresh, take it to the campaigns listing
    if (prevProps.isLoading && !this.props.isLoading && !this.props.campaign) {
      this.props.history.replace("/app");
    }
  }

  handleTextChange = (name, value) => {
    this.props.editCurrentCampaignDraftWeb({ [name]: value });
  };

  evaluate = (style) => {
    if (!style) return;
    let deepClone = JSON.parse(JSON.stringify(style));
    for (let [key, value] of Object.entries(style)) {
      if (key == "top" || key == "left" || key == "right" || key == "bottom") {
        if (typeof value === "string") {
          value = value.replace(
            "this.state.focusedView",
            "this.props.currentCampaignDraft[this.props.currentStepIndex].style.focussedView"
          );
          value = value.replace(
            "this.state.focusedView",
            "this.props.currentCampaignDraft[this.props.currentStepIndex].style.focussedView"
          );
          deepClone[key] = (value);
        }
      }
    }
    return deepClone;
  };

  render() {
    const {
      campaign,
      isLoading,
      currentStepIndex,
      currentScreenIndex,
      isDirty,
      currentCampaignDraft,
      editCurrentCampaignDraft,
      selectCampaignScreen,
      addOrRemoveIntroViewWeb,
      type,
      pendingPublish,
      campaignName,
      percentageExposure,
      userRules,
    } = this.props;

    let { campaignId } = this.props.match.params;
    return (
      <div className="container">
        <AppNav arena signOut={this.props.signOut} showPublish={true} pendingPublish={pendingPublish} />
        <div>
          <div style={{ margin: "auto" }}>
            {isLoading && (
              <Loader
                type="TailSpin"
                color="#6ad7dd"
                style={{ textAlign: "center" }}
                height={"60vh"}
                width={60}
              />
            )}
          </div>
          <main>
            {!isLoading && campaign && campaign.details && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr .5fr",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100vh",
                    background: "#fafafa",
                    marginTop: "60px",
                  }}
                >
                  <section className="topics-list">
                    {currentScreenIndex == 0 && <div style={{ display: 'flex', marginLeft: 40, marginRight: 40, alignItems: 'baseline' }}>

                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: 'start',
                        margin: '0px 5px',
                        flex: 1
                      }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: '60%',
                            fontSize: 20,
                            fontWeight: 900,
                          }}
                        >
                          <EdiText
                            type="text"
                            saveButtonContent={<DoneIcon />}
                            cancelButtonContent={<CancelIcon />}
                            saveButtonClassName="custom-edit-button"
                            editButtonClassName="custom-edit-button"
                            cancelButtonClassName="custom-edit-button"
                            editButtonContent={<EditIcon />}
                            value={campaignName}
                            onSave={(value) => {
                              this.handleTextChange("name", value);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            paddingTop: 10,
                            paddingRight: 0,
                            paddingBottom: 20,
                            paddingLeft: 0,
                            color: "#578FFF",
                            fontWeight: "900",
                          }}
                        >{currentCampaignDraft[currentStepIndex].screenName}
                        </span>
                      </div>
                      <span className="footer-contact"
                        style={{
                          width: '135px',
                          background: '#FF9080',
                          borderRadius: '4px',
                          color: 'white',
                          marginLeft: 'auto',
                          marginRight: '1px',
                          textAlign: 'center'
                        }}
                        onClick={(campaign.details[0].steps[0].viewIndex != -5679) ?
                          () => addOrRemoveIntroViewWeb("add", campaignId) :
                          () => addOrRemoveIntroViewWeb("remove", campaignId)
                        }
                      >
                        {(campaign.details[0].steps[0].viewIndex != -5679) ? "Add intro view" : "Remove intro view"}
                      </span>
                    </div>
                    }
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 40, marginRight: 40,
                    }}>
                      {campaign.details && campaign.details[currentScreenIndex].steps.length > 1 && <div className="question-timeline">
                        {
                          campaign.details[currentScreenIndex].steps.map((_, index) => {
                            return index == currentStepIndex ? (
                              <div className="question-index active-ques">
                                Step {index + 1}
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  this.props.selectCampaignStepWeb(index)
                                }
                                className={
                                  true
                                    ? "question-index"
                                    : this.state.selected[index]
                                      ? "question-index visited"
                                      : "question-index visited-not-selected"
                                }
                              >
                                Step {index + 1}
                              </div>
                            );
                          })}
                      </div>}
                      <div
                        style={{
                          margin: "100px auto 200px auto",
                          position: "relative",
                          // width: "360px",
                          height: "320px",
                        }}
                      >
                        <>
                          <PreviewTooltip
                            key={currentStepIndex}
                            showIndexView={currentCampaignDraft[currentStepIndex].showStepNumber}
                            direction={currentCampaignDraft[currentStepIndex].tooltipDirection}
                            styleTooltip={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .tooltip
                              )
                            }
                            styleIndexView={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .indexView
                              )
                            }
                            stylePrimaryText={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .primaryText
                              )
                            }
                            styleSecondaryText={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .secondaryText
                              )
                            }
                            styleActionView={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .actionView
                              )
                            }
                            styleActionText={
                              this.evaluate(
                                currentCampaignDraft[currentStepIndex].tooltipStyle
                                  .actionText
                              )
                            }
                            styleSkipText={
                              currentCampaignDraft[currentStepIndex].tooltipStyle
                                .skipActionText
                            }
                            skipText={currentCampaignDraft[currentStepIndex].skipAction}
                            secondaryText={
                              currentCampaignDraft[currentStepIndex].secondaryText
                            }
                            primaryText={
                              currentCampaignDraft[currentStepIndex].primaryText
                            }
                            actionText={
                              currentCampaignDraft[currentStepIndex].actionText
                            }
                            focusedView={currentCampaignDraft[currentStepIndex].style
                              .focussedView}
                            currentStepIndex={currentStepIndex}
                            viewIndex={campaign.details[0].steps[currentStepIndex].viewIndex}
                            image={currentCampaignDraft[currentStepIndex].headerImage}
                          />
                        </>
                      </div>
                    </div>
                  </section>
                </div>
                {campaign && (
                  <div className="app-sidenav" key={currentStepIndex}>
                    <EditCampaignTab
                      setCampaignData={(data) => {
                        this.setState({
                          campaignData: { [currentStepIndex]: data },
                        });
                        this.props.setCampaignDirty();
                      }}
                      isIntroView={campaign.details[0].steps[currentStepIndex].viewIndex == -5679}
                      details={(
                        campaign.details[currentScreenIndex].steps[currentStepIndex].view
                      )}
                    />
                  </div>
                )}
                {isDirty && (
                  <div
                    style={{
                      position: "fixed",
                      left: "0px",
                      bottom: "0px",
                      zIndex: 9,
                      textAlign: "right",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      background: "white",
                      height: "80px",
                      boxShadow: "0px 18px 13px 13px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <span>You have unsaved changes.</span>
                    <ul
                      style={{
                        display: "flex",
                        listStyle: "none",
                      }}
                    >
                      <li
                        className="footer-contact"
                        style={{
                          background: "#b5655b",
                          color: "white",
                          margin: "10px",
                        }}
                      >
                        <a
                          onClick={() => {
                            this.setState({
                              campaignData: null,
                            });
                            this.props.setCampaignClean();
                            this.props.initialiseCurrentCampaignDraftWeb();
                          }}
                        >
                          Discard
                        </a>
                      </li>
                      <li
                        className="footer-contact"
                        style={{
                          color: "white",
                          margin: "10px",
                        }}
                      >
                        <a
                          onClick={() => {
                            this.props.saveCampaignWeb(
                              campaignId,
                              currentScreenIndex,
                              currentStepIndex,
                              currentCampaignDraft,
                              campaignName,
                              percentageExposure,
                              userRules
                            );
                          }}
                        >
                          Save draft
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </main>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.campaigns.isLoading,
  pendingPublish: state.campaigns.pendingPublish,
  campaign: state.campaigns.currentCampaign,
  currentStepIndex: state.campaigns.currentStepIndex,
  currentCampaignDraft: state.campaigns.currentCampaignDraft,
  currentScreenIndex: state.campaigns.currentScreenIndex,
  isDirty: state.campaigns.currentCampignIsDirty,
  campaignName: state.campaigns.name,
  percentageExposure: state.campaigns.percentageExposure,
  userRules: state.campaigns.userRules,
  type: state.campaigns.currentCampaignDraft[state.campaigns.currentStepIndex] ? state.campaigns.currentCampaignDraft[state.campaigns.currentStepIndex].type : 0,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectCampaignWeb,
      selectCampaignStepWeb,
      setCampaignClean,
      setCampaignDirty,
      saveCampaignWeb,
      getAllCampaigns,
      editCurrentCampaignDraftWeb,
      selectCampaignScreen,
      addOrRemoveIntroViewWeb,
      initialiseCurrentCampaignDraftWeb
    },
    dispatch
  );

export default withCookies(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditCampaign)
  )
);
