import React from "react";
import { Link } from "react-scroll";
import "../Styles/Section3.css";
import { Button } from "@material-ui/core";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

function Section3() {
  return (
    <div className="section3">
      <img
        src="https://res.cloudinary.com/dfcnic8wq/image/upload/v1592725917/FlowDash/Illustration_3_bjnr7c.png"
        className="mainImage3"
        alt=""
      />
      <div className="section3__Right">
        <div className="yellow__box">
          <div className="time__icon">
            <QueryBuilderIcon fontSize="large" />
          </div>
        </div>
        <h1>
          <span className="code__yellow">Analyse, Measure & Improve </span>
          every user engagement.
        </h1>
        {/* <h4>Join our waiting list along with 40,000 startups</h4> */}
        <Link
          className="section3__button"
          to="CTA"
          smooth={true}
          spy={true}
          activeClass="active"
          offset={-70}
          duration={500}
        >
          {/* <Button variant="contained"> */}
            Get early access
          {/* </Button> */}
        </Link>
      </div>
    </div>
  );
}

export default Section3;
