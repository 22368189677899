import React from "react";
import ValueLabelComponent from '../common/valueLabelComponent';
import Slider from '../common/prettoSlider';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from '@material-ui/icons/Help';
import {AUDIENCE} from "../../constants/android"
export default function (props) {
    return (<div
        style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
        }}
    >
        <div
            style={{
                flex: 1,
                padding: "40px 60px",
            }}
        >
            <span>
                <h4>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    > Coming soon <Tooltip arrow title="Edit colors, style, visuals and more!">
                            <HelpIcon style={{
                                padding: "5px",
                                color: 'rgb(172 172 172)',
                                cursor: "help",
                            }} /></Tooltip>
                    </span>
                </h4>
            </span>
        </div>
    </div>)
}