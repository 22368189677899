import React from "react";
import LandingPage from "./src/App";

function Home() {
  return (
    <div className="container">
      <LandingPage />
    </div>
  );
}

export default Home;
