import { combineReducers } from "redux";
import campaigns from "./campaigns";
import loginData from "./login";
import sidebarState from "./sideBar";
const appReducer = combineReducers({
  campaigns,
  loginData,
  sidebarState,
});

export default appReducer;
