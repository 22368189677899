import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Fade from "@material-ui/core/Grow";
import Icon from "@material-ui/core/Icon";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { connect } from "react-redux";
import { BrokenImage } from "../settings/staticComponents";

const useStyles = makeStyles((theme) => ({
  userIcon: {
    fontSize: "2.1em",
    color: "#c6c7d3",
  },
}));

function UserMenu(props) {
  console.log("here", props.user);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    handleClose();
    // console.log(props);
    // props.removeCookie("circuital_user_email");
    // props.removeCookie("circuital_user_name");
    // props.removeCookie("circuital_user_id");
    // props.forceRender();
    // props.history.push("/foundry");
    // props.signOut();
  };

  return (
    <div>
      <div
        style={{
          padding: "0px",
          cursor: "pointer",
          display: "flex",
          flex: 2,
        }}
        onClick={handleClick}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: "10px",
          }}
        >
          <Icon className={classes.userIcon} style={{ flex: 1.5 }}>
            {props.user.imageUrl ? (
              <img
                src={props.user.imageUrl}
                style={{ height: "30px", width: "30px", borderRadius: "50%" }}
              />
            ) : (
              <BrokenImage />
            )}
          </Icon>
          <p style={{ margin: "auto" }}>{props.user.givenName || "Admin"}</p>
          <MoreVertIcon
            style={{
              margin: "auto",
            }}
          />
        </div>
      </div>
      <Menu
        style={{
          top: "50px",
        }}
        elevation={0}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="user-menu"
        TransitionComponent={Fade}
      >
        {/* <MenuItem onClick={handleClose}>
          <img src={Coin} style={{ width: "14px", padding: "4px" }}></img> 10 
        </MenuItem> */}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.loginData.user,
});

export default connect(mapStateToProps)(withRouter(UserMenu));
