import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editCampaignStatus } from "../../actions/campaigns";

import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { green, grey, red, deepPurple } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";

toast.configure();

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    transition: "all 0.200s ease-in-out",
    border: "1px solid rgb(229, 229, 229)",
    boxShadow: "none"
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    "max-width": "70%",
    margin: "0 auto",
    backgroundSize: "84%",
  },
  campaignName: {
    fontFamily: "DM Sans",
    fontSize: "18px !important",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  filters: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "18px",
    padding: "0 1rem",
    color: "#1c1f28",
    margin: "0 3rem",
    cursor: "pointer",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    color: "#5c10e4",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: deepPurple[300],
    fontWeight: 800,
    fontSize: "x-small",
  },
  upvote: {
    color: green[400],
  },
  upvoteRevert: {
    color: grey[700],
  },
  downvote: {
    color: red[400],
  },
  downvoteRevert: {
    color: grey[700],
  },
  report: {
    color: grey[500],
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

function CampaignList(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [tofilter, setToFilter] = React.useState("ACTIVE");
  const [active, setActive] = React.useState([1, 0, 0]);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  let { walkthroughs, editCampaignStatus, appOwner } = props;
  if (walkthroughs) {
    walkthroughs = walkthroughs.sort(function (a, b) {
      return a.id - b.id;
    });
  }
  const statusMap = {
    ACTIVE: {
      text: "Active",
      color: "#3f9b87",
      backgroundColor: "#bffae5",
      title: "This campaign is currently live.",
    },
    PAUSED: {
      text: "Paused",
      color: "#8C8C8C",
      backgroundColor: "#8C8C8C",
      title: "This campaign is currently paused.",
    },
    DELETED: {
      text: "Archived",
      color: "#69002c",
      backgroundColor: "#ffedf0",
      title: "This campaign is archived.",
    },
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          boxSizing: "border-box",
          width: "100%",
          height: "5rem",
          padding: "0rem",
          border: "1px solid #E5E5E5",
          background: "#ffffff",
          borderRadius: "6px 6px 0 0",
        }}
      >
        <p
          className={classes.filters}
          onClick={() => {
            setActive([1, 0, 0]);
            setToFilter("ACTIVE");
          }}
          style={{
            color: active[0] ? "#578FFF" : "#1C1F28",
            borderBottom: active[0] ? "2px solid #578FFF" : "none",
            borderRadius: active[0] ? "2px" : "none",
            marginLeft: "2rem",
          }}
        >
          Active
        </p>
        <p
          className={classes.filters}
          onClick={() => {
            setActive([0, 1, 0]);
            setToFilter("PAUSED");
          }}
          style={{
            color: active[1] ? "#578FFF" : "#1C1F28",
            borderBottom: active[1] ? "2px solid #578FFF" : "none",
            borderRadius: active[1] ? "2px" : "none",
          }}
        >
          Paused
        </p>
        <p
          className={classes.filters}
          style={{
            color: active[2] ? "#578FFF" : "#1C1F28",
            borderBottom: active[2] ? "2px solid #578FFF" : "none",
            borderRadius: active[2] ? "2px" : "none",
          }}
          onClick={() => {
            setActive([0, 0, 1]);
            setToFilter("DELETED");
          }}
        >
          Archived
        </p>
      </div>
      <Card
        className={classes.card}
        onMouseEnter={handleExpandClick}
        onMouseLeave={handleExpandClick}
      >
        <Collapse in={true} timeout="auto" unmountOnExit>
          <CardContent>
            <List
              component="div"
              aria-label="main mailbox folders"
              className=""
            >
              {walkthroughs
                .filter((walkthrough) => walkthrough.status == tofilter)
                .map((walkthrough, index) => {
                  let appType = appOwner.appType.toLowerCase();
                  let id = walkthrough.id;
                  let name = walkthrough.name || "Untitled campaign";
                  let status = walkthrough.status || "ACTIVE";
                  let percentageExposure = walkthrough.percentageExposure;
                  let stats = walkthrough.stats || {
                    shown: "N.A.",
                    completed: "N.A.",
                  };
                  return (
                    <Link
                      to={{
                        pathname: "/app/campaign/" + appType + "/" + id,
                      }}
                    >
                      <ListItem
                        button
                        key={index}
                        style={{
                          borderTop: index == 0 ? "" : "1px solid #ebecf2",
                          minHeight: "120px",
                        }}
                      >
                        <ListItemText
                          primary={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "18px",
                                  lineHeight: "23px",
                                  color: "#1c1f28",
                                  fontWeight: "bold",
                                  width: "30%",
                                }}
                              >
                                {name}
                                <div>
                                  <Tooltip
                                    arrow
                                    title={statusMap[status].title}
                                  >
                                    <Chip
                                      key={index}
                                      style={{
                                        color: statusMap[status].color,
                                        fontWeight: 400,
                                        backgroundColor: "transparent",
                                        border: `1px solid ${statusMap[status].color}`,
                                        borderRadius: "4px",
                                        cursor: "help",
                                        margin: "0px 20px",
                                        width: "120px",
                                        height: "36px",
                                        margin: "15px 0  20px 0",
                                      }}
                                      label={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "10px",
                                              height: "10px",
                                              borderRadius: "50%",
                                              marginRight: "10px",
                                              backgroundColor:
                                                statusMap[status].color,
                                            }}
                                          />
                                          <div>{statusMap[status].text}</div>
                                        </div>
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                  width: "50%",
                                  maxWidth: "500px",
                                  margin: "0 3rem",
                                }}
                              >
                                <li
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Audience</span>
                                  <span style={{ color: "#578fff" }}>
                                    {percentageExposure}%{" "}
                                  </span>
                                </li>
                                <li
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Seen by</span>
                                  <span style={{ color: "#578fff" }}>
                                    {stats.shown}{" "}
                                  </span>
                                </li>
                                <li
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Completed</span>
                                  <span style={{ color: "#578fff" }}>
                                    {stats.completed}{" "}
                                  </span>
                                </li>
                                {/* <li style={{
                            display: "flex",
                            flexDirection: "column"
                          }}
                          ><span style={{ fontWeight: "bold" }}>Converted</span><span>1003 </span></li> */}
                              </ul>
                            </div>
                          }
                          style={{
                            fontFamily: "DM Sans",
                            fontSize: "14px !important",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            lineHeight: "18px",
                            letterSpacing: "0em",
                            textAlign: "left",
                          }}
                        />

                        <Tooltip title="Edit">
                          <IconButton
                            label="Edit"
                            component={Link}
                            to={{
                              pathname: "/app/campaign/" + appType + "/" + id,
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {status != "DELETED" &&
                          (status != "PAUSED" ? (
                            <Tooltip title="Pause">
                              <IconButton
                                label="Pause"
                                onClick={(e) => {
                                  e.preventDefault();
                                  editCampaignStatus(
                                    appOwner,
                                    id,
                                    "PAUSED",
                                    percentageExposure
                                  );
                                }}
                              >
                                <PauseCircleOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Restart">
                              <IconButton
                                label="Restart"
                                onClick={(e) => {
                                  e.preventDefault();
                                  editCampaignStatus(
                                    appOwner,
                                    id,
                                    "ACTIVE",
                                    percentageExposure
                                  );
                                }}
                              >
                                <PlayCircleOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          ))}
                        {status != "DELETED" ? (
                          <Tooltip title="Archive">
                            <IconButton
                              label="Delete"
                              style={{ color: "rgb(165 165 165)" }}
                            >
                              <ArchiveIcon
                                onClick={(e) => {
                                  e.preventDefault();
                                  editCampaignStatus(
                                    appOwner,
                                    id,
                                    "DELETED",
                                    percentageExposure
                                  );
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Unarchive">
                            <IconButton
                              label="Unarchive"
                              style={{ color: "rgb(165 165 165)" }}
                            >
                              <UnarchiveIcon
                                onClick={(e) => {
                                  e.preventDefault();
                                  editCampaignStatus(
                                    appOwner,
                                    id,
                                    "PAUSED",
                                    percentageExposure
                                  );
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ListItem>
                    </Link>
                  );
                })}
            </List>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({
  appOwner: state.loginData.appOwner,
  walkthroughs: state.campaigns.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editCampaignStatus }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
