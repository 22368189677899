import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { withRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { withCookies } from "react-cookie";

import Header from "./components/header";
import Footer from "./components/footer";
import AppMain from "./components/appMain";
import AppSettings from "./components/settings";
import HttpsRedirect from "react-https-redirect";
import Login from "./components/common/login";
import EditCampaign from "./components/edit-campaign/index";
import EditCampaignAndroid from "./components/edit-campaign-android/index";
import EditCampaignWeb from "./components/edit-campaign-web/index";
import LandingPage from "./landing-page";
import Snippets from "./components/settings/snippets";
import Profile from "./components/settings/profile";
import TeamSettings from "./components/settings/teamSettings";
import Subscriptions from "./components/settings/subscriptions";

const PrivateRoute = ({
  component: Component,
  isAuthenticated: IsAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      return IsAuthenticated === true ||
        (props.location.state && props.location.state.isAuthenticated) ||
        !props.location.search ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { to: props.location, showAuth: true },
          }}
        />
      );
    }}
  />
);

class WrapperComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
    };

    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;
    if (pathname !== prevProps.location.pathname) {
      window.flowdash.onRouteChange();
    }
  }

  signIn(next) {
    this.setState({
      isAuthenticated: true,
    });
    next();
  }

  signOut() {
    this.setState({
      isAuthenticated: false,
    });
  }
  render() {
    return (
      <Switch>
        <Route
          default
          exact
          path="/"
          render={(props) => (
            <LandingPage
              isAuthenticated={this.state.isAuthenticated}
              signIn={this.signIn}
              {...props}
            />
          )}
        />
        <Route exact path="/app" component={AppMain} />
        <Route
          exact
          path="/app/settings"
          render={() => <AppSettings toRender={Snippets} />}
        />
        <Route
          exact
          path="/app/settings/profile"
          render={() => <AppSettings toRender={Profile} />}
        />
        <Route
          exact
          path="/app/settings/team"
          render={() => <AppSettings toRender={TeamSettings} />}
        />
        <Route
          exact
          path="/app/settings/snippets"
          render={() => <AppSettings toRender={Snippets} />}
        />
        <Route
          exact
          path="/app/settings/subscription"
          render={() => <AppSettings toRender={Subscriptions} />}
        />
        <Route
          exact
          path="/app/campaign/rn/:campaignId"
          isAuthenticated={this.state.isAuthenticated}
          signOut={this.signOut}
          component={EditCampaign}
        />

        <Route
          exact
          path="/app/campaign/android/:campaignId"
          isAuthenticated={this.state.isAuthenticated}
          signOut={this.signOut}
          component={EditCampaignAndroid}
        />
        <Route
          exact
          path="/app/campaign/web/:campaignId"
          isAuthenticated={this.state.isAuthenticated}
          signOut={this.signOut}
          component={EditCampaignWeb}
        />
        <Route
          exact
          path="/login"
          render={(props) => <Login signIn={this.signIn} {...props} />}
        />
      </Switch>
    );
  }
}
WrapperComponent = withCookies(withRouter(WrapperComponent));

class App extends Component {
  render() {
    return (
      <HttpsRedirect>
        <CookiesProvider>
          <Router basename={process.env.PUBLIC_URL}>
            <WrapperComponent />
          </Router>
        </CookiesProvider>
      </HttpsRedirect>
    );
  }
}

export default App;
