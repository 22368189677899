import React from "react";
import clsx from "clsx";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import Slider from "@material-ui/core/Slider";
import ValueLabelComponent from '../common/valueLabelComponent';
import { SwatchesPicker } from "react-color";
import TextField from "@material-ui/core/TextField";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CodeIcon from "@material-ui/icons/Code";
import LoopIcon from '@material-ui/icons/Loop';
import "./style-editor.css";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from '@material-ui/icons/Help';

const PrettoSlider = withStyles({
  root: {
    color: "#418ef1",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function TooltipStyleControls(props) {

  const [expanded, setExpanded] = React.useState("panel5");

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    classes,
    currentCampaignDraft,
    currentStepIndex,
    handleNumberChange,
    handleColorChange,
    handleStateChange,
    isIntroView,
    handleTooltipDirectionChange,
  } = props;

  const handleSpotlightToggle = (event, bool) => {
    props.editCurrentCampaignDraftWeb({
      [event.target.name]: !bool,
    });
  }

  return (
    <>
      <Accordion
        elevation={0}
        expanded={expanded === "panel5"}
        onChange={handleAccordianChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.subHeading}>Tooltip</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              width: "100%",
            }}
          >
            {!isIntroView && <span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#1C1F28',
                  fontWeight: 100
                }}> Spotlight view <Tooltip arrow title="This will highlight the element & make everything else in the background translucent">
                    <HelpIcon style={{
                      padding: "5px",
                      color: 'rgb(172 172 172)',
                      cursor: "help",
                    }} /></Tooltip></h5>
                <div>
                  <label class="isSpotlight">
                    <input type="checkbox" id="mode"
                      checked={currentCampaignDraft[currentStepIndex].isSpotlight}
                      onClick={(evt) => handleSpotlightToggle(evt, currentCampaignDraft[currentStepIndex].isSpotlight)}
                      color="default"
                      name="isSpotlight"
                    ></input>
                    <span class="slider round"></span>
                  </label>
                </div>
              </span>
            </span>}
            {!isIntroView && <span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }}> Orientation</h5>
                <LoopIcon
                  style={{
                    color: '#7ea7ff',
                    fontSize: '2rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => { handleTooltipDirectionChange(); }} />
              </span>
            </span>}
            <span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }} > Background Color</h5>
                <SwatchesPicker
                  onChangeComplete={(color, event) =>
                    handleColorChange(
                      "tooltip",
                      "backgroundColor",
                      color,
                      event,
                      "tooltip"
                    )
                  }
                />
              </span>
            </span>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={expanded === "panel1"}
        onChange={handleAccordianChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.subHeading}>Primary Text</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              width: "100%",
            }}
          >
            <span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }}>Color</h5>
                <SwatchesPicker
                  onChangeComplete={(color, event) =>
                    handleColorChange("primaryText", "color", color, event, "tooltip")
                  }
                />
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }} >Font size</h5>
                <TextField
                  type="number"
                  onChange={(event) => {
                    handleNumberChange(
                      "primaryText",
                      "fontSize",
                      event.target.value,
                      "tooltip"
                    );
                  }}
                  type="number"
                  defaultValue={10}
                  helperText="Value in px"
                  value={
                    currentCampaignDraft[currentStepIndex].tooltipStyle.primaryText
                      .fontSize
                  }
                  size="small"
                  style={{
                    margin: "20px 0px",
                  }}
                />
              </span>
            </span>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={expanded === "panel2"}
        onChange={handleAccordianChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.subHeading}>
            Secondary Text
              </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              width: "100%",
            }}
          >
            <span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }} >Color</h5>
                <SwatchesPicker
                  onChangeComplete={(color, event) =>
                    handleColorChange(
                      "secondaryText",
                      "color",
                      color,
                      event,
                      "tooltip"
                    )
                  }
                />
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }} >Font size</h5>
                <TextField
                  type="number"
                  onChange={(event) => {
                    handleNumberChange(
                      "secondaryText",
                      "fontSize",
                      event.target.value,
                      "tooltip"
                    );
                  }}
                  defaultValue={10}
                  helperText="Value in px"
                  value={
                    currentCampaignDraft[currentStepIndex].tooltipStyle
                      .secondaryText
                      ? currentCampaignDraft[currentStepIndex].tooltipStyle
                        .secondaryText.fontSize
                      : 10
                  }
                  size="small"
                  style={{
                    margin: "20px 0px",
                  }}
                />
              </span>
            </span>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={expanded === "panel3"}
        onChange={handleAccordianChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.subHeading}>Skip Text</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              width: "100%",
            }}
          >
            <span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }} >Color</h5>
                <SwatchesPicker
                  onChangeComplete={(color, event) =>
                    handleColorChange(
                      "skipActionText",
                      "color",
                      color,
                      event,
                      "tooltip"
                    )
                  }
                />
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }} >Font size</h5>
                <TextField
                  type="number"
                  onChange={(event) => {
                    handleNumberChange(
                      "skipActionText",
                      "fontSize",
                      event.target.value,
                      "tooltip"
                    );
                  }}
                  defaultValue={10}
                  helperText="Value in px"
                  value={
                    currentCampaignDraft[currentStepIndex].tooltipStyle
                      .skipActionText.fontSize
                  }
                />
              </span>
            </span>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={expanded === "panel4"}
        onChange={handleAccordianChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.subHeading}>CTA Style</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              width: "100%",
            }}
          >
            <span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }} >Color</h5>
                <SwatchesPicker
                  onChangeComplete={(color, event) =>
                    handleColorChange("actionText", "color", color, event, "tooltip")
                  }
                />
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                }}
              >
                <h5 style={{ color: '#1C1F28', fontWeight: 100 }} >Background Color</h5>
                <SwatchesPicker
                  onChangeComplete={(color, event) =>
                    handleColorChange(
                      "actionView",
                      "backgroundColor",
                      color,
                      event,
                      "tooltip"
                    )
                  }
                />
              </span>
              <span
                style={{
                  display: "grid",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <h5 style={{ color: '#1C1F28', fontWeight: 100 }} >Font size</h5>
                  <TextField
                    type="number"
                    onChange={(event) => {
                      handleNumberChange(
                        "actionText",
                        "fontSize",
                        event.target.value,
                        "tooltip"
                      );
                    }}
                    defaultValue={10}
                    helperText="Value in px"
                    value={
                      currentCampaignDraft[currentStepIndex].tooltipStyle
                        .actionText.fontSize
                    }
                  />
                </span>
              </span>
            </span>
          </div>
        </AccordionDetails>
      </Accordion>
    </>);
}

function AbsoluteControls(props) {
  const handleSliderChange = (property, newValue) => {
    const { source, handleNumberChange, template } = props;
    handleNumberChange(source, property, newValue, template);
  };

  return (
    <>
      <span>
        <h5>Left</h5>
        <PrettoSlider
          ValueLabelComponent={ValueLabelComponent}
          aria-label="custom thumb label"
          defaultValue={0}
          min={-400}
          max={400}
          onChange={(_, newValue) => handleSliderChange("left", newValue)}
        />
      </span>
      <span>
        <h5>Top</h5>
        <PrettoSlider
          ValueLabelComponent={ValueLabelComponent}
          aria-label="custom thumb label"
          defaultValue={0}
          min={-400}
          max={400}
          onChange={(_, newValue) => handleSliderChange("top", newValue)}
        />
      </span>
      <span>
        <h5>Bottom</h5>
        <PrettoSlider
          ValueLabelComponent={ValueLabelComponent}
          aria-label="custom thumb label"
          defaultValue={0}
          min={-400}
          max={400}
          onChange={(_, newValue) => handleSliderChange("bottom", newValue)}
        />
      </span>
      <span>
        <h5>Right</h5>
        <PrettoSlider
          ValueLabelComponent={ValueLabelComponent}
          aria-label="custom thumb label"
          defaultValue={0}
          min={-400}
          max={400}
          onChange={(_, newValue) => handleSliderChange("right", newValue)}
        />
      </span>
    </>
  );
}

function RelativeControls(props) {
  const handleSliderChange = (property, newValue) => {
    const { source, handleRelativePositionChange } = props;
    handleRelativePositionChange(source, property, newValue);
  };

  return (
    <>
      <span>
        <h5>Left</h5>
        <PrettoSlider
          ValueLabelComponent={ValueLabelComponent}
          aria-label="custom thumb label"
          defaultValue={0}
          min={-200}
          max={200}
          onChange={(_, newValue) => handleSliderChange("left", newValue)}
        />
      </span>
      <span>
        <h5>Top</h5>
        <PrettoSlider
          ValueLabelComponent={ValueLabelComponent}
          aria-label="custom thumb label"
          defaultValue={0}
          min={-200}
          max={200}
          onChange={(_, newValue) => handleSliderChange("top", newValue)}
        />
      </span>
    </>
  );
}

export default function StyleEditor(props) {
  const {
    classes,
    currentCampaignDraft,
    currentStepIndex,
    handleStateChange,
    handleRawStyleChange,
    handleStyleChange,
    togglePositionControls,
    template,
    editCurrentCampaignDraftWeb,
    isIntroView,
  } = props;

  const [raw, setRaw] = React.useState(false);

  const handleChange = (event) => {
    setRaw(!raw);
  };

  const [expanded, setExpanded] = React.useState("panel0");

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleColorChange = (source, property, color, event, template) => {
    handleStyleChange(source, property, color.hex, template);
  };

  const handleNumberChange = (source, property, value, template) => {
    let no = parseInt(value);
    no = no === NaN ? 0 : no;
    handleStyleChange(source, property, no, template);
  };

  const handleRelativePositionChange = (source, property, value) => {
    let no = parseInt(value);
    no = no === NaN ? 0 : no;
    let positiveSuffix =
      property == "left"
        ? "this.state.focusedView.width + "
        : "this.state.focusedView.height + ";
    let newValue =
      "this.state.focusedView." +
      property +
      (no > 0 ? " + " + positiveSuffix : " - ") +
      String(Math.abs(no));
    handleStyleChange(source, property, newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >

      {raw ? (
        <Editor
          value={currentCampaignDraft[currentStepIndex].code}
          onValueChange={handleRawStyleChange}
          highlight={(code) => highlight(code, languages.js)}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            background: "#f7f8fa",
            margin: "40px 0px",
            maxHeight: "490px",
            overflowY: "scroll",
          }}
        />
      ) : (
          <div
          > { template == 0 ?
            <TooltipStyleControls
              isIntroView={isIntroView}
              editCurrentCampaignDraftWeb={editCurrentCampaignDraftWeb}
              handleStateChange={handleStateChange}
              handleNumberChange={handleNumberChange}
              handleColorChange={handleColorChange} {...props} /> : <>
              <Accordion
                elevation={0}
                expanded={expanded === "panel0"}
                onChange={handleAccordianChange("panel0")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.subHeading}>Step Number</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Color</h5>
                        <SwatchesPicker
                          onChangeComplete={(color, event) =>
                            handleColorChange("indexView", "color", color, event)
                          }
                        />
                      </span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Font size</h5>
                        <TextField
                          type="number"
                          onChange={(event) => {
                            handleNumberChange(
                              "indexView",
                              "fontSize",
                              event.target.value
                            );
                          }}
                          type="number"
                          defaultValue={10}
                          helperText="Value in px"
                          value={
                            currentCampaignDraft[currentStepIndex].style.indexView ? currentCampaignDraft[currentStepIndex].style.indexView
                              .fontSize : 10
                          }
                          size="small"
                          style={{
                            margin: "20px 0px",
                          }}
                        />
                      </span>
                    </span>
                    <h5>Position</h5>
                    <span>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              currentCampaignDraft[currentStepIndex].controls
                                .indexView
                            }
                            onChange={(evt) =>
                              togglePositionControls(
                                "controls",
                                "indexView",
                                true
                              )
                            }
                            color="default"
                            name="useRelativePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Relative to selected view"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !currentCampaignDraft[currentStepIndex].controls
                                .indexView
                            }
                            onChange={(evt) =>
                              togglePositionControls(
                                "controls",
                                "indexView",
                                false
                              )
                            }
                            color="default"
                            name="useAbsolutePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Absolute"
                      />{" "}
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridColumnGap: "15px",
                      }}
                    >
                      {currentCampaignDraft[currentStepIndex].controls
                        .indexView ? (
                          <RelativeControls
                            source="indexView"
                            handleRelativePositionChange={
                              handleRelativePositionChange
                            }
                          />
                        ) : (
                          <AbsoluteControls
                            source="indexView"
                            handleNumberChange={handleNumberChange}
                          />
                        )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              {
                currentCampaignDraft[currentStepIndex].style
                  .wrapper &&
                <Accordion
                  elevation={0}
                  expanded={expanded === "panel5"}
                  onChange={handleAccordianChange("panel5")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.subHeading}>View wrapper</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <span>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h5>Border Color</h5>
                          <SwatchesPicker
                            onChangeComplete={(color, event) =>
                              handleColorChange("wrapper", "borderColor", color, event)
                            }
                          />
                        </span>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h5>Border Width</h5>
                          <TextField
                            type="number"
                            onChange={(event) => {
                              handleNumberChange(
                                "wrapper",
                                "borderWidth",
                                event.target.value
                              );
                            }}
                            type="number"
                            defaultValue={10}
                            helperText="Value in px"
                            value={
                              currentCampaignDraft[currentStepIndex].style.wrapper
                                .borderWidth
                            }
                            size="small"
                            style={{
                              margin: "20px 0px",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h5>Border Radius</h5>
                          <TextField
                            type="number"
                            onChange={(event) => {
                              handleNumberChange(
                                "wrapper",
                                "borderRadius",
                                event.target.value
                              );
                            }}
                            type="number"
                            defaultValue={10}
                            helperText="Value in px"
                            value={
                              currentCampaignDraft[currentStepIndex].style.wrapper
                                .borderRadius
                            }
                            size="small"
                            style={{
                              margin: "20px 0px",
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </AccordionDetails>
                </Accordion>
              }
              <Accordion
                elevation={0}
                expanded={expanded === "panel1"}
                onChange={handleAccordianChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.subHeading}>Primary Text</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Color</h5>
                        <SwatchesPicker
                          onChangeComplete={(color, event) =>
                            handleColorChange("primaryText", "color", color, event)
                          }
                        />
                      </span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Font size</h5>
                        <TextField
                          type="number"
                          onChange={(event) => {
                            handleNumberChange(
                              "primaryText",
                              "fontSize",
                              event.target.value
                            );
                          }}
                          type="number"
                          defaultValue={10}
                          helperText="Value in px"
                          value={
                            currentCampaignDraft[currentStepIndex].style.primaryText
                              .fontSize
                          }
                          size="small"
                          style={{
                            margin: "20px 0px",
                          }}
                        />
                      </span>
                    </span>
                    <h5>Position</h5>
                    <span>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              currentCampaignDraft[currentStepIndex].controls
                                .primaryText
                            }
                            onChange={(evt) =>
                              togglePositionControls(
                                "controls",
                                "primaryText",
                                true
                              )
                            }
                            color="default"
                            name="useRelativePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Relative to selected view"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !currentCampaignDraft[currentStepIndex].controls
                                .primaryText
                            }
                            onChange={(evt) =>
                              togglePositionControls(
                                "controls",
                                "primaryText",
                                false
                              )
                            }
                            color="default"
                            name="useAbsolutePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Absolute"
                      />{" "}
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridColumnGap: "15px",
                      }}
                    >
                      {currentCampaignDraft[currentStepIndex].controls
                        .primaryText ? (
                          <RelativeControls
                            source="primaryText"
                            handleRelativePositionChange={
                              handleRelativePositionChange
                            }
                          />
                        ) : (
                          <AbsoluteControls
                            source="primaryText"
                            handleNumberChange={handleNumberChange}
                          />
                        )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                expanded={expanded === "panel2"}
                onChange={handleAccordianChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.subHeading}>
                    Secondary Text
              </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Color</h5>
                        <SwatchesPicker
                          onChangeComplete={(color, event) =>
                            handleColorChange(
                              "secondaryText",
                              "color",
                              color,
                              event
                            )
                          }
                        />
                      </span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Font size</h5>
                        <TextField
                          type="number"
                          onChange={(event) => {
                            handleNumberChange(
                              "secondaryText",
                              "fontSize",
                              event.target.value
                            );
                          }}
                          defaultValue={10}
                          helperText="Value in px"
                          value={
                            currentCampaignDraft[currentStepIndex].style
                              .secondaryText
                              ? currentCampaignDraft[currentStepIndex].style
                                .secondaryText.fontSize
                              : 10
                          }
                          size="small"
                          style={{
                            margin: "20px 0px",
                          }}
                        />
                      </span>
                    </span>
                    <h5>Position</h5>
                    <span>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              currentCampaignDraft[currentStepIndex].controls
                                .secondaryText
                            }
                            onChange={(evt) =>
                              togglePositionControls(
                                "controls",
                                "secondaryText",
                                true
                              )
                            }
                            color="default"
                            name="useRelativePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Relative to selected view"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !currentCampaignDraft[currentStepIndex].controls
                                .secondaryText
                            }
                            onChange={(evt) =>
                              togglePositionControls(
                                "controls",
                                "secondaryText",
                                false
                              )
                            }
                            color="default"
                            name="useAbsolutePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Absolute"
                      />{" "}
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridColumnGap: "15px",
                      }}
                    >
                      {currentCampaignDraft[currentStepIndex].controls
                        .secondaryText ? (
                          <RelativeControls
                            source="secondaryText"
                            handleRelativePositionChange={
                              handleRelativePositionChange
                            }
                          />
                        ) : (
                          <AbsoluteControls
                            source="secondaryText"
                            handleNumberChange={handleNumberChange}
                          />
                        )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                expanded={expanded === "panel3"}
                onChange={handleAccordianChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.subHeading}>Skip Text</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Color</h5>
                        <SwatchesPicker
                          onChangeComplete={(color, event) =>
                            handleColorChange(
                              "skipActionText",
                              "color",
                              color,
                              event
                            )
                          }
                        />
                      </span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Font size</h5>
                        <TextField
                          type="number"
                          onChange={(event) => {
                            handleNumberChange(
                              "skipActionText",
                              "fontSize",
                              event.target.value
                            );
                          }}
                          defaultValue={10}
                          helperText="Value in px"
                          value={
                            currentCampaignDraft[currentStepIndex].style
                              .skipActionText.fontSize
                          }
                        />
                      </span>
                    </span>
                    <h5>Position</h5>
                    <span>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              currentCampaignDraft[currentStepIndex].controls
                                .skipAction
                            }
                            onChange={(evt) =>
                              togglePositionControls("controls", "skipAction", true)
                            }
                            color="default"
                            name="useRelativePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Relative to selected view"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !currentCampaignDraft[currentStepIndex].controls
                                .skipAction
                            }
                            onChange={(evt) =>
                              togglePositionControls(
                                "controls",
                                "skipAction",
                                false
                              )
                            }
                            color="default"
                            name="useAbsolutePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Absolute"
                      />{" "}
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridColumnGap: "15px",
                      }}
                    >
                      {currentCampaignDraft[currentStepIndex].controls
                        .skipAction ? (
                          <RelativeControls
                            source="skipAction"
                            handleRelativePositionChange={
                              handleRelativePositionChange
                            }
                          />
                        ) : (
                          <AbsoluteControls
                            source="skipAction"
                            handleNumberChange={handleNumberChange}
                          />
                        )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                expanded={expanded === "panel4"}
                onChange={handleAccordianChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.subHeading}>CTA Style</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "5px",
                        }}
                      >
                        <h5>Color</h5>
                        <SwatchesPicker
                          onChangeComplete={(color, event) =>
                            handleColorChange("actionText", "color", color, event)
                          }
                        />
                      </span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "5px",
                        }}
                      >
                        <h5>Background Color</h5>
                        <SwatchesPicker
                          onChangeComplete={(color, event) =>
                            handleColorChange(
                              "actionView",
                              "backgroundColor",
                              color,
                              event
                            )
                          }
                        />
                      </span>
                      <span
                        style={{
                          display: "grid",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <h5>Font size</h5>
                          <TextField
                            type="number"
                            onChange={(event) => {
                              handleNumberChange(
                                "actionText",
                                "fontSize",
                                event.target.value
                              );
                            }}
                            defaultValue={10}
                            helperText="Value in px"
                            value={
                              currentCampaignDraft[currentStepIndex].style
                                .actionText.fontSize
                            }
                          />
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <h5>Width</h5>
                          <TextField
                            type="number"
                            onChange={(event) => {
                              handleNumberChange(
                                "actionView",
                                "width",
                                event.target.value
                              );
                            }}
                            defaultValue={10}
                            helperText="Value in px"
                            value={
                              currentCampaignDraft[currentStepIndex].style
                                .actionView.width
                            }
                            size="small"
                            style={{
                              margin: "20px 0px",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <h5>Height</h5>
                          <TextField
                            type="number"
                            onChange={(event) => {
                              handleNumberChange(
                                "actionView",
                                "height",
                                event.target.value
                              );
                            }}
                            defaultValue={10}
                            helperText="Value in px"
                            value={
                              currentCampaignDraft[currentStepIndex].style
                                .actionView.height
                            }
                            size="small"
                            style={{
                              margin: "20px 0px",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <h5>Border Radius</h5>
                          <TextField
                            type="number"
                            onChange={(event) => {
                              handleNumberChange(
                                "actionView",
                                "borderRadius",
                                event.target.value
                              );
                            }}
                            defaultValue={10}
                            helperText="Value in px"
                            value={
                              currentCampaignDraft[currentStepIndex].style
                                .actionView.borderRadius
                            }
                            size="small"
                            style={{
                              margin: "20px 0px",
                            }}
                          />
                        </span>
                      </span>
                    </span>
                    <h5>Position</h5>
                    <span>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              currentCampaignDraft[currentStepIndex].controls
                                .actionView
                            }
                            onChange={(evt) =>
                              togglePositionControls("controls", "actionView", true)
                            }
                            color="default"
                            name="useRelativePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Relative to selected view"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !currentCampaignDraft[currentStepIndex].controls
                                .actionView
                            }
                            onChange={(evt) =>
                              togglePositionControls(
                                "controls",
                                "actionView",
                                false
                              )
                            }
                            color="default"
                            name="useAbsolutePosition"
                            checkedIcon={
                              <span
                                className={clsx(classes.icon, classes.checkedIcon)}
                              />
                            }
                            icon={<span className={classes.icon} />}
                          />
                        }
                        label="Absolute"
                      />{" "}
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridColumnGap: "15px",
                      }}
                    >
                      {currentCampaignDraft[currentStepIndex].controls
                        .actionView ? (
                          <RelativeControls
                            source="actionView"
                            handleRelativePositionChange={
                              handleRelativePositionChange
                            }
                          />
                        ) : (
                          <AbsoluteControls
                            source="actionView"
                            handleNumberChange={handleNumberChange}
                          />
                        )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </>}
          </div>
        )}
    </div>
  );
}
