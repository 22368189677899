import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ColorPicker from "./ColorPicker";
import { Swatch } from "./StaticComponents";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import LoopIcon from '@material-ui/icons/Loop';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from '@material-ui/icons/Help';

function TooltipElement({ title, propText, handleTextChange,
    propFontSize, handleFontChange,
    propColor, handleColorChange, isIntroView,
    handleTooltipDirectionChange, isSpotlight,
    handleSpotlightToggle, headerImage, handleImageChange }) {
    const [color, setColor] = useState(propColor);
    const [text, setText] = useState(propText);
    const [fontStyle, setFontStyle] = useState("normal");
    const [fontSize, setFontSize] = useState(propFontSize);
    const [fontType, setFontType] = useState("DM Sans");
    const [showColorPicker, setShowColorPicker] = useState(false);
    const sizes = [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32];

    useEffect(() => {
        setFontSize(propFontSize);
        setText(propText);
        setColor(propColor)
    }, [propFontSize, propText, propColor, isSpotlight, headerImage])

    const styles = {
        input: {
            height: "2.5rem",
            width: "20rem",
            boxSizing: "border-box",
            borderRadius: "4px",
            border: "2px solid #e5e5e5",
            paddingLeft: "10px",
        },
        select: {
            lineHeight: "16px",
            color: "#578FFF",
            marginleft: "2rem",
            border: "none"
        },
        seperator: {
            width: "90%",
            color: "#E5E5E5",
            border: 'none',
            height: 1,
            backgroundColor: '#E5E5E5'
        },
    };

    const onColorChange = (color) => {
        setColor(color);
        console.log(color);
        handleColorChange(color);
    };
    const handleClose = () => {
        setShowColorPicker(!showColorPicker);
    };

    const handleClickAway = () => {
        setShowColorPicker(false);
    };

    return (
        <div
            style={{
                width: "100%",
                margin: "0.45rem 0",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#1C1F28",
                
            }}
        >
            {title}
            {isIntroView &&
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 20,
                        marginBottom: 15,
                        alignItems: 'center',
                    }}
                >
                    <label style={{ marginRight: "1rem", color: "#8C8C8C", flex: 0.2 }}>
                        Image URL:
                    </label>
                    <input
                        style={{ ...styles.input, flex: 0.8, marginRight: 8 }}
                        value={headerImage}
                        onChange={(e) => {
                            setShowColorPicker(false);
                            setText(e.target.value);
                            handleImageChange(e);
                        }}
                    />
                </div>}
            {
                !isIntroView && <span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: 'center'
                        }}
                    >
                        <h5 style={{ color: 'rgb(140, 140, 140)', fontWeight: 100, fontSize: 12 }}> Orientation</h5>
                        <LoopIcon
                            style={{
                                color: '#7ea7ff',
                                fontSize: '1.8rem',
                                cursor: 'pointer'
                            }}
                            onClick={() => { handleTooltipDirectionChange(); }} />
                    </span>
                </span>
            }
            {
                !isIntroView && <span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: 'center'
                        }}
                    >
                        <h5 style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'rgb(140, 140, 140)',
                            fontWeight: 100,
                            fontSize: 12,
                        }}> Spotlight view <Tooltip arrow title="This will highlight the element & make everything else in the background translucent">
                                <HelpIcon style={{
                                    padding: "5px",
                                    color: 'rgb(172 172 172)',
                                    cursor: "help",
                                }} /></Tooltip></h5>
                        <div>
                            <label class="isSpotlight">
                                <input type="checkbox" id="mode"
                                    checked={isSpotlight}
                                    onClick={(evt) => handleSpotlightToggle(evt, isSpotlight)}
                                    color="default"
                                    name="isSpotlight"
                                ></input>
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </span>
                </span>
            }
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    marginBottom: "10px",
                    position: "relative"
                }}
            >
                <label style={{ marginRight: "1rem", color: "#8C8C8C", flex: 0.85 }}>
                    Background color:
                </label>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div style={{ flex: 0.1 }}>
                        <Swatch
                            onClick={() => setShowColorPicker(!showColorPicker)}
                            bgColor={color?.hex || color}
                        />
                        {showColorPicker && (
                            <ColorPicker
                                color={color}
                                onChange={onColorChange}
                                handleClose={handleClose}
                            />
                        )}
                    </div>
                </ClickAwayListener>
            </div>

            <hr style={styles.seperator} />
        </div >
    );
}

export default TooltipElement;
