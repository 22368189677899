import React from 'react';
import './App.css';
import Header from './Components/Header';
import Banner from './Components/Banner';
import Section1 from './Components/Section1';
import Section2 from './Components/Section2';
import Section3 from './Components/Section3';
import Section4 from './Components/Section4';
import MultiLanguage from './Components/MultiLanguage';
import Footer from './Components/Footer';
import MiniFooter from './Components/MiniFooter';
import esther from './Assets/esther.svg';

function App() {
  return (
    <div className='App'>
      <Header />
      <Banner />

      <Section1 subTitle='Join our waiting list along with 40,000 startups.' />
      {/* <Section2
        subTitle='Join our waiting list along with 40,000 startups.'
        quote='"Product Tours allowed us to save hundreds of development hours compared to a custom-built solution."'
        quoteImg={esther}
      /> */}
      <Section3 subTitle='Join our waiting list along with 40,000 startups.' />
      <Section4
        subTitle='Join our waiting list along with 40,000 startups.'
        quote='"Product Tours allowed us to save hundreds of development hours compared to a custom-built solution."'
        quoteImg={esther}
      />

      {/* <MultiLanguage /> */}

      <Footer
        subTitle='Get early access.'
        quoteImg={esther}
      />
      <MiniFooter />
    </div>
  );
}

export default App;
