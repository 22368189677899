import React, { useState } from "react";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import Face from "@material-ui/icons/Face";
import Logo from "../assets/FlowDash_Icon_2x.png";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { useCookies } from "react-cookie";

import SideNav from "./sidenav";
import UserMenu from "./common/user-menu";

const useStyles = makeStyles((theme) => ({
  userIcon: {
    fontSize: "2.1em",
    color: "#d6ba76",
  },
}));

function Nav(props) {
  const classes = useStyles();
  const [change, setChange] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies([
    "circuital_user_email",
    "circuital_user_name",
    "circuital_user_dp"
  ]);

  const changePosition = 80;

  let position = useWindowScrollPosition();
  // position == { x: 0, y: 0 }

  if (position.y > changePosition && !change) {
    setChange(true);
  }

  if (position.y <= changePosition && change) {
    setChange(false);
  }

  let style = {
    backgroundColor: change ? "black" : "transparent",
    boxShadow: change ? "0px 13px 13px -18px rgba(0,0,0,0.75)" : "none",
    color: change ? "black" : "white",
  };

  let navClass = change || props.arena ? "nav-class-active" : "nav-class";

  if (props.arena) {
    navClass += " inarena";
  }
  function openNav() {
    document.getElementById("mySidenav").style.width = "60%";
  }

  return (
    <nav className={navClass}>
      <SideNav
        {...props}
        cookies={cookies}
        removeCookie={removeCookie}
        forceRender={props.forceRender}
      />
      <ul className="logo-container">
        <li>
          <span
            style={{ fontSize: "26px", cursor: "pointer", color: "#bcb9d0" }}
            onClick={openNav}
            className="hamburger"
          >
            {" "}
            <i class="fas fa-bars"></i>
          </span>
        </li>
        <li className="logo">
          <div>
            <img
              src={Logo}
              style={{
                height: "32px",
                margin: "2px 5px -5px",
                padding: "0px 2px",
              }}
            ></img>
            <div style={{ display: "initial" }}>
              <RouterLink
                to="/"
                style={{ display: "initial", color: "inherit" }}
                className="mainLogo"
              >
                 Flow<span>Dash</span>
              </RouterLink>
            </div>
          </div>
        </li>
      </ul>
      <ul className="mainNav">
        {!props.arena && (
          <>
            <li>
              <a
                href="https://docs.flowdash.ai/react-native"
                to="discover"
                smooth={true}
                spy={true}
                className="docs"
                style={{
                  color:'#3e3c3c'
                }}
                activeClass="active"
                offset={-70}
                duration={500}
              >
                Docs
              </a>
            </li>
            <li>
              <a
                href="mailto:team@flowdash.ai?subject=I'm interested in FlowDash!"
                to="discover"
                smooth={true}
                spy={true}
                className="sign-up"
                activeClass="active"
                offset={-70}
                duration={500}
              >
                Contact us
              </a>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

const NavWithRouter = withRouter((props) => <Nav {...props} />);

export default withCookies(NavWithRouter);
