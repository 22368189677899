import React from "react";

export default function PreviewTooltip(props) {

    let tooltipStyle, caretStyle;
    let { focusedView, showIndexView } = props;
    switch (props.direction) {
        case 0:
            tooltipStyle = {
                left: focusedView.left - 5,
                top: focusedView.top - 105,
                flexDirection: 'column'
            }
            caretStyle = {
                width: 0,
                height: 0,
                borderTopColor: props.styleTooltip ? props.styleTooltip.backgroundColor : '#ffffff',
                borderTopWidth: 5,
                borderBottomColor: 'transparent',
                borderBottomWidth: 10,
                borderRightColor: 'transparent',
                borderRightWidth: 10,
                borderLeftColor: 'transparent',
                borderLeftWidth: 10,
                marginLeft: 15,
            }
            break;
        case 1:
            tooltipStyle = {
                left: focusedView.left + focusedView.width + 5,
                top: focusedView.top + (focusedView.height / 2) - 45,
                flexDirection: 'row-reverse'
            }
            caretStyle = {
                width: 0,
                height: 0,
                borderTopColor: 'transparent',
                borderTopWidth: 10,
                borderBottomColor: 'transparent',
                borderBottomWidth: 10,
                borderRightColor: props.styleTooltip ? props.styleTooltip.backgroundColor : '#ffffff',
                borderRightWidth: 5,
                borderLeftColor: 'transparent',
                borderLeftWidth: 10,
                marginTop: 15,
            }
            break;
        case 2:
            tooltipStyle = {
                left: focusedView.left - 5,
                top: focusedView.top + focusedView.height + 5,
                flexDirection: 'column-reverse'
            }
            caretStyle = {
                width: 0,
                height: 0,
                borderTopColor: 'transparent',
                borderTopWidth: 10,
                borderBottomColor: props.styleTooltip ? props.styleTooltip.backgroundColor : '#ffffff',
                borderBottomWidth: 5,
                borderRightColor: 'transparent',
                borderRightWidth: 10,
                borderLeftColor: 'transparent',
                borderLeftWidth: 10,
                marginLeft: 15,
            }
            break;
        case 3:
            tooltipStyle = {
                left: focusedView.left - 200,
                top: focusedView.top + (focusedView.height / 2) - 45,
                flexDirection: 'row'
            }
            caretStyle = {
                width: 0,
                height: 0,
                borderTopColor: 'transparent',
                borderTopWidth: 10,
                borderBottomColor: 'transparent',
                borderBottomWidth: 10,
                borderRightColor: 'transparent',
                borderRightWidth: 10,
                borderLeftColor: props.styleTooltip ? props.styleTooltip.backgroundColor : '#ffffff',
                borderLeftWidth: 5,
                marginTop: 15,
            }
            break;
        default:
            break;
    }
    return (
        <div
            style={{
                position: "absolute",
                zIndex: 2,
                ...tooltipStyle,
                display: 'flex', justifyContent: 'center', alignItems: 'flex-start'
            }}
        >
            <div
                style={{
                    minWidth: 180,
                    height: 80,
                    backgroundColor: 'white',
                    padding: 10,
                    borderRadius: 10,
                    ...props.styleTooltip,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <span
                    style={{
                        ...props.stylePrimaryText,
                        fontWeight: 'bold',
                    }}
                >
                    {props.primaryText}
                </span>
                <span
                    style={props.styleSecondaryText}
                >
                    {props.secondaryText}
                </span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 5,
                }}>
                    {showIndexView && (
                        <span style={{
                            ...props.styleIndexView,
                            flex: 1,
                            fontWeight: 'bold',
                            position: 'relative'
                        }}>
                            Step {props.currentStepIndex}
                        </span>
                    )}
                    <div
                        style={{ ...props.styleActionView, flex: 1 }}
                    >
                        <span
                            style={{ ...props.styleActionText, textAlign: "center" }}>{props.actionText}</span>
                    </div>
                </div>
            </div>
            <div style={{ ...caretStyle, borderStyle: 'solid' }}>
                <span></span>
            </div>

        </div >
    );
}
