import React, { Component } from "react";
import { Button, Input } from "@material-ui/core";
import "../Styles/Banner.css";
import arrow from "../Assets/arrow.svg";
import { toast } from "react-toastify";
import { makeStyles, fade } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Link as RouterLink } from "react-router-dom";
import { CORE_API_BASE_URL } from "../../../constants/api";

toast.configure();

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(51, 129, 218, 0.25)",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: "red",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function RedditTextField(props) {
  const classes = useStylesReddit();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChangeEmail = (evt) => {
    console.log(evt.target.value);
    this.setState({
      email: evt.target.value,
    });
  };

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleSubmit = () => {
    const { email } = this.state;
    if (this.validateEmail(email)) {
      window.analytics.identify(email, {
        email: email,
        flowdash: true,
      });

      // todo - Add code to save email addresses here.
      const saveEmailUrl = CORE_API_BASE_URL + "signup?email=" + email;
      fetch(saveEmailUrl, {
        method: "POST",
      })
        .then((resp) => resp.json())
        .then(() => {
          toast.success(
            "Thanks for your interest! We'll get in touch with you soon."
          );
        })
        .catch(() => {
          toast.error(
            "Bummer. Seems to be an issue with the email. Please try again."
          );
        });
    } else {
      toast.error(
        "Bummer. Seems to be an issue with the email. Please try again."
      );
    }
  };

  render() {
    return (
      <div className="banner">
        <h1 className="banner__title">
          Engage Users through{" "}
          <span className="personalized"> Personalized</span>
          <img src={arrow} alt="" className="arrow__icon" />
          Onboarding
        </h1>

        <h4 className="banner__subTitle">
          Drive users to your app's Aha! moment with personalized onboarding
          using Flowdash.
        </h4>
        <form className="form">
          <RedditTextField
            id="standard-basic"
            variant="filled"
            label="Your work email"
            className="email__input"
            color="white"
            onChange={this.handleChangeEmail}
          />
          <Button
            variant="contained"
            onClick={this.handleSubmit}
            className="banner__button"
          >
            Get early access
          </Button>
        </form>
        <div className="request-access-form">
          <div
            style={{
              textAlign: "left",
              fontSize: "1rem",
              color: "rgb(150, 149, 149)",
              padding: "10px",
            }}
          >
            Already using Flowdash?
            <RouterLink
              to="/login"
              style={{
                color: "rgb(255, 176, 0)",
                padding: "5px",
              }}
            >
              Sign In
            </RouterLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
