import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { editCurrentCampaignDraftAndroid, saveCampaign } from "../../actions/campaigns";
import ContentEditor from "./content-editor";
import AudienceEditor from "./audience-editor";
import StyleEditor from "./style-editor";
import ImagePicker from "../common/image-picker";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

import {
  NAME
} from "../../constants/android"

const imageList = ["https://res.cloudinary.com/dfcnic8wq/image/upload/v1605598107/FlowDash/Tooltip_2_sfd0e2.png", "https://res.cloudinary.com/dfcnic8wq/image/upload/v1605597724/FlowDash/Tooltip_1_erapxz.png"

  // TODO - Uncomment for hotspots
  // ,"https://res.cloudinary.com/dfcnic8wq/image/upload/v1605598835/FlowDash/Tooltip_3_py3w05.png"
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    textAlign: "left",
    padding: "10px",
  },
  icon: {
    borderRadius: 2,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#418ef1",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#418ef1",
    },
  },
  heading: {
    fontWeight: 700,
  }
});

const useParentStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
});

const EditCampaignTabAndroid = function CenteredTabs(props) {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const [value, setValue] = React.useState("one");
  const [template, setTemplate] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCampaignChange = (payload) => {
    props.editCurrentCampaignDraftAndroid(payload);
  }

  let { currentCampaignDraft, currentStepIndex, type, campaign } = props;
  let { campaignId } = props.match.params;
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "50px",
        }}
      >
        <TextField
          label="Name of your campaign"
          onChange={(ev) => {
            handleCampaignChange({
              changeType: NAME,
              changeValue: ev.target.value
            });
          }}
          defaultValue={currentCampaignDraft.name}
          helperText="Your campaign name eg: First user experience at ACME"
          size="small"
          style={{
            margin: "40px 20px",
          }}
        />
        <></>
        <AudienceEditor
          currentCampaignDraft={currentCampaignDraft}
          handleCampaignChange={handleCampaignChange}
        />
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        classes={parentClasses.root}
      >
        <Tab label="Content" value="one" {...a11yProps("one")} />
        <Tab label="Style" value="two" {...a11yProps("two")} />
      </Tabs>
      <TabPanel value={value} index="one">
        <ContentEditor
          currentCampaignDraft={currentCampaignDraft}
          currentStepIndex={currentStepIndex}
          handleCampaignChange={handleCampaignChange}
          classes={classes}
          template={type}
        />
      </TabPanel>
      <TabPanel value={value} index="two">
        <StyleEditor
          currentCampaignDraft={currentCampaignDraft}
          handleCampaignChange={handleCampaignChange}
        />
      </TabPanel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaigns.currentCampaign,
  currentStepIndex: state.campaigns.currentStepIndex,
  currentCampaignDraft: state.campaigns.currentCampaignDraft,
  type: 0,
  isDirty: state.campaigns.currentCampignIsDirty,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editCurrentCampaignDraftAndroid,
      saveCampaign,

    },
    dispatch
  );
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCampaignTabAndroid));
