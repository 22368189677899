import {
  LOGIN_FULFILLED,
  LOGIN_REQUESTED,
  LOGIN_REJECTED,
} from "../constants/actions";
import { CORE_API_BASE_URL, HOUSEWARE_NOTIFY_URL } from "../constants/api";

const loginRequested = () => ({
  type: LOGIN_REQUESTED,
});

const loginRejected = (error) => ({
  type: LOGIN_REJECTED,
  payload: error,
});

export const loginFulflled = (data) => ({
  type: LOGIN_FULFILLED,
  payload: data,
});

export const login = (loginResponse, setCookie) => async (dispatch) => {
  dispatch(loginRequested());
  try {
    const userEmail = loginResponse.profileObj.email;
    const loginUrl = CORE_API_BASE_URL + "login?email=" + userEmail;

    fetch(loginUrl, {
      method: "POST",
      body: JSON.stringify({
        userDetails: loginResponse.profileObj,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        let { success } = data;
        if (success) {
          console.log("fulfilled", data);
          let user = data.userDetails;
          if (window.analytics) {
            window.analytics.identify(user.googleId, {
              email: user.email,
              name: user.name,
              displayName: user.givenName,
              imageUrl: user.imageUrl,
              createdAt: new Date().getTime(0),
            });
          }
          if (window.flowdash) {
            console.log("Flowdash Identify called.");
            window.flowdash.identify(user.googleId, {
              email: user.email,
              name: user.name,
              displayName: user.givenName,
              imageUrl: user.imageUrl,
              createdAt: new Date().getTime(0),
            });
          }
          setCookie("fd_user_email", user.email);
          setCookie("fd_user_name", user.name);
          setCookie("fd_user_dp", user.imageUrl);
          setCookie("fb_account_guid", data.data.guid);
          setCookie("fd_account_api_key", data.data.apiKey);
          setCookie("fd_account_domain", data.data.domain);
          setCookie("fd_user_googleId", user.googleId);

          dispatch(loginFulflled(data));
          let message = `*User ${user.email} signed in* on Flowdash`
          fetch(HOUSEWARE_NOTIFY_URL, {
            mode: "cors",
            headers: {
              "content-Type": "application/json",
              // "x-api-key": location.appOwner?.apiKey,
            },
            method: "POST",
            body: JSON.stringify({
              text: message
            }),
          })
            .then((r) => console.log(r)).catch(e => console.log(e));
        } else {
          dispatch(loginRejected());
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(loginRejected(e));
      });
  } catch (err) {
    dispatch(loginRejected(err));
  }
};
