import React from "react";
import "../Styles/Section4.css";
import Esther from "../Assets/person1.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";

function Section1() {
  return (
    <div className="section4">
      <div className="section4__Right">
        <div className="red__box">
          <div className="eye__icon">
            <VisibilityIcon fontSize="large" />
          </div>
        </div>
        <h1>
          <span className="code__red">Get Insights</span> on which features are
          adopted well and which need nudging.
        </h1>
        {/* <h4>Join our waiting list along with 40,000 startups</h4> */}
        {/* <hr />
        <p className='quote'>
          "Product tours allowed us to save hundreds of development hours
          compared to a custom-built solution."
        </p>
        <div className='quote__bio4'>
          <img src={Esther} alt='' className='quote__img' />
          <div className='quote__name'>
            <p>Esther Howard</p>
            <p className='quote__company'>Company Name</p>
          </div>
        </div> */}
      </div>
      <video
        className="mainImage4"
        src="https://res.cloudinary.com/dfcnic8wq/video/upload/v1612240191/FlowDash/Flowdash_A3_dkfkyu.mp4"
        autoPlay="autoplay"
        loop={true}
        muted
        poster="https://res.cloudinary.com/dfcnic8wq/image/upload/v1617168026/FlowDash/Flowdash_A3_1_xgf3dq.png"
      ></video>
    </div>
  );
}

export default Section1;
