import React from 'react';
import '../Styles/MultiLanguage.css';

import vectorLogo from '../Assets/rectangle_green.svg';

function MultiLanguage() {
  return (
    <div className='multi__language'>
      <img src={vectorLogo} alt='' className='multi__languageLogo' />
      <h1>
        <span className='multi__languageTitle'>Multi-language</span> support to
        reach all of your diverse customers.
      </h1>
      <h4 className='multi__languageSubTitle'>
        We'll automatically create multi-language onboarding support for your
        onboarding flow.
      </h4>
    </div>
  );
}

export default MultiLanguage;
