import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer style={{ gridTemplateColumns: "1fr" }}>
        <h3>&copy; 2021 FlowDash.ai</h3>
        <span
          style={{
            marginBottom: "30px",
            textAlign: "center",
          }}
        >
          Engineered with{" "}
          <i
            className="fas fa-heart"
            style={{
              color: "#ff506c",
            }}
          ></i>{" "}
          in Bangalore
        </span>
      </footer>
    );
  }
}

export default Footer;
