import React from "react";
import ValueLabelComponent from '../common/valueLabelComponent';
import Slider from '../common/prettoSlider';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from '@material-ui/icons/Help';

export default function (props) {
    return (<div
        style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
        }}
    >
        <div
            style={{
                flex: 1,
                padding: "40px 60px",
            }}
        >
            <span>
                <h4>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    >Audience % <Tooltip arrow title="The percentage of people this campaign will be shown to.">
                            <HelpIcon style={{
                                padding: "5px",
                                color: 'rgb(172 172 172)',
                                cursor: "help",
                            }} /></Tooltip>
                    </span>
                </h4>
                <Slider
                    ValueLabelComponent={(props) => <ValueLabelComponent suffix="%" {...props} />}
                    aria-label="custom thumb label"
                    defaultValue={props.currentCampaignDraft[props.currentStepIndex].percentageExposure}
                    min={0}
                    max={100}
                    onChange={(_, newValue) => props.handleAudienceChange(newValue)}
                />
            </span>
        </div>
    </div>)
}