import React from "react";
import Loader from "react-loader-spinner";

export function FlowdashLoader() {
    return <Loader
        type="TailSpin"
        color="#6ad7dd"
        style={{ textAlign: "center" }}
        height={"60vh"}
        width={60}
    />
}