import React, { Component } from "react";
import "../Styles/Footer.css";
import { Button, Input } from "@material-ui/core";
import person1 from "../Assets/person1.svg";
import person2 from "../Assets/person2.svg";
import person3 from "../Assets/person3.svg";
import person4 from "../Assets/person4.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { toast } from "react-toastify";
import { makeStyles, fade } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { CORE_API_BASE_URL } from "../../../constants/api";

toast.configure();

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(51, 129, 218, 0.25)",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: "red",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function RedditTextField(props) {
  const classes = useStylesReddit();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChangeEmail = (evt) => {
    console.log(evt.target.value);
    this.setState({
      email: evt.target.value,
    });
  };

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleSubmit = () => {
    const { email } = this.state;
    if (this.validateEmail(email)) {
      window.analytics.identify(email, {
        email: email,
        flowdash: true,
      });

      // todo - Add code to save email addresses here.
      const saveEmailUrl = CORE_API_BASE_URL + "signup?email=" + email;
      fetch(saveEmailUrl, {
        method: "POST",
      })
        .then((resp) => resp.json())
        .then(() => {
          toast.success(
            "Thanks for your interest! We'll get in touch with you soon."
          );
        })
        .catch(() => {
          toast.error(
            "Bummer. Seems to be an issue with the email. Please try again."
          );
        });
    } else {
      toast.error(
        "Bummer. Seems to be an issue with the email. Please try again."
      );
    }
  };

  render() {
    let { subTitle } = this.props;
    return (
      <div className="footer" name="CTA">
        <div className="footer__left">
          <div className="red__boxFooter">
            <div className="eye_iconFooter">
              <VisibilityIcon fontSize="large" />
            </div>
          </div>
          <h1>
            <span className="footer__ready">Ready</span> to fix your long term
            retention?
          </h1>
          <p>{subTitle}</p>
          <form>
            <RedditTextField
              id="standard-basic"
              variant="filled"
              label="Enter your work email"
              className="email__input"
              color="white"
              onChange={this.handleChangeEmail}
            />
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              className="footer__button"
            >
              Get early access
            </Button>
          </form>
        </div>
        <div className="footer__right">
          <h1>
            "Product Tours allowed us to save hundreds of development hours
            compared to a custom-built solution."
            <div className="footer__bio">
              <p>- You</p>
              <p className="footer__company">
                Your Awesome Company (when you get your hands on Flowdash.)
              </p>
              <img src={person3} alt="" className="person" />
              <img src={person1} alt="" className="person__big" />
              <img src={person2} alt="" className="person" />
              <img src={person4} alt="" className="person" />
            </div>
          </h1>
        </div>
      </div>
    );
  }
}

export default Footer;
