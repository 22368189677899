import React from 'react';
import '../Styles/MiniFooter.css';

function MiniFooter() {
    return (<div className='mini__footer' >
        <a href="/terms.html" className='flowdash'> Terms </a>
        <p className='flowdash'> &copy; flowdash.ai </p>
        <a href="/policy.html" className='flowdash'> Privacy policy </a> </div>
    );
}

export default MiniFooter;