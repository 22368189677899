import { Avatar, makeStyles, Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import { Text18, Card } from "./staticComponents";
import { GiLargePaintBrush } from "react-icons/gi";
import { BiCodeBlock } from "react-icons/bi";
const styles = {
  avatar: {
    height: "10rem",
    width: "10rem",
  },
  detailsBox: {
    height: "5rem",
    paddingLeft: "3.5rem",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginRight: "40px",
    width: "16.5rem",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#8C8C8C",
    padding: "13px 16px",
    border: "1px solid #E5E5E5",
    borderRadius: "4px",
  },
};

const profiles = [
  {
    label: "UI/UX Designer",
    icon: GiLargePaintBrush,
  },
  {
    label: "Developer",
    icon: BiCodeBlock,
  },
];

function Profile() {
  const [activeElement, setActiveElement] = useState([1, 0]);
  const handleSelect = (index) => {
    let temp = [0, 0];
    temp[index] = 1;
    setActiveElement(temp);
  };
  return (
    <Box style={{ padding: "31px" }}>
      <Text18>Details</Text18>
      <Box
        display="flex"
        style={{
          borderBottom: "1px solid #E5E5E5",
          paddingBottom: "1.5rem",
          marginBottom: "1.5rem",
        }}
      >
        <Avatar style={styles.avatar} />
        <Box style={styles.detailsBox}>
          <Box>
            <input style={styles.input} type="text" value={"Jatin"} />
            <input style={styles.input} type="text" value={"Vyas"} />
          </Box>
          <Box display="flex">
            <Box>
              <input
                style={{ ...styles.input, width: "38rem", marginTop: "1.5rem" }}
                value={"kkr1196@gmail.com"}
                disabled
              />
              <Button
                variant="outlined"
                style={{
                  color: "#FF9080",
                  border: "1px solid #FF9080",
                  textDecoration: "none",
                }}
              >
                Reset Password
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Text18>What do you do?</Text18>
      <Box display="flex">
        {profiles.map((profile, index) => (
          <Card
            label={profile.label}
            icon={profile.icon}
            onClick={() => handleSelect(index)}
            active={activeElement[index]}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Profile;
