import { Box, Button } from "@material-ui/core";
import brokenImage from "../../assets/broken-image.png";
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import GreenTick from "./../../assets/greenTick.png";
import { MEMBER_API, STRIPE_API_KEY, HOUSEWARE_NOTIFY_URL } from "../../constants/api";

export const Text18 = (props) => {
  return (
    <div
      style={{
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "23px",
        color: " #1C1F28",
        padding: "0 0 1rem 0",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export const Text24 = (props) => {
  return (
    <div
      style={{
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "31px",
        color: " #1C1F28",
        padding: "0 0 0.5rem 0",
      }}
    >
      {props.children}
    </div>
  );
};
export const Text12 = (props) => {
  return (
    <div
      style={{
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#1C1F28",
        padding: "1rem",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export const Text48 = (props) => {
  let color = "#1C1F28";
  if (props.color) {
    color = props.color;
  }
  return (
    <div
      style={{
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "48px",
        lineHeight: "63px",
        textAlign: "left",
        color: color,
        padding: "1rem",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export const Text14 = (props) => {
  return (
    <div
      style={{
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#8C8C8C",
        paddingBottom: "2rem",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export const Card = (props) => {
  const styles = {
    root: {
      height: "7rem",
      width: "7rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid",
      borderColor: props.active ? "#578FFF" : "#8c8c8c",
      borderRadius: "4px",
      margin: "1rem",
    },
    icon: {
      height: "2rem",
      width: "2rem",
      color: props.active ? "#578FFF" : "#8c8c8c",
    },
  };
  return (
    <Box style={{ cursor: "pointer" }} onClick={props.onClick}>
      <Box style={styles.root}>{<props.icon style={styles.icon} />}</Box>
      <Text12>{props.label}</Text12>
    </Box>
  );
};

export const SubscriptionCard = (props) => {
  let color = "#578FFF";
  const stripePromise = loadStripe(STRIPE_API_KEY);
  const handleClick = async (evt, annual) => {
    if (annual) {
      let message = `*User ${props.location?.user?.email} clicked on Subscribe for Annual Plan*`
      fetch(HOUSEWARE_NOTIFY_URL, {
        mode: "cors",
        method: "POST",
        headers: {
          "content-Type": "application/json",
          "x-api-key": props.location?.appOwner?.apiKey,
        },
        body: JSON.stringify({
          text: message
        }),
      })
        .then((r) => console.log(r)).catch(e => console.log(e));
    }
    
    const url = `${MEMBER_API}create-checkout-session${annual ? "?annual=true" : ""
      }`;
    console.log(url);
    const stripe = await stripePromise;
    const response = await fetch(url, {
      method: "POST",
    });
    const session = await response.json();
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };
  const styles = {
    p: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "left",
      color: "#1C1F28",
      paddingLeft: "8px",
      margin: "0",
    },
    button: {
      background: color,
      textTransform: "none",
      color: "#fff",
      width: "80%",
    },
  };
  return (
    <Box
      display="flex"
      width="25%"
      flexDirection="column"
      minHeight="380px"
      justifyContent="flex-start"
      style={{
        border: `1px solid ${color}`,
        borderRadius: "6px",
        padding: "1.4rem",
      }}
    >
      <Text24>Early access</Text24>
      <Text14 style={{ paddingBottom: "1rem" }}>Basic</Text14>
      {props.variant === "monthly" && (
        <Text48
          color={color}
          style={{ paddingLeft: 0 }}
        >{`$${props.price}`}</Text48>
      )}
      {props.variant === "annually" && (
        <Box display="flex" alignItems="center">
          <Text18
            style={{ padding: "0 1rem 0 0", textDecoration: "line-through" }}
          >
            {`$${props.price * 12}`}
          </Text18>
          <Text48 color={color} style={{ paddingLeft: 0 }}>{`$${props.price * 12 * 0.8
            }`}</Text48>
        </Box>
      )}
      <p
        style={{
          color: "#1C1F28",
          fontWeight: "bold",
          fontSize: "14px",
          lineHeight: "18px",
          paddingLeft: 0,
        }}
      >
        Features
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          src={GreenTick}
          style={{
            height: "8px",
            width: "8px",
            float: "left",
          }}
        />
        <p style={styles.p}>All the features till we launch</p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          src={GreenTick}
          style={{
            height: "8px",
            width: "8px",
            float: "left",
          }}
        />
        <p style={styles.p}>Unimited MAU</p>
      </div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="85%"
        style={{ position: "absolute", bottom: "26px" }}
      >
        <Button
          variant="contained"
          style={{ ...styles.button }}
          onClick={(event) =>
            handleClick(event, props.variant === "annually" ? true : false)
          }
        >
          Subscribe
        </Button>
      </Box>
    </Box>
  );
};

export const BrokenImage = () => {
  return (
    <div
      style={{
        height: "30px",
        width: "30px",
        borderRadius: "50%",
        border: "1px solid #1c1f28",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={brokenImage}
        style={{
          height: "15px",
          width: "15px",
        }}
      />
    </div>
  );
};
