import { Box, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { SubscriptionCard, Text14, Text18 } from "./staticComponents";
import { useLocation } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "31px",
    boxSizing: "border-box",
    width: "100%",
  },
  timePeriod: {
    borderBottom: "1px solid #e5e5e5",
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    padding: "0",
  },
  navlink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontStyle: "DM Sans",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    padding: "0 1rem 1.8rem 1rem",
    margin: "0 2rem",
    color: "#1c1f28",
    cursor: "pointer",
  },
}));
function Subscriptions() {
  const classes = useStyles();
  let location = useLocation();
  const [active, setActive] = useState("monthly");
  
  return (
    <Box className={classes.root}>
      <Text18 style={{ paddingBottom: "0" }}>Subscription Plans</Text18>
      <Text14>
        Save 20% on our annual plan.
      </Text14>
      <Box className={classes.timePeriod}>
        <p
          className={classes.navlink}
          style={
            active === "monthly"
              ? {
                  color: "#578FFF",
                  borderBottom: "2px solid #578FFF",
                }
              : {}
          }
          onClick={() => setActive("monthly")}
        >
          Monthly
        </p>
        <p
          className={classes.navlink}
          style={
            active === "annually"
              ? {
                  color: "#578FFF",
                  borderBottom: "2px solid #578FFF",
                }
              : {}
          }
          onClick={() => setActive("annually")}
        >
          Annually (20% Savings)
        </p>
      </Box>
      <Box style={{ padding: "31px" }} display="flex" justifyContent="center">
        <SubscriptionCard type={false} variant={active} price={19} location={location}/>
      </Box>
    </Box>
  );
}

export default Subscriptions;
