import React, { useState, Fragment } from "react";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-scroll";
import { Link as RouterLink, withRouter } from "react-router-dom";
import UserMenu from "./common/user-menu";

const useStyles = makeStyles((theme) => ({
  userIcon: {
    fontSize: "2.1em",
    color: "#d6ba76",
  },
}));

function SideNav(props) {
  const classes = useStyles();
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  return (
    <Fragment>
      <div id="mySidenav" className="sidenav">
        <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
          &times;
        </a>
        {!props.arena && <div>&nbsp;</div>}
        {!props.arena && <div>&nbsp;</div>}

        {!props.arena && (
          <>
            {" "}
            <a
              href="https://docs.flowdash.ai/react-native"
              to="discover"
              smooth={true}
              spy={true}
              className="docs"
              style={{
                color: "rgb(146, 146, 146)",
              }}
              activeClass="active"
              offset={-70}
              duration={500}
            >
              Docs
            </a>
            <a
              href="mailto:team@flowdash.ai?subject=I'm interested in FlowDash!"
              to="discover"
              smooth={true}
              spy={true}
              className="sign-up"
              activeClass="active"
              offset={-70}
              duration={500}
            >
              Contact us
            </a>
          </>
        )}
      </div>
    </Fragment>
  );
}

export default SideNav;
