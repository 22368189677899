import React from "react";
import { INDEPENDANT_VIEW } from "../../constants/web";
import { isBackgroundDark } from "../../constants/utils";
export default function PreviewTooltip(props) {
  let tooltipStyle, caretStyle;
  let { viewIndex } = props;
  let isDark = isBackgroundDark(props.styleTooltip?.backgroundColor);
  switch (props.direction) {
    case 0:
      tooltipStyle = {
        flexDirection: "column",
      };
      caretStyle = {
        width: 0,
        height: 0,
        borderTopColor: props.styleTooltip
          ? props.styleTooltip.backgroundColor
          : "#ffffff",
        borderTopWidth: 10,
        borderBottomColor: "transparent",
        borderBottomWidth: 15,
        borderRightColor: "transparent",
        borderRightWidth: 15,
        borderLeftColor: "transparent",
        borderLeftWidth: 15,
        marginLeft: 15,
      };
      break;
    case 1:
      tooltipStyle = {
        flexDirection: "row-reverse",
      };
      caretStyle = {
        width: 0,
        height: 0,
        borderTopColor: "transparent",
        borderTopWidth: 15,
        borderBottomColor: "transparent",
        borderBottomWidth: 15,
        borderRightColor: props.styleTooltip
          ? props.styleTooltip.backgroundColor
          : "#ffffff",
        borderRightWidth: 10,
        borderLeftColor: "transparent",
        borderLeftWidth: 15,
        marginTop: 15,
      };
      break;
    case 2:
      tooltipStyle = {
        flexDirection: "column-reverse",
      };
      caretStyle = {
        width: 0,
        height: 0,
        borderTopColor: "transparent",
        borderTopWidth: 15,
        borderBottomColor: props.styleTooltip
          ? props.styleTooltip.backgroundColor
          : "#ffffff",
        borderBottomWidth: 10,
        borderRightColor: "transparent",
        borderRightWidth: 15,
        borderLeftColor: "transparent",
        borderLeftWidth: 15,
        marginLeft: 15,
      };
      break;
    case 3:
      tooltipStyle = {
        flexDirection: "row",
      };
      caretStyle = {
        width: 0,
        height: 0,
        borderTopColor: "transparent",
        borderTopWidth: 15,
        borderBottomColor: "transparent",
        borderBottomWidth: 15,
        borderRightColor: "transparent",
        borderRightWidth: 15,
        borderLeftColor: props.styleTooltip
          ? props.styleTooltip.backgroundColor
          : "#ffffff",
        borderLeftWidth: 10,
        marginTop: 15,
      };
      break;
    default:
      break;
  }
  let independantViewStyle =
    viewIndex == INDEPENDANT_VIEW
      ? {
          minWidth: 380,
          minHeight: props.image ? 350 : 250,
        }
      : {
          minWidth: 280,
          minHeight: 180,
        };
  let footerColor = "";
  return (
    <div
      style={{
        // position: "absolute",
        zIndex: 2,
        ...tooltipStyle,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          ...independantViewStyle,
          backgroundColor: "white",
          padding: "15px 20px",
          borderRadius: 12,
          ...props.styleTooltip,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          boxShadow: "rgb(0 0 0 / 20%) 0px 0px 50px 0px",
          alignItems: viewIndex == INDEPENDANT_VIEW ? "center" : "initial",
          maxWidth: 320,
          overflowWrap: "break-word",
        }}
      >
        {viewIndex == INDEPENDANT_VIEW && (
          <>
            {props.image && <img style={{ width: "33%" }} src={props.image} />}
            <span
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <svg
                fill="#969696"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
            </span>
          </>
        )}
        <span
          style={{
            ...props.stylePrimaryText,
            fontWeight: "bold",
            marginTop: 16,
            width: "100%",
          }}
        >
          {props.primaryText}
        </span>
        <span
          style={{
            ...props.styleSecondaryText,
            marginTop: 20,
            marginBottom: 20,
            width: "100%",
          }}
        >
          {props.secondaryText}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {viewIndex != INDEPENDANT_VIEW && (
            <span
              style={{
                ...props.styleSkipText,
                flex: 1,
                fontWeight: "bold",
                position: "relative",
              }}
            >
              {props.skipText}
            </span>
          )}
          <div
            style={{
              ...props.styleActionView,
              flex: viewIndex != INDEPENDANT_VIEW ? 0.75 : 1,
              maxWidth: 250,
              width: "auto",
            }}
          >
            <span
              style={{
                ...props.styleActionText,
                textAlign: "center",
                margin: "0px 20px",
              }}
            >
              {props.actionText}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            color: isDark ? "rgba(40, 30, 65,0.62)" : "#ffffff9e",
            justifyContent:
              viewIndex == INDEPENDANT_VIEW ? "center" : "flex-end",
            alignItems: "center",
            marginRight: viewIndex == INDEPENDANT_VIEW ? 0 : 15,
            marginBottom: viewIndex == INDEPENDANT_VIEW ? -20 : 0,
            marginLeft: viewIndex == INDEPENDANT_VIEW ? 24 : 0,
            marginTop: viewIndex == INDEPENDANT_VIEW ? 0 : 20,
          }}
        >
          <span
            style={{
              fontFamily: "DM Sans",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "13px",
              letterSpacing: "0em",
              paddingRight: "0.5rem",
            }}
          >
            Powered By{" "}
          </span>
          <span
            style={{
              fontFamily: "DM Sans",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "13px",
              paddingRight: "0.5rem",
            }}
          >
            Flowdash
          </span>
          <span>
            <img
              src={
                isDark
                  ? "https://res.cloudinary.com/dfcnic8wq/image/upload/v1615900711/FlowDash/logo-light_dv8bfy.png"
                  : "https://res.cloudinary.com/dfcnic8wq/image/upload/v1615900708/FlowDash/logo-dark_qpp1ja.png"
              }
              style={{ width: "13px" }}
            />
          </span>
        </div>
      </div>
      {viewIndex != INDEPENDANT_VIEW && (
        <div style={{ ...caretStyle, borderStyle: "solid" }}>
          <span></span>
        </div>
      )}
    </div>
  );
}
