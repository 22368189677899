import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { editCurrentCampaignDraft, saveCampaign } from "../../actions/campaigns";
import ContentEditor from "./content-editor";
import StyleEditor from "./style-editor";
import AudienceEditor from "./audience-editor";
import ImagePicker from "../common/image-picker";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
const imageList = ["https://res.cloudinary.com/dfcnic8wq/image/upload/v1605598107/FlowDash/Tooltip_2_sfd0e2.png", "https://res.cloudinary.com/dfcnic8wq/image/upload/v1605597724/FlowDash/Tooltip_1_erapxz.png"

  // TODO - Uncomment for hotspots
  // ,"https://res.cloudinary.com/dfcnic8wq/image/upload/v1605598835/FlowDash/Tooltip_3_py3w05.png"
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    textAlign: "left",
    padding: "10px",
  },
  icon: {
    borderRadius: 2,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#418ef1",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#418ef1",
    },
  },
  heading: {
    fontWeight: 700,
  }
});

const useParentStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
});

const EditCampaignTab = function CenteredTabs(props) {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const [value, setValue] = React.useState("one");
  const [template, setTemplate] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStateChange = (event) => {
    props.editCurrentCampaignDraft({
      [event.target.name]: event.target.checked,
    });
  };

  const handleAudienceChange = (value) => {
    props.editCurrentCampaignDraft({
      ["percentageExposure"]: value,
    });
  };

  const handleTooltipDirectionChange = () => {
    props.editCurrentCampaignDraft({
      ["tooltipDirection"]: 1,
    });
  };

  const togglePositionControls = (source, property, value) => {
    props.editCurrentCampaignDraft({
      [source]: { [property]: value },
    });
  };

  const handleRawStyleChange = (value) => {
    props.editCurrentCampaignDraft({ ["code"]: value });
  };

  const handleStyleChange = (source, property, value, template) => {
    if (template == "tooltip") {
      props.editCurrentCampaignDraft({
        tooltipStyle: { [source]: { [property]: value } },
      });
    }
    else if (template == "hotspot") {
      props.editCurrentCampaignDraft({
        hotspotStyle: { [source]: { [property]: value } },
      });
    }
    else {
      props.editCurrentCampaignDraft({
        style: { [source]: { [property]: value } },
      });
    }

  };

  const handleTextChange = (name, event) => {
    props.editCurrentCampaignDraft({ [name]: event.target.value });
  };

  let { currentCampaignDraft, currentStepIndex, currentScreenIndex, type, campaign } = props;
  let { campaignId } = props.match.params;
  console.log("This", campaign.details[currentScreenIndex].defaultType)
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "50px",
        }}
      >
        <TextField
          label="Name of your campaign"
          onChange={(event) => {
            handleTextChange("name", event);
          }}
          value={currentCampaignDraft[currentStepIndex].name}
          helperText="Your campaign name eg: First user experience at ACME"
          size="small"
          style={{
            margin: "40px 20px",
          }}
        />
        <h4 style={{
          fontWeight: 'normal',
          textTransform: 'uppercase',
          marginLeft: 20,
          color: 'rgb(128 128 128)'
        }}>Template</h4>
        <ImagePicker
          disabled={!campaign.details[currentScreenIndex].defaultType}
          images={imageList.map((image, i) => ({ src: image, value: i }))}
          onPick={(evt) => {
            props.editCurrentCampaignDraft({
              ["type"]: evt.value,
            });
          }}
          selected={{ src: imageList[type], value: type }}
        />

        {campaign.details[currentScreenIndex].defaultType &&
          <Tooltip title={"The template once chosen cannot be changed."}>
            <a
              className="footer-contact"
              style={{
                color: "white",
                margin: "10px",
                maxWidth: "135px"
              }}
              onClick={() => {
                props.saveCampaign(
                  campaignId,
                  currentScreenIndex,
                  currentStepIndex,
                  currentCampaignDraft
                );
              }}
            >
              Confirm template
            </a>
          </Tooltip>}
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        classes={parentClasses.root}
      >
        <Tab label="Content" value="one" {...a11yProps("one")} />
        <Tab label="Styling" value="two" {...a11yProps("two")} />
        <Tab label="Audience" value="three" {...a11yProps("three")} />
      </Tabs>
      <TabPanel value={value} index="one">
        <ContentEditor
          currentCampaignDraft={currentCampaignDraft}
          currentStepIndex={currentStepIndex}
          handleStateChange={handleStateChange}
          handleTextChange={handleTextChange}
          currentScreenIndex={currentScreenIndex}
          handleTooltipDirectionChange={handleTooltipDirectionChange}
          classes={classes}
          template={type}
        />
      </TabPanel>
      <TabPanel value={value} index="two">
        <StyleEditor
          currentCampaignDraft={currentCampaignDraft}
          currentStepIndex={currentStepIndex}
          handleStateChange={handleStateChange}
          togglePositionControls={togglePositionControls}
          handleStyleChange={handleStyleChange}
          handleRawStyleChange={handleRawStyleChange}
          handleTooltipDirectionChange={handleTooltipDirectionChange}
          classes={classes}
          template={type}
        />
      </TabPanel>
      <TabPanel value={value} index="three">
        <AudienceEditor
          currentCampaignDraft={currentCampaignDraft}
          currentStepIndex={currentStepIndex}
          handleAudienceChange={handleAudienceChange}
        />
      </TabPanel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaigns.currentCampaign,
  currentStepIndex: state.campaigns.currentStepIndex,
  currentCampaignDraft: state.campaigns.currentCampaignDraft,
  currentScreenIndex: state.campaigns.currentScreenIndex,
  type: state.campaigns.currentCampaignDraft[state.campaigns.currentStepIndex].type,
  isDirty: state.campaigns.currentCampignIsDirty,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editCurrentCampaignDraft,
      saveCampaign,

    },
    dispatch
  );
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCampaignTab));
