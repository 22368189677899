import React from "react";
import "../Styles/Section1.css";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import { Button } from "@material-ui/core";

function Section1() {
  return (
    <div className="section1">
      <div className="main-video">
        <video
          className="video"
          src="https://res.cloudinary.com/dfcnic8wq/video/upload/v1613541534/FlowDash/Flowdash_A1_fmonha.mp4"
          autoPlay="autoplay"
          loop={true}
          muted
          poster="https://res.cloudinary.com/dfcnic8wq/image/upload/v1617168026/FlowDash/Flowdash_A1_1_uahx5u.png"
        ></video>
      </div>
      <div className="section1__Right">
        <div className="blue__box">
          <div className="arrows">
            <SettingsEthernetIcon fontSize="large" />
          </div>
        </div>
        <h1>
          Create beautiful onboarding flows in your app.
          <br />
          <span className="code__blue"> Code-free.</span>
        </h1>
        {/* <h4>Join our waiting list along with 40,000 startups</h4> */}
        {/* <Button variant='contained' className='section1__button'>
          Join Now
        </Button> */}
      </div>
    </div>
  );
}

export default Section1;
