import React from "react";

export default function PreviewText(props) {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 2,
        ...props.style,
      }}
    >
      <span>{props.text}</span>
    </div>
  );
}
