import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { editCurrentCampaignDraftWeb, saveCampaign } from "../../actions/campaigns";
import ContentEditor from "./content-editor";
import StyleEditor from "./style-editor";
import CampaignEditor from "./campaign-editor/index";
import AudienceEditor from "./audience-editor";
import ImagePicker from "../common/image-picker";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
const imageList = ["https://res.cloudinary.com/dfcnic8wq/image/upload/v1605598107/FlowDash/Tooltip_2_sfd0e2.png", "https://res.cloudinary.com/dfcnic8wq/image/upload/v1605597724/FlowDash/Tooltip_1_erapxz.png"

  // TODO - Uncomment for hotspots
  // ,"https://res.cloudinary.com/dfcnic8wq/image/upload/v1605598835/FlowDash/Tooltip_3_py3w05.png"
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    textAlign: "left",
    padding: "40px",
    color: '#1C1F28',
  },
  icon: {
    borderRadius: 2,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#418ef1",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#418ef1",
    },
  },
  heading: {
    fontWeight: 900,
    fontFamily: 'DM Sans',
    color: '#1C1F28'
  },
  subHeading: {
    fontFamily: 'DM Sans',
    color: '#1C1F28',
    fontSize: 14
  }
});

const useParentStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
  selected: {
    color: '#578FFF !important',
    indicator: {
      backgroundColor: '#578FFF !important',
    }
  },

});

const EditCampaignTab = function CenteredTabs(props) {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const [value, setValue] = React.useState("one");
  const [template, setTemplate] = React.useState();

  const [expanded, setExpanded] = React.useState("panel0");

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStateChange = (event) => {
    props.editCurrentCampaignDraftWeb({
      [event.target.name]: event.target.checked,
    });
  };

  const handleAudienceChange = (value) => {
    props.editCurrentCampaignDraftWeb({
      ["percentageExposure"]: value,
    });
  };

  const handleUserRuleChange = (value) => {
    props.editCurrentCampaignDraftWeb({
      ["userRules"]: value
    });
  };

  const handleTooltipDirectionChange = () => {
    props.editCurrentCampaignDraftWeb({
      ["tooltipDirection"]: 1,
    });
  };

  const togglePositionControls = (source, property, value) => {
    props.editCurrentCampaignDraftWeb({
      [source]: { [property]: value },
    });
  };

  const handleRawStyleChange = (value) => {
    props.editCurrentCampaignDraftWeb({ ["code"]: value });
  };

  const handleStyleChange = (source, property, value, template) => {
    if (template == "tooltip") {
      props.editCurrentCampaignDraftWeb({
        tooltipStyle: { [source]: { [property]: value } },
      });
    }
    else if (template == "hotspot") {
      props.editCurrentCampaignDraftWeb({
        hotspotStyle: { [source]: { [property]: value } },
      });
    }
    else {
      props.editCurrentCampaignDraftWeb({
        style: { [source]: { [property]: value } },
      });
    }

  };

  const handleTextChange = (name, event) => {
    props.editCurrentCampaignDraftWeb({ [name]: event.target.value });
  };

  let { currentCampaignDraft,
    currentStepIndex, currentScreenIndex, type, campaign, editCurrentCampaignDraftWeb,
    isIntroView, percentageExposure, userRules } = props;
  let { campaignId } = props.match.params;
  console.log("This", campaign.details[currentScreenIndex].defaultType)
  return (
    <div className={classes.root}>
      <Accordion
        elevation={0}
        expanded={expanded === "panel0"}
        onChange={handleAccordianChange("panel0")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Content</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              width: "100%",
            }}
          >
            <CampaignEditor
              currentCampaignDraft={currentCampaignDraft}
              currentStepIndex={currentStepIndex}
              handleStateChange={handleStateChange}
              handleTextChange={handleTextChange}
              currentScreenIndex={currentScreenIndex}
              handleTooltipDirectionChange={handleTooltipDirectionChange}
              template={type}
              isIntroView={isIntroView}
              togglePositionControls={togglePositionControls}
              handleStyleChange={handleStyleChange}
              handleRawStyleChange={handleRawStyleChange}
              editCurrentCampaignDraftWeb={editCurrentCampaignDraftWeb}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={expanded === "panel1"}
        onChange={handleAccordianChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Audience</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              width: "100%",
            }}
          >
            <AudienceEditor
              currentCampaignDraft={currentCampaignDraft}
              currentStepIndex={currentStepIndex}
              handleAudienceChange={handleAudienceChange}
              handleUserRuleChange={handleUserRuleChange}
              percentageExposure={percentageExposure}
              userRules={userRules}
            />

          </div>
        </AccordionDetails>
      </Accordion>
    </div >
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaigns.currentCampaign,
  currentStepIndex: state.campaigns.currentStepIndex,
  currentCampaignDraft: state.campaigns.currentCampaignDraft,
  currentScreenIndex: state.campaigns.currentScreenIndex,
  type: state.campaigns.currentCampaignDraft[state.campaigns.currentStepIndex].type,
  isDirty: state.campaigns.currentCampignIsDirty,
  percentageExposure: state.campaigns.percentageExposure,
  userRules: state.campaigns.userRules,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editCurrentCampaignDraftWeb,
      saveCampaign,

    },
    dispatch
  );
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCampaignTab));
