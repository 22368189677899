import { Button, Card } from "@material-ui/core";
import React from "react";
import { Text12, Text14, Text18 } from "../settings/staticComponents";
import welcomeImage from "../../assets/welcome.png";
function Welcomescreen() {
  return (
    <ul
      style={{
        background: "rgba(214, 214, 215, 0.02)",
        padding: "0px 15px 30px 50px",
      }}
    >
      <li>
        <Card
          style={{
            width: "100%",
            transition: "all 0.200s ease-in-out",
            border: "1px solid rgb(229, 229, 229)",
            boxShadow: "none",
            height: "72vh",
            maxHeight: "750px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Text18
            style={{
              color: "#281e41",
              fontWeight: 700,
              paddingBottom: "2rem",
            }}
          >
            Create Your First Campaign
          </Text18>
          <img
            src={welcomeImage}
            style={{
              height: "250px",
              width: "275px",
              paddingBottom: "2.5rem",
            }}
          />
          <Text12 style={{ color: "#281e41", paddingBottom: "3rem" }}>
            Start creating product tours now!
          </Text12>
          <Button
            style={{ backgroundColor: "#281e41", color: "white" }}
            variant="contained"
            target="_blank"
            href="https://chrome.google.com/webstore/detail/flowdash-builder/jkbncdocenalfamaankglendfnkiollm"
          >
            Get Started!
          </Button>
        </Card>
      </li>
    </ul>
  );
}

export default Welcomescreen;
