import styled from "styled-components";
const blackColor = "#1c1f28";
export const ColorCard = styled.div`
  position: absolute;
  right: 80px;
  top:-10px;
  z-index: 100;
  width: 180px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  font-size: 13px;
  font-weight: normal;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

export const ColorCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColorCardheading = styled.div`
  color: ${blackColor};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

export const CloseButton = styled.div`
  height: 18px;
  width: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${blackColor};
`;

export const Swatch = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  background: ${({ bgColor }) => bgColor};
  boxsizing: "border-box";
  border-radius: 4px;
  border:1px solid rgb(229, 229, 229);
  cursor:pointer;
`;

export const Caret = styled.div`
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
  position: absolute;
  display: inline-block;
  z-index: 100;
  right: 75px;
  top:0px;
  height: 1px;
  margin-top: 10px;
`;