import React from 'react';
import '../Styles/Section2.css';
import Esther from '../Assets/person1.svg';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

function Section1() {
  return (
    <div className='section2'>
      <div className='section2__Right'>
        <div className='green__box'>
          <div className='person__icon'>
            <PersonOutlineIcon fontSize='large' />
          </div>
        </div>
        <h1>
          <span className='code__green'>Personalise</span> on user traits,
          events or segments.
        </h1>
        <h4>Join our waiting list along with 40,000 startups</h4>
        <hr />
        <p className='quote'>
          "Product tours allowed us to save hundreds of development hours
          compared to a custom-built solution."
        </p>
        <div className='quote__bio2'>
          <img src={Esther} alt='' className='quote__img' />
          <div className='quote__name'>
            <p>Esther Howard</p>
            <p className='quote__company'>Company Name</p>
          </div>
        </div>
      </div>
      <img
        src='https://res.cloudinary.com/dfcnic8wq/image/upload/v1592725917/FlowDash/Illustration_2_vrpvrc.png'
        className='mainImage2'
        alt=''
      />
    </div>
  );
}

export default Section1;
