import React from "react";
import CodeBlock from "react-copy-code";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useLocation } from "react-router-dom"
import { HOUSEWARE_NOTIFY_URL } from "../../constants/api";

function Snippets(props) {
  let location = useLocation();
  let guid = location && location.appOwner ? location.appOwner.guid : (props.appOwner && props.appOwner.guid ? props.appOwner.guid : "<YOUR_APP_ID>");
  let key = location && location.appOwner ? location.appOwner.apiKey : (props.appOwner && props.appOwner.apiKey ? props.appOwner.apiKey : "<YOUR_APP_KEY>");
  return (
    <div>
      <p
        style={{
          background: "white",
          textAlign: "left",
          paddingTop: "20px",
          color: "rgb(87, 143, 255)",
          margin: "20px",
          fontSize: "inherit",
        }}
      >
        <CodeBlock
          onCopy={()=> {
            let message = `*User ${location.user?.email} copied integration snippet*`
            fetch(HOUSEWARE_NOTIFY_URL, {
              mode: "cors",
              method: "POST",
              headers: {
                "content-Type": "application/json",
                "x-api-key": location.appOwner?.apiKey,
              },
              body: JSON.stringify({
                text: message
              }),
            })
              .then((r) => console.log(r)).catch(e => console.log(e));
          }}
          element={"span"}
          highlight={true}
          svg={() => (
            <FileCopyIcon
              style={{
                color: "rgb(87, 143, 255)",
                cursor: "pointer",
                background: "none",
                padding: 5,
                margin: 5,
              }}
            />
          )}
        >
          <pre>
            <code
              style={{
                display: "block",
                whiteSpace: "pre-wrap",
              }}
            >
              {`<script type="text/javascript">
    var s = document.createElement('script')
    s.src = 'https://js.flowdash.ai/'
    s.async = true;
    document.head.appendChild(s);
    s.onload = function () {
      flowdash.init('${guid}','${key}');
    }
</script>`}
            </code>
          </pre>
        </CodeBlock>
      </p>
    </div>
  );
}

export default Snippets;
