import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ColorPicker from "./ColorPicker";
import { Swatch } from "./StaticComponents";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
  AiOutlineAlignRight,
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineUnderline,
} from "react-icons/ai";

function ContentElement({
  title,
  propText,
  handleTextChange,
  propFontSize,
  handleFontChange,
  propColor,
  handleColorChange,
  handleBgColorChange,
  propBgColor,
  handleAlignmentChange,
  propTextAlign,
  propFontWeight,
  propFontStyle,
  propTextDecoration,
  handleFontStyleChange,
  handleFontDecorationChange,
  handleFontWeightChange,
}) {
  const [color, setColor] = useState(propColor);
  const [bgcolor, setBgColor] = useState(propBgColor);
  const [text, setText] = useState(propText);
  const [fontStyle, setFontStyle] = useState(propFontStyle || "normal");
  const [fontSize, setFontSize] = useState(propFontSize);
  const [fontType, setFontType] = useState("DM Sans");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showBgPicker, setBgPicker] = useState(false);
  const [textAlign, setTextAlign] = useState(propTextAlign || "center");
  const [fontWeight, setFontWeight] = useState(propFontWeight || 400);
  const [textDecoration, setTextDecoration] = useState(
    propTextDecoration || "none"
  );
  const sizes = [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32];

  useEffect(() => {
    setFontSize(propFontSize);
    setText(propText);
    setColor(propColor);
    setBgColor(propBgColor);
  }, [propFontSize, propText, propColor, propBgColor]);
  console.log("DEBUG", handleBgColorChange);
  const styles = {
    input: {
      height: "2.5rem",
      flex: 0.85,
      boxSizing: "border-box",
      borderRadius: "4px",
      border: "2px solid #e5e5e5",
      paddingLeft: "10px",
    },
    select: {
      lineHeight: "16px",
      color: "#578FFF",
      marginleft: "2rem",
      border: "none",
    },
    seperator: {
      width: "80%",
      color: "#E5E5E5",
      border: "none",
      height: 1,
      backgroundColor: "#E5E5E5",
    },
  };

  const onColorChange = (color) => {
    setColor(color);
    handleColorChange(color);
  };

  const onBgColorChange = (color) => {
    setBgColor(color);
    handleBgColorChange(color);
  };

  const handleBgClose = () => {
    setBgPicker(!showBgPicker);
  };

  const handleClose = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleClickAway = () => {
    setShowColorPicker(false);
  };

  const handleClickBgAway = () => {
    setBgPicker(false);
  };

  return (
    <div
      style={{
        width: "100%",
        margin: "0.45rem 0",
        height: "150px",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#1C1F28",
      }}
    >
      {title}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          position: "relative",
        }}
      >
        <input
          style={styles.input}
          value={text}
          onChange={(e) => {
            setShowColorPicker(false);
            setText(e.target.value);
            handleTextChange(e);
          }}
        />
        <ClickAwayListener onClickAway={handleClickAway}>
          <div style={{ flex: 0.1 }}>
            <Swatch
              onClick={() => setShowColorPicker(!showColorPicker)}
              bgColor={color?.hex || color}
            />
            {showColorPicker && (
              <ColorPicker
                color={color}
                onChange={onColorChange}
                handleClose={handleClose}
              />
            )}
          </div>
        </ClickAwayListener>
      </div>
      <div
        style={{
          color: "#8C8C8C",
          marginTop: "20px",
          display: "flex",
          marginBottom: "20px",
          width: "84%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ paddingTop: "4px" }}>
          <label style={{ marginRight: "1rem" }} for="fontSize">
            Font Size:{" "}
          </label>

          <select
            style={styles.select}
            onChange={(evt) => {
              setFontSize(evt.target.value);
              handleFontChange(evt);
            }}
            value={fontSize}
            name="fontSize"
            id="fontSize"
          >
            {sizes.map((size) => (
              <option
                selected={{ fontSize } == size ? true : false}
                value={size}
              >
                {size}
              </option>
            ))}
          </select>
        </div>
        {propTextAlign === false ? null : (
          <div
            style={{
              border: "1px solid rgb(229 229 229)",
              borderRadius: "4px",
              paddingTop: "4px",
            }}
          >
            <AiOutlineAlignLeft
              style={{
                color: textAlign === "left" ? "#578FFF" : "#8c8c8c",
                height: "16px",
                width: "16px",
                margin: "0 6px 0 6px",
                cursor: "pointer",
              }}
              onClick={(event) => {
                setTextAlign("left");
                handleAlignmentChange("left");
              }}
            />
            <AiOutlineAlignCenter
              style={{
                color: textAlign === "center" ? "#578FFF" : "#8c8c8c",
                height: "16px",
                width: "16px",
                margin: "0 6px",
                cursor: "pointer",
              }}
              onClick={(event) => {
                setTextAlign("center");
                handleAlignmentChange("center");
              }}
            />
            <AiOutlineAlignRight
              style={{
                color: textAlign === "right" ? "#578FFF" : "#8c8c8c",
                height: "16px",
                width: "16px",
                margin: "0 6px",
                cursor: "pointer",
              }}
              onClick={(event) => {
                setTextAlign("right");
                handleAlignmentChange("right");
              }}
            />
          </div>
        )}
        <div
          style={{
            border: "1px solid rgb(229 229 229)",
            borderRadius: "4px",
            paddingTop: "4px",
          }}
        >
          <AiOutlineBold
            style={{
              color: fontWeight === "bold" ? "#578FFF" : "#8c8c8c",
              height: "16px",
              width: "16px",
              margin: "0 6px",
              cursor: "pointer",
            }}
            onClick={() => {
              setFontWeight(fontWeight === "bold" ? "normal" : "bold");
              handleFontWeightChange(fontWeight === "bold" ? "normal" : "bold");
            }}
          />
          <AiOutlineItalic
            style={{
              color: fontStyle === "italic" ? "#578FFF" : "#8c8c8c",
              height: "16px",
              width: "16px",
              margin: "0 6px",
              cursor: "pointer",
            }}
            onClick={() => {
              setFontStyle(fontStyle === "italic" ? "normal" : "italic");
              handleFontStyleChange(
                fontStyle === "italic" ? "normal" : "italic"
              );
            }}
          />
          <AiOutlineUnderline
            style={{
              color: textDecoration === "underline" ? "#578FFF" : "#8c8c8c",
              height: "16px",
              width: "16px",
              margin: "0 6px",
              cursor: "pointer",
            }}
            onClick={() => {
              setTextDecoration(
                textDecoration === "underline" ? "none" : "underline"
              );
              handleFontDecorationChange(
                textDecoration === "underline" ? "none" : "underline"
              );
            }}
          />
        </div>
      </div>

      {propBgColor && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            marginBottom: "10px",
            position: "relative",
          }}
        >
          <label style={{ marginRight: "1rem", color: "#8C8C8C", flex: 0.85 }}>
            Background color:
          </label>
          <ClickAwayListener onClickAway={handleClickBgAway}>
            <div style={{ flex: 0.1 }}>
              <Swatch
                onClick={() => {
                  setBgPicker(!showBgPicker);
                }}
                bgColor={bgcolor?.hex || bgcolor}
              />
              {showBgPicker && (
                <ColorPicker
                  color={bgcolor}
                  onChange={onBgColorChange}
                  handleClose={handleBgClose}
                />
              )}
            </div>
          </ClickAwayListener>
        </div>
      )}
      <hr style={styles.seperator} />
    </div>
  );
}

export default ContentElement;
