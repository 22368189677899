import React, { useState, useEffect } from "react";

import ValueLabelComponent from '../common/valueLabelComponent';
import Slider from '../common/prettoSlider';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from '@material-ui/icons/Help';

import {
    makeStyles,
    TextField,
    FormControl,
} from "@material-ui/core";

const styles = {
    root: {
        padding: "31px",
    },
    input: {
        marginRight: "12px",
    },
    select: {
        appearance: "none",
        background: `url("https://res.cloudinary.com/dfcnic8wq/image/upload/v1616237238/FlowDash/ic_baseline-arrow-drop-down_lvshhj.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "25px 25px",
        backgroundPosition: "94% 7px",
        backgroundColor: "#95B8FE"
    },
};
const useStyles = makeStyles(theme => ({
    rootTableHead: {
        padding: "1px !important",
    },
    input: {
        borderColor: '#E5E5E5',
        marginRight: "12px",
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#E5E5E5',
            },
        },
    },
    outlined: {
        transform: "translate(14px, 14px) scale(1)"
    },
    focused: {
        color: "rgb(87, 143, 255) !important"
    },
}));

export default function AudienceEditor(props) {
    const [createdAtType, setCreatedAtType] = useState(props.userRules ?
        props.userRules.createdAt.type : "days");
    const [createdAtValue, setCreatedAtValue] = useState(props.userRules ? props.userRules.createdAt.value : 1);
    const [filter, setFilter] = useState(props.userRules ? "createdAt" : "any");

    const classes = useStyles();

    useEffect(() => {
    }, [props]);

    return (<div
        style={{
            display: "flex",
            flexDirection: "column",
        }}
    >
        <div
            style={{
                flex: 1,
            }}
        >
            <span>
                <span style={{ fontSize: 12 }}>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    >Rollout % <Tooltip arrow title="The percentage of people this campaign will be shown to.">
                            <HelpIcon style={{
                                padding: "5px",
                                color: 'rgb(172 172 172)',
                                cursor: "help",
                            }} /></Tooltip>
                    </span>
                </span>
                <Slider
                    style={{
                        color: '#95B8FE'
                    }}
                    ValueLabelComponent={(props) => <ValueLabelComponent suffix="%" {...props} />}
                    aria-label="custom thumb label"
                    defaultValue={props.percentageExposure}
                    min={0}
                    max={100}
                    onChange={(_, newValue) => props.handleAudienceChange(newValue)}
                />
            </span>
            <span style={{ fontSize: 12 }} ><p>Users whose</p>
                <div>
                    <select
                        defaultValue={filter}
                        placeholder="Role"
                        style={{ ...styles.input, width: "10rem", ...styles.select }}
                        value={filter}
                        onChange={(e) => {
                            if (e.target.value === "any") {
                                props.handleUserRuleChange(null);
                            }
                            else {
                                props.handleUserRuleChange({
                                    [e.target.value]: {
                                        type: createdAtType,
                                        value: createdAtValue
                                    }
                                })
                            }
                            setFilter(e.target.value);
                        }}
                        style={{
                            width: "10rem",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#fff",
                            padding: "13px 16px",
                            border: "1px solid #E5E5E5",
                            borderRadius: "4px",
                            verticalAlign: "center",
                            ...styles.select,
                            ...styles.input,
                        }}
                    >
                        <option value="createdAt" styles={styles.input}>Sign up date</option>
                        <option value="any" styles={styles.input}>All users</option>
                    </select>
                    {filter === "createdAt" &&
                        <>
                            <select
                                placeholder="Role"
                                style={{ ...styles.input, width: "10rem", ...styles.select }}
                                value={""}
                                // onChange={(e) => setRole(e.target.value)}
                                style={{
                                    width: "10rem",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    color: "#fff",
                                    padding: "13px 16px",
                                    border: "1px solid #E5E5E5",
                                    borderRadius: "4px",
                                    verticalAlign: "center",
                                    ...styles.select,
                                    ...styles.input,
                                }}
                            >
                                <option value="within" styles={styles.input}>is within</option>
                                {/* <option value="admin" styles={styles.input}>is</option> */}
                            </select>
                            <div style={{ marginTop: 12 }}>
                                <FormControl>
                                    <TextField
                                        value={createdAtValue}
                                        autoComplete="off"
                                        classes={{ root: classes.input }}
                                        id="email"
                                        variant="outlined"
                                        onChange={(e) => {
                                            let no = parseInt(e.target.value);
                                            no = isNaN(no) ? 0 : no;
                                            setCreatedAtValue(no);
                                            props.handleUserRuleChange({
                                                [filter]: {
                                                    type: createdAtType,
                                                    value: no
                                                }
                                            })
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                outlined: classes.outlined,
                                                focused: classes.focused,
                                                notchedOutline: classes.notchedOutline
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                width: "16.5rem",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                lineHeight: "16px",
                                                color: "#8C8C8C",
                                                border: "1px solid #E5E5E5",
                                                borderRadius: "4px",
                                                verticalAlign: "center",
                                                padding: 14
                                            },
                                        }}
                                    />
                                </FormControl>
                                <select
                                    placeholder="Role"
                                    style={{
                                        ...styles.input, width: "10rem",
                                    }}
                                    value={createdAtType}
                                    onChange={(e) => {
                                        setCreatedAtType(e.target.value);
                                        props.handleUserRuleChange({
                                            [filter]: {
                                                type: e.target.value,
                                                value: createdAtValue
                                            }
                                        })
                                    }}
                                    style={{
                                        width: "10rem",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        lineHeight: "16px",
                                        color: "#445C66",
                                        padding: "13px 16px",
                                        border: "1px solid #E5E5E5",
                                        borderRadius: "4px",
                                        verticalAlign: "center",
                                        appearance: "none",
                                        background: `url("https://res.cloudinary.com/dfcnic8wq/image/upload/v1616080231/FlowDash/dropdown_uwlknu.png")`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "10px 4px",
                                        backgroundPosition: "94% 18px",
                                        ...styles.input,
                                    }}
                                >
                                    <option value="days" styles={styles.input}>days</option>
                                    <option value="minutes" styles={styles.input}>minutes</option>
                                    <option value="seconds" styles={styles.input}>seconds</option>
                                </select>
                            </div>
                        </>
                    }
                </div>
            </span>
        </div>
    </div>)
}