import React, { Component, Fragment } from "react";
import CampaignList from "./campaign-list";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { bindActionCreators } from "redux";
import { getAllCampaigns } from "../../actions/campaigns";
import Welcomescreen from "./welcome-screen";

class AllCampaigns extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.loadCampaigns();
  }

  loadCampaigns = () => {
    this.props.getAllCampaigns(this.props.appOwner);
  };

  render() {
    const { isLoading, forceRender, walkthroughs } = this.props;
    return (
      <Fragment key="1">
        {!isLoading ? (
          (walkthroughs && walkthroughs.length > 0) ?
            (<ul
              style={{
                background: "rgba(214, 214, 215, 0.02)",
                padding: "0px 15px 30px 50px",
              }}
            >
              <li>
                <CampaignList forceRender={forceRender} title={"All campaigns"} />
              </li>
            </ul>) : (<Welcomescreen/>)
        ) : (
            <div>
              <Loader
                type="TailSpin"
                color="#6ad7dd"
                style={{ textAlign: "center" }}
                height={"60vh"}
                width={60}
              />
            </div>
          )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.campaigns.isLoading,
  appOwner: state.loginData.appOwner,
  walkthroughs: state.campaigns.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAllCampaigns }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllCampaigns);
