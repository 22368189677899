import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer>
        <h3>&copy; 2020 FlowDash.ai</h3>
        <ul>
          <li
            style={{
              marginBottom: "20px",
              textAlign: "left"
            }}
          >
            Engineered with{" "}
            <i
              className="fas fa-heart"
              style={{
                color: "#ff506c"
              }}
            ></i>{" "}
            in Bangalore
          </li>
          <li className="footer-contact">
            {" "}
            <a href="mailto:team@flowdash.ai?subject=I'm interested in FlowDash!">
              Get in touch
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/31357864">
              <i
                className="fab fa-linkedin-in"
                style={{
                  color: "#388ef1"
                }}
              ></i>{" "}
            </a>
          </li>
        </ul>
      </footer>
    );
  }
}

export default Footer;
