import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Select,
  FormControl,
} from "@material-ui/core";
import { Text12, Text14, Text18, BrokenImage } from "./staticComponents";
import { MEMBER_API, HOUSEWARE_NOTIFY_URL } from "../../constants/api";
import { checkValidEmail } from "../../constants/utils";
import { useLocation } from "react-router-dom";
import { FlowdashLoader } from "../common/loader";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  rootTableHead: {
    padding: "1px !important",
  },
  input: {
    marginRight: "40px",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "rgb(87, 143, 255)",
      },
    },
  },
  outlined: {
    transform: "translate(14px, 14px) scale(1)",
  },
  focused: {
    color: "rgb(87, 143, 255) !important",
  },
}));

const styles = {
  root: {
    padding: "31px",
  },
  input: {
    marginRight: "40px",
  },
  select: {
    appearance: "none",
    background: `url("https://res.cloudinary.com/dfcnic8wq/image/upload/v1616080231/FlowDash/dropdown_uwlknu.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "10px 4px",
    backgroundPosition: "94% 18px",
  },
};
function TeamSettings() {
  let location = useLocation();
  const classes = useStyles();
  const [members, setMembers] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("admin");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const requestOptions = {
      mode: "cors",
      method: "GET",
      headers: {
        "content-Type": "application/json",
        "x-api-key": location.appOwner?.apiKey,
      },
    };
    if (location && location.appOwner && location.appOwner.domainName) {
      setIsLoading(true);
      fetch(MEMBER_API + `${location.appOwner.guid}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            toast.error(data.error);
          } else {
            setMembers([...data]);
          }
          setIsLoading(false);
        });
    }
  }, []);

  const handleAddMember = () => {
    if (!checkValidEmail(email)) {
      setError(true);
      return;
    }
    setError(false);
    const requestOptions = {
      mode: "cors",
      method: "POST",
      headers: {
        "content-Type": "application/json",
        "x-api-key": location.appOwner?.apiKey,
      },
    };

    setIsLoading(true);
    fetch(MEMBER_API + `${location.appOwner?.guid}`, {
      ...requestOptions,
      body: JSON.stringify({
        guid: location.appOwner?.guid,
        domain: location.appOwner.domainName,
        email: email,
        date: new Date().toLocaleString(),
        role: role,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const requestOptions = {
          mode: "cors",
          method: "GET",
          headers: {
            "content-Type": "application/json",
            "x-api-key": location.appOwner?.apiKey,
          },
        };
        if (location && location.appOwner && location.appOwner.domainName) {
          fetch(MEMBER_API + `${location.appOwner.guid}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data.status) {
                toast.error(data.error);
              } else {
                setMembers([...data]);
                setIsLoading(false);
              }
            });
        }
        if (data.status != "ok") {
          toast.error("Member could not be added: " + data.error);
        }
      })
      .catch((e) => {
        toast.warn("Member could not be added: " + e);
        setIsLoading(false);
      });

      let message = `*User ${location.user?.email} invited ${email}*`
      fetch(HOUSEWARE_NOTIFY_URL, {
        ...requestOptions,
        body: JSON.stringify({
          text: message
        }),
      })
        .then((r) => console.log(r)).catch(e => console.log(e));
  };

  return (
    <Box style={styles.root}>
      <Box
        style={{ paddingBottom: "1.5rem", borderBottom: "1px solid #E5E5E5" }}
      >
        <Text18>Add a Member</Text18>
        <Text14>Invite others to join your workspace.</Text14>

        <FormControl>
          <TextField
            classes={{ root: classes.input }}
            label="Email"
            error={error}
            id="email"
            placeholder="Enter your email"
            helperText={error ? "Enter valid Email" : ""}
            variant="outlined"
            onChange={(e) => {
              setError(false);
              setEmail(e.target.value);
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.outlined,
                focused: classes.focused,
                notchedOutline: classes.notchedOutline,
              },
            }}
            inputProps={{
              style: {
                width: "16.5rem",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#8C8C8C",
                border: "1px solid #E5E5E5",
                borderRadius: "4px",
                verticalAlign: "center",
                padding: 14,
              },
            }}
          />
        </FormControl>
        <select
          placeholder="Role"
          style={{ ...styles.input, width: "10rem", ...styles.select }}
          value={role}
          onChange={(e) => setRole(e.target.value)}
          style={{
            width: "10rem",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#8C8C8C",
            padding: "13px 16px",
            border: "1px solid #E5E5E5",
            borderRadius: "4px",
            verticalAlign: "center",
            ...styles.select,
            ...styles.input,
          }}
        >
          <option value="admin" styles={styles.input}>
            Admin
          </option>
          <option value="nonadmin" styles={styles.input}>
            Non Admin
          </option>
        </select>
        <Button
          variant="outlined"
          style={{
            color: "#FF9080",
            border: "1px solid #FF9080",
            padding: "10px 3rem",
            textTransform: "none",
          }}
          onClick={handleAddMember}
        >
          Add
        </Button>
      </Box>
      <Box style={{ paddingTop: "1.5rem" }}>
        <Text18>Team</Text18>
        {isLoading ? (
          <FlowdashLoader />
        ) : (
          members && (
            <Box>
              <TableContainer
                style={{
                  border: "1px solid #E5E5E5",
                  borderRadius: "4px",
                  padding: "0 30px",
                  boxSizing: "border-box",
                  height:"500px",
                  overflowY:"scroll"
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                    <TableCell classes={{ root: classes.rootTableHead }} style={{width:"45px"}}>
                          <Text12></Text12>
                        </TableCell>
                      {members[0] && members[0].name && (
                        <TableCell classes={{ root: classes.rootTableHead }}>
                          <Text12>Name</Text12>
                        </TableCell>
                      )}
                      {members[0] && members[0].email && (
                        <TableCell
                          classes={{ root: classes.rootTableHead }} 
                        >
                          <Text12 style={{textAlign:"left"}}>Email</Text12>
                        </TableCell>
                      )}
                      {members[0] && members[0].role && (
                        <TableCell classes={{ root: classes.rootTableHead }}>
                          <Text12>Role</Text12>
                        </TableCell>
                      )}
                      {members[0] && members[0].date && (
                        <TableCell classes={{ root: classes.rootTableHead }}>
                          <Text12>Added</Text12>
                        </TableCell>
                      )}
                      {members[0] && members[0].status && (
                        <TableCell classes={{ root: classes.rootTableHead }}>
                          <Text12>Status</Text12>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {members.map((member) => (

                      <TableRow>
                      <TableCell>
                      {member && member.userImage ? (
                              <img
                                src={member.userImage}
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  padding: "10px",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <BrokenImage />
                            )}
                      </TableCell>
                        {members[0] && members[0].name && (
                          <TableCell classes={{ root: classes.rootTableHead }}>
                            <Text12
                              style={{ color: "#8c8c8c", fontWeight: "700" }}
                            >
                              {member.name}
                            </Text12>
                          </TableCell>
                        )}
                        {members[0] && members[0].email && (
                          <TableCell classes={{ root: classes.rootTableHead }}>
                            <Text12
                              style={{ color: "#8c8c8c", fontWeight: "700", textAlign:"left" }}
                            >
                              {member.email}
                            </Text12>
                          </TableCell>
                        )}
                        {members[0] && members[0].role && (
                          <TableCell classes={{ root: classes.rootTableHead }}>
                            <Text12
                              style={{ color: "#8c8c8c", fontWeight: "700" }}
                            >
                              {member.role}
                            </Text12>
                          </TableCell>
                        )}
                        {members[0] && members[0].date && (
                          <TableCell classes={{ root: classes.rootTableHead }}>
                            <Text12
                              style={{ color: "#8c8c8c", fontWeight: "700" }}
                            >
                              {member.date}
                            </Text12>
                          </TableCell>
                        )}
                        {members[0] && members[0].status && (
                          <TableCell classes={{ root: classes.rootTableHead }}>
                            <Text12
                              style={{ color: "#8c8c8c", fontWeight: "700" }}
                            >
                              {member.status}
                            </Text12>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
}

export default TeamSettings;
