import {
  FETCH_CAMPAIGNS_REQUESTED,
  FETCH_CAMPAIGNS_FULFILLED,
  FETCH_CAMPAIGNS_REJECTED,
  SELECT_CAMPAIGN_FULFILLED,
  SELECT_CAMPAIGN_REJECTED,
  SELECT_CAMPAIGN_REQUESTED,
  SELECT_STEP_FULFILLED,
  SET_CAMPAIGN_DIRTY,
  SET_CAMPAIGN_CLEAN,
  SAVE_CAMPAIGN_FULFILLED,
  SAVE_CAMPAIGN_REJECTED,
  SAVE_CAMPAIGN_REQUESTED,
  PUBLISH_CAMPAIGN_FULFILLED,
  PUBLISH_CAMPAIGN_REJECTED,
  PUBLISH_CAMPAIGN_REQUESTED,
  INIT_CURRENT_CAMPAIGN_DRAFT,
  INIT_CURRENT_CAMPAIGN_DRAFT_ANDROID,
  INIT_CURRENT_CAMPAIGN_DRAFT_WEB,
  EDIT_CURRENT_CAMPAIGN_DRAFT,
  EDIT_CAMPAIGN_STATUS_FULFILLED,
  EDIT_CAMPAIGN_STATUS_REJECTED,
  EDIT_CAMPAIGN_STATUS_REQUESTED,
  SELECT_SCREEN_FULFILLED,
  SELECT_CAMPAIGN_FULFILLED_ANDROID,
  EDIT_CURRENT_CAMPAIGN_DRAFT_ANDROID,
  SELECT_CAMPAIGN_FULFILLED_WEB,
  SELECT_STEP_FULFILLED_WEB,
  EDIT_CURRENT_CAMPAIGN_DRAFT_WEB,
} from "../constants/actions";

import { CORE_API_BASE_URL } from "../constants/api";

const WALKTHROUGH_URL_PREFIX = "walkthrough/ui/";

let walkthroughList = [];

const campaignsRequested = () => ({
  type: FETCH_CAMPAIGNS_REQUESTED,
});

const campaignsRejected = (error) => ({
  type: FETCH_CAMPAIGNS_REJECTED,
  payload: error,
});

const campaignsFulflled = (data) => ({
  type: FETCH_CAMPAIGNS_FULFILLED,
  payload: data,
});

export const getAllCampaigns = (appOwner) => async (dispatch) => {
  dispatch(campaignsRequested());
  try {
    var requestOptions = {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        api_key: appOwner.apiKey,
      },
    };

    let ownerId = appOwner.guid;
    let fetchURL = CORE_API_BASE_URL + WALKTHROUGH_URL_PREFIX + `${ownerId}/`;
    if (!ownerId) dispatch(campaignsRejected("Bad account"));
    fetch(fetchURL, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        walkthroughList = [];
        for (let index in result) {
          try {
            let campaign = {
              id: result[index].id,
              details: JSON.parse(result[index].value),
              name: result[index].name,
              status: result[index].status,
              percentageExposure: result[index].percentageExposure,
              userRules: result[index].userRules ? JSON.parse(result[index].userRules) : null,
              eventRules: result[index].eventRules ? JSON.parse(result[index].eventRules) : null,
              stats: result[index].stats
            };
            // Move to RN specific component
            // Set default template to tooltip.
            if (appOwner.appType !== "ANDROID") {
              for (let index = 0; index < campaign.details.length; index++) {
                if (campaign.details[index].type === undefined) {
                  campaign.details[index].type = 0;
                  campaign.details[index].defaultType = true;
                }
              }
            }
            walkthroughList.push(campaign);
          } catch (e) {
            console.log("Error parsing campaign: " + result[index].id, e);
          }
        }
        dispatch(campaignsFulflled(walkthroughList));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(campaignsRejected(error));
      });
  } catch (err) {
    dispatch(campaignsRejected(err));
  }
};

const selectCampaignRequested = () => ({
  type: SELECT_CAMPAIGN_REQUESTED,
});

const selectCampaignRejected = (error) => ({
  type: SELECT_CAMPAIGN_REJECTED,
  payload: error,
});

const selectCampaignFulfilled = (data) => ({
  type: SELECT_CAMPAIGN_FULFILLED,
  payload: data,
});

const selectCampaignFulfilledWeb = (data) => ({
  type: SELECT_CAMPAIGN_FULFILLED_WEB,
  payload: data,
});

const selectCampaignFulfilledAndroid = (data) => ({
  type: SELECT_CAMPAIGN_FULFILLED_ANDROID,
  payload: data,
});

const initialiseCurrentCampaignDraft = (data) => ({
  type: INIT_CURRENT_CAMPAIGN_DRAFT,
});

export const initialiseCurrentCampaignDraftWeb = (data) => ({
  type: INIT_CURRENT_CAMPAIGN_DRAFT_WEB,
});


const initialiseCurrentCampaignDraftAndroid = (data) => ({
  type: INIT_CURRENT_CAMPAIGN_DRAFT_ANDROID,
});

export const selectCampaign = (id) => async (dispatch) => {
  dispatch(selectCampaignRequested());
  try {
    setTimeout(() => {
      dispatch(selectCampaignFulfilled(id));
    }, 1000);
  } catch (err) {
    dispatch(selectCampaignRejected(err));
  }
};

export const selectCampaignWeb = (id) => async (dispatch) => {
  dispatch(selectCampaignRequested());
  try {
    setTimeout(() => {
      dispatch(selectCampaignFulfilledWeb(id));
    }, 1000);
  } catch (err) {
    dispatch(selectCampaignRejected(err));
  }
};

export const selectCampaignAndroid = (id) => async (dispatch) => {
  dispatch(selectCampaignRequested());
  try {
    setTimeout(() => {
      dispatch(selectCampaignFulfilledAndroid(id));
    }, 1000);
  } catch (err) {
    dispatch(selectCampaignRejected(err));
  }
};

export const selectCampaignStepAndroid = (stepIndex) => (dispatch) => {
  dispatch({
    type: SELECT_STEP_FULFILLED,
    payload: stepIndex
  });
  dispatch(initialiseCurrentCampaignDraftAndroid());
}

export const selectCampaignStepWeb = (stepIndex) => (dispatch) => {
  dispatch({
    type: SELECT_STEP_FULFILLED_WEB,
    payload: stepIndex,
  });
  dispatch(initialiseCurrentCampaignDraftWeb());
};

export const selectCampaignStep = (stepIndex) => (dispatch) => {
  dispatch({
    type: SELECT_STEP_FULFILLED,
    payload: stepIndex,
  });
  dispatch(initialiseCurrentCampaignDraft());
};

export const selectCampaignScreen = (screenIndex) => (dispatch) => {
  dispatch({
    type: SELECT_SCREEN_FULFILLED,
    payload: screenIndex,
  });
  dispatch(initialiseCurrentCampaignDraft());

}

export const editCurrentCampaignDraftAndroid = (data) => ({
  type: EDIT_CURRENT_CAMPAIGN_DRAFT_ANDROID,
  payload: data
})

export const editCurrentCampaignDraft = (data) => ({
  type: EDIT_CURRENT_CAMPAIGN_DRAFT,
  payload: data,
});

export const editCurrentCampaignDraftWeb = (data) => ({
  type: EDIT_CURRENT_CAMPAIGN_DRAFT_WEB,
  payload: data,
});

export const setCampaignDirty = () => ({
  type: SET_CAMPAIGN_DIRTY,
});

export const setCampaignClean = () => ({
  type: SET_CAMPAIGN_CLEAN,
});

export const saveCampaignRequested = () => ({
  type: SAVE_CAMPAIGN_REQUESTED,
});
export const saveCampaignRejected = (error) => ({
  type: SAVE_CAMPAIGN_REJECTED,
  payload: error,
});
export const saveCampaignFulfilled = (data) => ({
  type: SAVE_CAMPAIGN_FULFILLED,
  payload: data,
});

export const publishCampaignRequested = () => ({
  type: PUBLISH_CAMPAIGN_REQUESTED,
});
export const publishCampaignRejected = (error) => ({
  type: PUBLISH_CAMPAIGN_REJECTED,
  payload: error,
});
export const publishCampaignFulfilled = (data) => ({
  type: PUBLISH_CAMPAIGN_FULFILLED,
  payload: data,
});

export const saveCampaignWeb = (
  campaignId,
  currentScreenIndex,
  currentStepIndex,
  campaignData,
  campaignName,
  percentageExposure,
  userRules
) => async (dispatch) => {
  dispatch(saveCampaignRequested());
  try {
    let selected = walkthroughList.filter((w) => w.id == campaignId)[0];
    let view = (selected.details[currentScreenIndex].steps[currentStepIndex].view);

    if (view.content) {
      view.content.skipAction = campaignData[currentStepIndex].skipAction;
      view.content.actionText = campaignData[currentStepIndex].actionText;
      view.content.primaryText = campaignData[currentStepIndex].primaryText;
      view.content.secondaryText = campaignData[currentStepIndex].secondaryText;
      view.content.headerImage = campaignData[currentStepIndex].headerImage;
    }

    view.tooltipStyle = JSON.parse(campaignData[currentStepIndex].code);

    view.direction = campaignData[currentStepIndex].tooltipDirection;
    view.isSpotlight = campaignData[currentStepIndex].isSpotlight;

    selected.details[currentScreenIndex].steps[currentStepIndex].view = view;

    selected.details[currentScreenIndex].type =
      campaignData[currentStepIndex].type;

    selected.name = campaignName;
    selected.percentageExposure = percentageExposure;
    if (userRules !== null) {
      selected.userRules = userRules;
    }
    else {
      selected.userRules = null;
    }

    if (campaignData[currentStepIndex].eventRules) {
      selected.eventRules = JSON.stringify(campaignData[currentStepIndex].eventRules);
    }

    for (let index = 0; index < walkthroughList.length; index++) {
      const current = walkthroughList[index];
      if (current.id == campaignId) {
        walkthroughList[index] = selected;
      }
    }
    setTimeout(() => {
      dispatch(saveCampaignFulfilled(true));
    }, 1000);
  } catch (err) {
    console.log("HERE", err);
    dispatch(saveCampaignRejected(err));
  }
};

export const saveCampaign = (
  campaignId,
  currentScreenIndex,
  currentStepIndex,
  campaignData,
  campaignName
) => async (dispatch) => {
  dispatch(saveCampaignRequested());
  try {

    let selected = walkthroughList.filter((w) => w.id == campaignId)[0];
    let view = JSON.parse(selected.details[currentScreenIndex].steps[currentStepIndex].view);

    view.content.skipAction = campaignData[currentStepIndex].skipAction;
    view.content.actionText = campaignData[currentStepIndex].actionText;
    view.content.primaryText = campaignData[currentStepIndex].primaryText;
    view.content.secondaryText = campaignData[currentStepIndex].secondaryText;

    if (selected.details[currentScreenIndex].type == 0) {
      view.tooltipStyle = JSON.parse(campaignData[currentStepIndex].code);
    }
    else {
      view.style = JSON.parse(campaignData[currentStepIndex].code);
    }
    selected.details[currentScreenIndex].steps[currentStepIndex].view = JSON.stringify(view);
    selected.details[currentScreenIndex].steps[currentStepIndex].scrollToView =
      campaignData[currentStepIndex].scrollToView;

    selected.details[currentScreenIndex].steps[currentStepIndex].tooltipDirection =
      campaignData[currentStepIndex].tooltipDirection;

    selected.details[currentScreenIndex].type =
      campaignData[currentStepIndex].type;

    selected.details[currentScreenIndex].defaultType = false;
    selected.details[currentScreenIndex].steps[currentStepIndex].showIndexView =
      campaignData[currentStepIndex].showStepNumber;

    selected.name = campaignName;
    selected.percentageExposure = campaignData[currentStepIndex].percentageExposure;
    for (let index = 0; index < walkthroughList.length; index++) {
      const current = walkthroughList[index];
      if (current.id == campaignId) {
        walkthroughList[index] = selected;
      }
    }
    setTimeout(() => {
      dispatch(saveCampaignFulfilled(true));
    }, 1000);
  } catch (err) {
    dispatch(saveCampaignRejected(err));
  }
};

export const saveCampaignAndroid = (
  // Write for android
  campaignId,
  draft
) => async (dispatch) => {
  dispatch(saveCampaignRequested());
  try {

    let selected = walkthroughList.filter((w) => w.id == campaignId)[0];
    for (let index = 0; index < walkthroughList.length; index++) {
      const current = walkthroughList[index];
      if (current.id == campaignId) {
        walkthroughList[index] = draft;
      }
    }
    setTimeout(() => {
      dispatch(saveCampaignFulfilled(true));
    }, 1000);
  } catch (err) {
    dispatch(saveCampaignRejected(err));
  }
};

export const publishCampaign = (appOwner, campaignId) => async (dispatch) => {
  dispatch(publishCampaignRequested());
  try {
    let selected = walkthroughList.filter((w) => w.id == campaignId)[0];
    let requestOptions = {
      mode: "cors",
      method: "PUT",
      headers: {
        api_key: appOwner.apiKey,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: selected.name || "",
        percentageExposure: selected.percentageExposure || 0,
        userRules: selected.userRules ? JSON.stringify(selected.userRules) : null,
        eventRules: JSON.stringify(selected.eventRules),
        status: selected.status,
        value: JSON.stringify(selected.details)
      }),
    };
    let ownerId = appOwner.guid;
    let fetchURL = CORE_API_BASE_URL + WALKTHROUGH_URL_PREFIX + `${ownerId}/${campaignId}`;
    fetch(fetchURL, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("Successful", result);
        dispatch(publishCampaignFulfilled(true));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(publishCampaignRejected(error));
      });
  } catch (err) {
    dispatch(publishCampaignRejected(err));
  }
};

export const editCampaignStatusRequested = () => ({
  type: EDIT_CAMPAIGN_STATUS_REQUESTED,
});
export const editCampaignStatusRejected = (error) => ({
  type: EDIT_CAMPAIGN_STATUS_REJECTED,
  payload: error,
});
export const editCampaignStatusFulfilled = (data) => ({
  type: EDIT_CAMPAIGN_STATUS_FULFILLED,
  payload: data,
});

export const editCampaignStatus = (appOwner, campaignId, newStatus, percentageExposure) => async (
  dispatch
) => {
  dispatch(editCampaignStatusRequested());
  try {
    console.log(appOwner, campaignId, newStatus);
    let selected = walkthroughList.filter((w) => w.id == campaignId)[0];

    let requestOptions = {
      mode: "cors",
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        api_key: appOwner.apiKey,
      },
      body: JSON.stringify({
        name: selected.name || "",
        value: JSON.stringify(selected.details),
        status: newStatus,
        percentageExposure: percentageExposure,
        userRules: selected.userRules ? JSON.stringify(selected.userRules) : null,
        eventRules: selected.eventRules ? JSON.stringify(selected.eventRules) : null,
      }),
    };
    let ownerId = appOwner.guid;
    let fetchURL =
      CORE_API_BASE_URL + WALKTHROUGH_URL_PREFIX + `${ownerId}/${campaignId}`;
    fetch(fetchURL, requestOptions)
      .then((response) => response.json())
      .then((success) => {
        console.log("Successful", success);
        if (success) {
          dispatch(
            editCampaignStatusFulfilled({ success, newStatus, campaignId })
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(editCampaignStatusRejected(error));
      });
  } catch (err) {
    dispatch(editCampaignStatusRejected(err));
  }
};


export const addOrRemoveIntroView = (action, campaignId) => async (dispatch) => {
  dispatch(saveCampaignRequested());
  let selected = walkthroughList.filter((w) => w.id == campaignId)[0];
  if (action == "remove") {
    selected.details[0].steps.shift();
  }
  else if (action == "add") {
    let introElement = {
      "viewIndex": -5679,
      "scrollToView": false,
      "view": JSON.parse("{\"style\":{\"actionView\":{\"position\":\"absolute\",\"top\":340,\"right\":85,\"backgroundColor\":\"rgb(101, 244, 212)\",\"display\":\"flex\",\"alignItems\":\"center\",\"justifyContent\":\"center\",\"width\":90,\"height\":30,\"borderRadius\":40},\"actionText\":{\"textTransform\":\"uppercase\",\"fontWeight\":\"bold\"},\"primaryText\":{\"position\":\"absolute\",\"top\":260,\"left\":24,\"color\":\"white\",\"fontSize\":24},\"secondaryText\":{\"position\":\"absolute\",\"top\":295,\"left\":24,\"color\":\"white\",\"fontSize\":18},\"skipActionText\":{\"fontSize\":16,\"color\":\"rgb(101, 244, 212)\",\"fontWeight\":\"bold\"},\"skipAction\":{\"position\":\"absolute\",\"bottom\":58,\"left\":15,\"zIndex\":2,\"fontSize\":16,\"fontWeight\":\"bold\"},\"indexView\":{\"position\":\"absolute\",\"top\":90,\"left\":50,\"color\":\"#65f4d4\",\"fontSize\":30,\"fontWeight\":\"bold\"}},\"content\":{\"actionText\":\"Start\",\"skipAction\":\"Skip\",\"primaryText\":\"Welcome to the tour\",\"secondaryText\":\"Click start to get started\"},\"showIndexView\":false}"
      )
    };
    selected.details[0].steps = [introElement].concat(selected.details[0].steps);
  }

  for (let index = 0; index < walkthroughList.length; index++) {
    const current = walkthroughList[index];
    if (current.id == campaignId) {
      walkthroughList[index] = selected;
    }
  }

  setTimeout(() => {
    dispatch(selectCampaignStep(0));
    dispatch(saveCampaignFulfilled(true));
  }, 1000);
}

export const addOrRemoveIntroViewWeb = (action, campaignId) => async (dispatch) => {
  dispatch(saveCampaignRequested());
  let selected = walkthroughList.filter((w) => w.id == campaignId)[0];
  if (action == "remove") {
    selected.details[0].steps.shift();
  }
  else if (action == "add") {
    let introElement = {
      "viewIndex": -5679,
      "scrollToView": false,
      "view": {
        "direction": 0,
        "content": { "actionText": "Start", "skipAction": "Skip", "primaryText": "Welcome to the tour", "secondaryText": "Click start to get started" },
        "tooltipStyle": { "tooltip": { "backgroundColor": "#fff" }, "actionView": { "backgroundColor": "#eb9373", "display": "flex", "alignItems": "center", "justifyContent": "center", "max-width": 250, "min-width": 70, "height": 30, "borderRadius": 4 }, "wrapper": { "borderColor": "#ffffff", "borderWidth": 1, "borderRadius": 5, "zIndex": 4 }, "actionText": { "color": "white", margin: '0px 20px' }, "skipActionText": { "fontSize": 16, "color": "rgb(217 217 217)", "fontWeight": "bold" }, "skipAction": { "bottom": 58, "left": 15, "zIndex": 2, "fontSize": 16, "fontWeight": "bold", "position": "absolute" }, "primaryText": { "top": 113.61904999999999, "left": 17.142857, "color": "#000000", "fontSize": 28 }, "secondaryText": { "top": 113.61904999999999, "left": 17.142857, "color": "#747474", "fontSize": "14px" }, "focussedView": { "top": 143.61905, "left": 17.142857, "width": 115.04762, "height": 115.04762 }, "indexView": { color: '#747474' } }
      }
    };
    selected.details[0].steps = [introElement].concat(selected.details[0].steps);
  }

  for (let index = 0; index < walkthroughList.length; index++) {
    const current = walkthroughList[index];
    if (current.id == campaignId) {
      walkthroughList[index] = selected;
    }
  }

  setTimeout(() => {
    dispatch(selectCampaignStepWeb(0));
    dispatch(saveCampaignFulfilled(true));
  }, 1000);
}