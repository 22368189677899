import React, { useState } from "react";

import "./App.css";
import ContentElement from "./ContentElement";
import TooltipElement from "./TooltipElement";
function CampaignEditor(props) {
  const handleNumberChange = (source, property, value, template) => {
    let no = parseInt(value);
    no = no === NaN ? 0 : no;
    props.handleStyleChange(source, property, no, template);
  };
  const handleColorChange = (source, property, color, event, template) => {
    props.handleStyleChange(source, property, color.hex, template);
  };

  const handleSpotlightToggle = (event, bool) => {
    props.editCurrentCampaignDraftWeb({
      [event.target.name]: !bool,
    });
  };

  const handleAlignmentChange = (source, property, value, template) => {
    props.handleStyleChange(source, property, value, template);
  };

  const handleFontWeightChange = (source, property, value, template) => {
    props.handleStyleChange(source, property, value, template);
  };

  const handleFontStyleChange = (source, property, value, template) => {
    props.handleStyleChange(source, property, value, template);
  };

  const handleFontDecorationChange = (source, property, value, template) => {
    props.handleStyleChange(source, property, value, template);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <TooltipElement
        title="Tooltip"
        headerImage={
          props.currentCampaignDraft[props.currentStepIndex].headerImage
        }
        handleSpotlightToggle={handleSpotlightToggle}
        isSpotlight={
          props.currentCampaignDraft[props.currentStepIndex].isSpotlight
        }
        handleTooltipDirectionChange={props.handleTooltipDirectionChange}
        isIntroView={props.isIntroView}
        propColor={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .tooltip
            ? props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
                .tooltip.backgroundColor
            : "#fff"
        }
        handleColorChange={(color, event) =>
          handleColorChange(
            "tooltip",
            "backgroundColor",
            color,
            event,
            "tooltip"
          )
        }
        handleImageChange={(text) => {
          props.handleTextChange("headerImage", text);
        }}
      />
      <ContentElement
        title="Title Text"
        propText={
          props.currentCampaignDraft[props.currentStepIndex].primaryText
        }
        handleTextChange={(text) => {
          props.handleTextChange("primaryText", text);
        }}
        propFontSize={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .primaryText.fontSize
        }
        handleFontChange={(event) => {
          handleNumberChange(
            "primaryText",
            "fontSize",
            event.target.value,
            "tooltip"
          );
        }}
        propColor={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .primaryText.color
        }
        handleColorChange={(color, event) =>
          handleColorChange("primaryText", "color", color, event, "tooltip")
        }
        propTextAlign={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .primaryText.textAlign
        }
        handleAlignmentChange={(align) =>
          handleAlignmentChange("primaryText", "textAlign", align, "tooltip")
        }
        propFontWeight={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .primaryText.fontWeight
        }
        handleFontWeightChange={(value) => {
          handleFontWeightChange("primaryText", "fontWeight", value, "tooltip");
        }}
        propFontStyle={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .primaryText.fontStyle
        }
        handleFontStyleChange={(value) => {
          handleFontStyleChange("primaryText", "fontStyle", value, "tooltip");
        }}
        propFontDecoration={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .primaryText.textDecoration
        }
        handleFontDecorationChange={(value) => {
          handleFontWeightChange(
            "primaryText",
            "textDecoration",
            value,
            "tooltip"
          );
        }}
      />
      <ContentElement
        title="Secondary Text"
        propText={
          props.currentCampaignDraft[props.currentStepIndex].secondaryText
        }
        handleTextChange={(text) => {
          props.handleTextChange("secondaryText", text);
        }}
        propFontSize={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .secondaryText.fontSize
        }
        handleFontChange={(event) => {
          handleNumberChange(
            "secondaryText",
            "fontSize",
            event.target.value,
            "tooltip"
          );
        }}
        propColor={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .secondaryText.color
        }
        handleColorChange={(color, event) =>
          handleColorChange("secondaryText", "color", color, event, "tooltip")
        }
        propTextAlign={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .secondaryText.textAlign
        }
        handleAlignmentChange={(align) =>
          handleAlignmentChange("secondaryText", "textAlign", align, "tooltip")
        }
        propFontWeight={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .secondaryText.fontWeight
        }
        handleFontWeightChange={(value) => {
          handleFontWeightChange(
            "secondaryText",
            "fontWeight",
            value,
            "tooltip"
          );
        }}
        propFontStyle={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .secondaryText.fontStyle
        }
        handleFontStyleChange={(value) => {
          handleFontStyleChange("secondaryText", "fontStyle", value, "tooltip");
        }}
        propFontDecoration={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .secondaryText.textDecoration
        }
        handleFontDecorationChange={(value) => {
          handleFontDecorationChange(
            "secondaryText",
            "textDecoration",
            value,
            "tooltip"
          );
        }}
      />
      {!props.isIntroView && (
        <ContentElement
          title="Skip Text"
          propText={
            props.currentCampaignDraft[props.currentStepIndex].skipAction
          }
          handleTextChange={(text) => {
            props.handleTextChange("skipAction", text);
          }}
          propFontSize={
            props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
              .skipAction.fontSize
          }
          handleFontChange={(event) => {
            handleNumberChange(
              "skipAction",
              "fontSize",
              event.target.value,
              "tooltip"
            );
          }}
          propColor={
            props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
              .skipActionText.color
          }
          handleColorChange={(color, event) =>
            handleColorChange(
              "skipActionText",
              "color",
              color,
              event,
              "tooltip"
            )
          }
          propTextAlign={false}
          propFontWeight={
            props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
              .secondaryText.fontWeight
          }
          handleFontWeightChange={(value) => {
            handleFontWeightChange(
              "skipActionText",
              "fontWeight",
              value,
              "tooltip"
            );
          }}
          propFontStyle={
            props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
              .skipActionText.fontStyle
          }
          handleFontStyleChange={(value) => {
            handleFontStyleChange(
              "skipActionText",
              "fontStyle",
              value,
              "tooltip"
            );
          }}
          propFontDecoration={
            props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
              .skipActionText.textDecoration
          }
          handleFontDecorationChange={(value) => {
            handleFontDecorationChange(
              "skipActionText",
              "textDecoration",
              value,
              "tooltip"
            );
          }}
        />
      )}
      <ContentElement
        title="Next Button"
        propText={props.currentCampaignDraft[props.currentStepIndex].actionText}
        handleTextChange={(text) => {
          props.handleTextChange("actionText", text);
        }}
        propFontSize={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .actionText.fontSize
        }
        handleFontChange={(event) => {
          handleNumberChange(
            "actionText",
            "fontSize",
            event.target.value,
            "tooltip"
          );
        }}
        propColor={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .actionText.color
        }
        handleColorChange={(color, event) =>
          handleColorChange("actionText", "color", color, event, "tooltip")
        }
        propBgColor={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .actionView.backgroundColor
        }
        propTextAlign={false}
        handleBgColorChange={(color, event) =>
          handleColorChange(
            "actionView",
            "backgroundColor",
            color,
            event,
            "tooltip"
          )
        }
        propFontWeight={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .secondaryText.fontWeight
        }
        handleFontWeightChange={(value) => {
          handleFontWeightChange("actionText", "fontWeight", value, "tooltip");
        }}
        propFontStyle={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .actionText.fontStyle
        }
        handleFontStyleChange={(value) => {
          handleFontStyleChange("actionText", "fontStyle", value, "tooltip");
        }}
        propFontDecoration={
          props.currentCampaignDraft[props.currentStepIndex].tooltipStyle
            .actionText.textDecoration
        }
        handleFontDecorationChange={(value) => {
          handleFontDecorationChange(
            "actionText",
            "textDecoration",
            value,
            "tooltip"
          );
        }}
      />
    </div>
  );
}

export default CampaignEditor;
