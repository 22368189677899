import {
  LOGIN_FULFILLED,
  LOGIN_REQUESTED,
  LOGIN_REJECTED,
} from "../constants/actions";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  appOwner: {
    apiKey: null,
    appName: null,
    domainName: null,
    guid: null,
    id: null,
  },
  user: {},
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_FULFILLED:
      return {
        ...state, // TODO - Revert below change when pushing.
        appOwner: {
          ...action.payload.data,
          appType: 'WEB',
          appName: action.payload.data.appName || "ACME Inc.",
          domainName: action.payload.data.domain
        },
        user: action.payload.userDetails,
        isAuthenticated: true,
        isLoading: false,
      };
    case LOGIN_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        appOwner: initialState.appOwner,
      };

    case LOGIN_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
