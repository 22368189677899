import React, { useState } from "react";
import { Link } from "react-scroll";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import Face from "@material-ui/icons/Face";
import Logo from "../assets/FlowDash_Icon_2x.png";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { useCookies } from "react-cookie";
import IconButton from "@material-ui/core/IconButton";
import SideNav from "./sidenav";
import UserMenu from "./common/user-menu";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { publishCampaign } from "../actions/campaigns";
import ShipIcon from "@material-ui/icons/Launch";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

import DescriptionIcon from "@material-ui/icons/Description";

const useStyles = makeStyles((theme) => ({
  userIcon: {
    fontSize: "2.1em",
    color: "#a1a2b3",
  },
}));

function Nav(props) {
  const classes = useStyles();
  const [change, setChange] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies([
    "circuital_user_email",
    "circuital_user_name",
    "circutial_user_dp"
  ]);

  const changePosition = 80;

  let position = useWindowScrollPosition();
  // position == { x: 0, y: 0 }

  if (position.y > changePosition && !change) {
    setChange(true);
  }

  if (position.y <= changePosition && change) {
    setChange(false);
  }

  let style = {
    backgroundColor: change ? "black" : "transparent",
    boxShadow: change ? "0px 13px 13px -18px rgba(0,0,0,0.75)" : "none",
    color: change ? "black" : "white",
  };

  let navClass = change || props.arena ? "nav-class-active" : "nav-class";

  if (props.arena) {
    navClass += " inarena";
  }
  function openNav() {
    document.getElementById("mySidenav").style.width = "60%";
  }

  return (
    <nav className={navClass}>
      <SideNav
        {...props}
        cookies={cookies}
        removeCookie={removeCookie}
        forceRender={props.forceRender}
      />
      <ul className="app-logo-container">
        <li className="logo">
          <div>
            <RouterLink to="/app">
              <img
                src={Logo}
                style={{
                  height: "32px",
                  margin: "2px 5px -5px",
                  padding: "0px 2px",
                  cursor: "pointer",
                }}
              ></img>
            </RouterLink>
          </div>
        </li>
      </ul>
      <div
        style={{
          display: "flex",
        }}
      >
        <span
          style={{
            padding: "10px",
            borderLeft: "1px solid #ebecf2",
            borderRight: "1px solid #ebecf2",
          }}
        >
          {props.appOwner.appName || "ACME Inc."}
        </span>
        {props.pendingPublish && (
          <Tooltip arrow title="Publish changes to save permanently">
            <span
              style={{
                padding: 10,
                color: "#d2a31b",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TrendingUpIcon style={{ padding: "0px 5px" }} />{" "}
              <>You have unpublished changes</>{" "}
            </span>
          </Tooltip>
        )}
      </div>
      <ul
        className="mainNav"
        style={{
          gridTemplateColumns:
            props.arena && !props.showPublish
              ? ".5fr .5fr .5fr 1fr"
              : "0.8fr 0.8fr 0.8fr 0.8fr 0.8fr",
        }}
      >
        <li
          style={{
            margin: "0px 20px",
            borderRadius: "50%",
          }}
        >
          <IconButton
            size="small"
            style={{
              borderRadius: "2px",
              fontSize: "1em",
              flex: 1,
            }}
            onClick={(ev) => {
              ev.preventDefault();
              window.open("https://docs.flowdash.ai");
            }}
          >
            <Tooltip title="Documentation">
              <DescriptionIcon />
            </Tooltip>
          </IconButton>
        </li>
        <li
          style={{
            margin: "0px 20px",
            borderRadius: "50%",
          }}
        >
          <IconButton
            size="small"
            style={{
              borderRadius: "2px",
              fontSize: "1em",
              flex: 1,
            }}
            onClick={(ev) => {
              ev.preventDefault();
              window.open(
                `mailto:team@flowdash.ai?subject=I'm facing an issue`
              );
            }}
          >
            <Tooltip title="Help (opens email)">
              <HelpIcon />
            </Tooltip>
          </IconButton>
        </li>
        <li
          style={{
            border: "1px solid #ebecf2",
            margin: "0px 20px",
            borderRadius: "50%",
          }}
        >
          <IconButton
            size="small"
            style={{
              borderRadius: "2px",
              fontSize: "1em",
              flex: 1,
            }}
          >
            <Tooltip title="Notifications">
              <NotificationsIcon />
            </Tooltip>
          </IconButton>
        </li>
        {props.showPublish && (
          <>
            <li
              className="footer-contact"
              style={{
                margin: "5px",
                borderRadius: "5px",
              }}
            >
              <a
                onClick={() =>
                  props.publishCampaign(
                    props.appOwner,
                    props.currentCampaign.id
                  )
                }
              >
                <span
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridColumnGap: "5px",
                  }}
                >
                  <span>Publish</span>
                  <ShipIcon
                    style={{
                      color: "rgb(250 252 255)",
                      fontSize: "1.2em",
                    }}
                  />
                </span>
              </a>
            </li>
          </>
        )}
        <li
          style={{
            borderLeft: "1px solid #ebecf2",
            margin: "0px 20px",
            padding: "0px 6px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
            }}
            activeClass="active"
            offset={-70}
            duration={500}
          >
            <IconButton
              size="small"
              style={{
                borderRadius: "2px",
                fontSize: "1em",
                flex: 1,
              }}
            >
              <span
                style={{ display: "flex", flex: 2, justifyContent: "flex-end" }}
              >
                <UserMenu
                  removeCookie={removeCookie}
                  forceRender={props.forceRender}
                  signOut={props.signOut}
                />
              </span>
            </IconButton>
          </div>
        </li>
      </ul>
    </nav>
  );
}

const NavWithRouter = withRouter((props) => <Nav {...props} />);

const mapStateToProps = (state) => ({
  currentCampaign: state.campaigns.currentCampaign,
  appOwner: state.loginData.appOwner,
  user: state.loginData.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      publishCampaign,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(NavWithRouter));
