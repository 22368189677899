import React, { useState } from "react";

import { NavLink } from "react-router-dom";

const styles = {
  navlink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "18px",
    padding: "0 1rem",
    margin: "0 1rem",
    color: "#1c1f28",
  },
  active: {
    color: "#578FFF",
    borderBottom: "2px solid #578FFF",
  },
};

function Settings(props) {
  return (
    <div
      style={{
        background: "#FFFFFF",
        border: "1px solid #E5E5E5",
        boxSizing: "border-box",
        borderRadius: "6px",
        width: "93%",
        minHeight: "80vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          boxSizing: "border-box",
          width: "100%",
          height: "77px",
          padding: "0 2rem",
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        <NavLink
          to={{ pathname: "/app/settings/snippets", appOwner: props.appOwner, user: props.user }}
          style={styles.navlink}
          activeStyle={styles.active}
        >
          Your Snippets
        </NavLink>
        {/* <NavLink
          to="/app/settings/profile"
          style={styles.navlink}
          activeStyle={styles.active}
        >
          Your Profile
        </NavLink> */}
        <NavLink
          to={{ pathname: "/app/settings/team", appOwner: props.appOwner, user: props.user }}
          style={styles.navlink}
          activeStyle={styles.active}
        >
          Team Settings
        </NavLink>
        {/* <NavLink
          to={{ pathname: "/app/settings/subscription", appOwner: props.appOwner, user: props.user }}
          style={styles.navlink}
          activeStyle={styles.active}
        >
          Subscription
        </NavLink> */}
      </div>
      {<props.toRender {...props} />}
    </div>
  );
}

export default Settings;
