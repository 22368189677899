import {
  FETCH_CAMPAIGNS_REQUESTED,
  FETCH_CAMPAIGNS_FULFILLED,
  FETCH_CAMPAIGNS_REJECTED,
  SELECT_CAMPAIGN_FULFILLED,
  SELECT_CAMPAIGN_REJECTED,
  SELECT_CAMPAIGN_REQUESTED,
  SELECT_STEP_FULFILLED,
  SELECT_STEP_FULFILLED_ANDROID,
  SET_CAMPAIGN_CLEAN,
  SET_CAMPAIGN_DIRTY,
  SAVE_CAMPAIGN_FULFILLED,
  SAVE_CAMPAIGN_REJECTED,
  SAVE_CAMPAIGN_REQUESTED,
  PUBLISH_CAMPAIGN_REQUESTED,
  PUBLISH_CAMPAIGN_FULFILLED,
  PUBLISH_CAMPAIGN_REJECTED,
  INIT_CURRENT_CAMPAIGN_DRAFT,
  EDIT_CURRENT_CAMPAIGN_DRAFT,
  EDIT_CURRENT_CAMPAIGN_DRAFT_ANDROID,
  EDIT_CAMPAIGN_STATUS_FULFILLED,
  EDIT_CAMPAIGN_STATUS_REJECTED,
  EDIT_CAMPAIGN_STATUS_REQUESTED,
  SELECT_SCREEN_FULFILLED,
  SELECT_CAMPAIGN_FULFILLED_ANDROID,
  INIT_CURRENT_CAMPAIGN_DRAFT_ANDROID,
  SELECT_CAMPAIGN_FULFILLED_WEB,
  INIT_CURRENT_CAMPAIGN_DRAFT_WEB,
  SELECT_STEP_FULFILLED_WEB,
  EDIT_CURRENT_CAMPAIGN_DRAFT_WEB,
} from "../constants/actions";

import { NAME, TITLE, DESCRIPTION, AUDIENCE } from "../constants/android";

import { toast } from "react-toastify";
toast.configure();

// TODO - revert for RN.
const tooltipStyle = {
  actionView: {
    backgroundColor: "#3d72dc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 90,
    height: 30,
    borderRadius: 4,
  },
  wrapper: {
    borderColor: "#ffffff",
    borderWidth: 1,
    borderRadius: 5,
    zIndex: 4,
  },
  actionText: { color: "white" },
  skipActionText: {
    fontSize: 16,
    color: "rgb(217 217 217)",
    fontWeight: "bold",
  },
  skipAction: {
    bottom: 58,
    left: 15,
    zIndex: 2,
    fontSize: 16,
    fontWeight: "bold",
    position: "absolute",
  },
  primaryText: {
    top: 113.61904999999999,
    left: 17.142857,
    color: "#747474",
    fontSize: 16,
  },
  secondaryText: {
    top: 113.61904999999999,
    left: 17.142857,
    color: "#747474",
    fontSize: 16,
  },
  focussedView: {
    top: 143.61905,
    left: 17.142857,
    width: 115.04762,
    height: 115.04762,
  },
  indexView: { color: "#747474" },
};

// TODO - Break down reducers & combine later.
const initialState = {
  isLoading: false,
  data: [],
  error: null,
  currentCampaign: null,
  currentCampaignDraft: {
    isSpotlight: true,
    type: 1,
    tooltipDirection: 0,
    name: "",
    isLinked: true,
    isSkippable: true,
    showStepNumber: true,
    useDefaultStyle: true,
    addCustomStyle: true,
    scrollToView: false,
    primaryText: "",
    secondaryText: "",
    skipAction: "",
    actionText: "",
    code: "",
    style: {
      actionView: {},
      actionText: {},
      primaryText: {},
      secondaryText: {},
      skipActionText: {},
      skipAction: {},
      indexView: {},
    },
    controls: {
      // TODO - Initialise with whats there in DB.
      primaryText: true,
      secondaryText: true,
      skipAction: true,
      actionText: true,
    },
  },
  currentStepIndex: 0,
  currentScreenIndex: 0,
  currentCampignIsDirty: false,
};

export default (state = initialState, action) => {
  let currentCampaignDetails = null;

  switch (action.type) {
    case FETCH_CAMPAIGNS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        currentCampignIsDirty: false,
        pendingPublish: false,
        data: [],
      };
    case FETCH_CAMPAIGNS_REJECTED:
      return {
        ...state,
        isLoading: false,
        currentCampignIsDirty: false,
        pendingPublish: false,
        data: [],
        error: action.payload,
      };

    case FETCH_CAMPAIGNS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        currentCampignIsDirty: false,
        data: action.payload,
      };
    case SELECT_CAMPAIGN_REQUESTED:
      return {
        ...state,
        isLoading: true,
        currentCampignIsDirty: false,
        pendingPublish: false,
      };
    case SELECT_CAMPAIGN_REJECTED:
      return {
        ...state,
        isLoading: false,
        currentCampignIsDirty: false,
        pendingPublish: false,
      };
    case SELECT_CAMPAIGN_FULFILLED_ANDROID:
      let idAndroid = action.payload;
      let selectedAndroid = state.data.filter((w) => w.id == idAndroid)[0];
      if (idAndroid && selectedAndroid) {
        currentCampaignDetails = selectedAndroid;
      }
      if (!currentCampaignDetails) return { ...state, isLoading: false };

      return {
        ...state,
        isLoading: false,
        currentCampignIsDirty: false,
        pendingPublish: false,
        currentCampaign: selectedAndroid,
        currentStepIndex: 0,
        currentCampaignDraft: selectedAndroid,
      };

    case SELECT_CAMPAIGN_FULFILLED_WEB: {
      // TODO - Modify WEB to this.
      let id = action.payload;
      let selected = state.data.filter((w) => w.id == id)[0];
      console.log("TEST", selected);
      if (id && selected) {
        if (selected.details && selected.details.length > 0) {
          currentCampaignDetails = selected.details[0].steps[0].view;
        }
      }
      if (!currentCampaignDetails) {
        toast.warn(
          "The campaign has no steps. Please make sure you select elements before publishing."
        );
        return { ...state, isLoading: false };
      }
      let initScreenIndex = 0;
      return {
        ...state,
        isLoading: false,
        currentCampaign: selected,
        currentStepIndex: 0,
        pendingPublish: false,
        currentCampignIsDirty: false,
        currentScreenIndex: initScreenIndex,
        name: selected.name || "Untitled Campaign",
        percentageExposure: selected.percentageExposure,
        userRules: selected.userRules,
        currentCampaignDraft: {
          [0]: {
            isSpotlight: currentCampaignDetails.isSpotlight,
            isLinked: true,
            isSkippable: true,
            type: selected.details[initScreenIndex].type,
            tooltipDirection: currentCampaignDetails.direction,
            tooltipStyle: currentCampaignDetails.tooltipStyle || tooltipStyle,
            showStepNumber: selected.details[initScreenIndex].showIndexView,
            useDefaultStyle: true,
            addCustomStyle: true,
            screenName: selected.details[initScreenIndex].screenId,
            // TODO - Get this data from extension while saving.
            headerImage: currentCampaignDetails.content.headerImage,
            primaryText: currentCampaignDetails.content.primaryText,
            secondaryText: currentCampaignDetails.content.secondaryText,
            skipAction: currentCampaignDetails.content.skipAction,
            actionText: currentCampaignDetails.content.actionText,
            code: JSON.stringify(
              currentCampaignDetails.style || tooltipStyle,
              null,
              2
            ),
            style: {
              ...currentCampaignDetails.style,
            },
            controls: initialState.currentCampaignDraft.controls,
          },
        },
      };
    }
    case SELECT_CAMPAIGN_FULFILLED:
      // Add android specific for this
      let id = action.payload;
      let selected = state.data.filter((w) => w.id == id)[0];
      if (id && selected) {
        currentCampaignDetails = JSON.parse(selected.details[0].steps[0].view);
      }
      if (!currentCampaignDetails) return { ...state, isLoading: false };
      let initScreenIndex = 0;

      let left =
        selected.details[0].steps[0].left + selected.details[0].steps[0].width >
        360
          ? 350 - selected.details[0].steps[0].width > 0
            ? 350 - selected.details[0].steps[0].width
            : 5
          : selected.details[0].steps[0].left;

      let top =
        selected.details[0].steps[0].top + selected.details[0].steps[0].height >
        560
          ? 550 - selected.details[0].steps[0].height > 0
            ? 550 - selected.details[0].steps[0].height
            : 5
          : selected.details[0].steps[0].top;

      return {
        ...state,
        isLoading: false,
        currentCampaign: selected,
        currentStepIndex: 0,
        currentCampignIsDirty: false,
        currentScreenIndex: initScreenIndex,
        currentCampaignDraft: {
          [0]: {
            isSpotlight: true,
            isLinked: true,
            isSkippable: true,
            type: selected.details[initScreenIndex].type,
            tooltipDirection:
              selected.details[initScreenIndex].steps[0].tooltipDirection || 0,
            tooltipStyle:
              selected.details[initScreenIndex].type == 0
                ? currentCampaignDetails.tooltipStyle || tooltipStyle
                : currentCampaignDetails.style,
            showStepNumber:
              selected.details[initScreenIndex].steps[0].showIndexView,
            useDefaultStyle: true,
            addCustomStyle: true,
            name: selected.name,
            percentageExposure: selected.percentageExposure,
            userRules: selected.userRules,
            screenName: selected.details[initScreenIndex].screenId,
            scrollToView:
              selected.details[initScreenIndex].steps[0].scrollToView,
            primaryText: currentCampaignDetails.content.primaryText,
            secondaryText: currentCampaignDetails.content.secondaryText,
            skipAction: currentCampaignDetails.content.skipAction,
            actionText: currentCampaignDetails.content.actionText,
            code:
              selected.details[state.currentScreenIndex].type == 0
                ? JSON.stringify(
                    currentCampaignDetails.tooltipStyle || tooltipStyle,
                    null,
                    2
                  )
                : JSON.stringify(currentCampaignDetails.style, null, 2),
            style: {
              ...currentCampaignDetails.style,
              focussedView: {
                top: selected.details[0].steps[0].scrollToView ? 120 : top,
                left: left,
                width: Math.min(selected.details[0].steps[0].width, 350),
                height: Math.min(selected.details[0].steps[0].height, 550),
              },
            },
            controls: initialState.currentCampaignDraft.controls,
          },
        },
      };
    case SELECT_STEP_FULFILLED:
      return {
        ...state,
        isLoading: false,
        currentStepIndex: action.payload,
        currentCampignIsDirty: false,
      };
    case SELECT_STEP_FULFILLED_WEB:
      return {
        ...state,
        isLoading: false,
        currentStepIndex: action.payload,
        currentCampignIsDirty: false,
      };
    case SELECT_SCREEN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        currentScreenIndex: action.payload,
        currentStepIndex: 0,
        currentCampignIsDirty: false,
      };

    case SET_CAMPAIGN_DIRTY:
      return {
        ...state,
        currentCampignIsDirty: true,
      };
    case SET_CAMPAIGN_CLEAN:
      return {
        ...state,
        currentCampignIsDirty: false,
      };
    case SAVE_CAMPAIGN_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case SAVE_CAMPAIGN_REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SAVE_CAMPAIGN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        currentCampignIsDirty: false,
        pendingPublish: true,
      };
    case PUBLISH_CAMPAIGN_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case PUBLISH_CAMPAIGN_REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PUBLISH_CAMPAIGN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        pendingPublish: false,
      };
    case INIT_CURRENT_CAMPAIGN_DRAFT_ANDROID:
      return state;
    case INIT_CURRENT_CAMPAIGN_DRAFT_WEB: {
      if (!state.currentCampaign) return state;
      let currentStepIndex = state.currentStepIndex;
      let currentScreenIndex = state.currentScreenIndex;
      currentCampaignDetails =
        state.currentCampaign.details[currentScreenIndex].steps[
          currentStepIndex
        ].view;
      let style = {
        ...currentCampaignDetails.style,
      };
      return {
        ...state,
        currentCampaignDraft: {
          ...state.currentCampaignDraft,
          [currentStepIndex]: {
            isSpotlight: currentCampaignDetails.isSpotlight,
            isLinked: true,
            isSkippable: true,
            type: state.currentCampaignDraft[0].type,
            tooltipStyle: currentCampaignDetails.tooltipStyle,
            tooltipDirection: currentCampaignDetails.direction,
            showStepNumber:
              state.currentCampaign.details[currentScreenIndex].steps[
                currentStepIndex
              ].showIndexView,
            useDefaultStyle: true,
            addCustomStyle: true,
            screenName:
              state.currentCampaign.details[currentScreenIndex].screenId,
            scrollToView:
              state.currentCampaign.details[currentScreenIndex].steps[
                currentStepIndex
              ].scrollToView,
            headerImage: currentCampaignDetails.content.headerImage,
            primaryText: currentCampaignDetails.content.primaryText,
            secondaryText: currentCampaignDetails.content.secondaryText,
            skipAction: currentCampaignDetails.content.skipAction,
            actionText: currentCampaignDetails.content.actionText,
            code: JSON.stringify(currentCampaignDetails.style, null, 2),
            style: style,
            controls: {
              // TODO - Initialise with whats there in DB.
              primaryText: true,
              secondaryText: true,
              skipAction: true,
              actionText: true,
              indexView: true,
            },
          },
        },
      };
    }
    case INIT_CURRENT_CAMPAIGN_DRAFT:
      if (!state.currentCampaign) return state;
      let currentStepIndex = state.currentStepIndex;
      let currentScreenIndex = state.currentScreenIndex;
      currentCampaignDetails = JSON.parse(
        state.currentCampaign.details[currentScreenIndex].steps[
          currentStepIndex
        ].view
      );

      left =
        state.currentCampaign.details[currentScreenIndex].steps[
          currentStepIndex
        ].left +
          state.currentCampaign.details[currentScreenIndex].steps[
            currentStepIndex
          ].width >
        360
          ? 350 -
              state.currentCampaign.details[currentScreenIndex].steps[
                currentStepIndex
              ].width >
            0
            ? 350 -
              state.currentCampaign.details[currentScreenIndex].steps[
                currentStepIndex
              ].width
            : 5
          : state.currentCampaign.details[currentScreenIndex].steps[
              currentStepIndex
            ].left;

      top =
        state.currentCampaign.details[currentScreenIndex].steps[
          currentStepIndex
        ].top +
          state.currentCampaign.details[currentScreenIndex].steps[
            currentStepIndex
          ].height >
        560
          ? 550 -
              state.currentCampaign.details[currentScreenIndex].steps[
                currentStepIndex
              ].height >
            0
            ? 550 -
              state.currentCampaign.details[currentScreenIndex].steps[
                currentStepIndex
              ].height
            : 5
          : state.currentCampaign.details[currentScreenIndex].steps[
              currentStepIndex
            ].top;

      let style = {
        ...currentCampaignDetails.style,
        indexView: currentCampaignDetails.style.indexView || {
          position: "absolute",
          top: 90,
          left: 50,
          color: "#65f4d4",
          fontSize: 30,
          fontWeight: "bold",
        },
        focussedView: {
          top: state.currentCampaign.details[currentScreenIndex].steps[
            currentStepIndex
          ].scrollToView
            ? 120
            : top,
          left: left,
          width: Math.min(
            state.currentCampaign.details[currentScreenIndex].steps[
              currentStepIndex
            ].width,
            350
          ),
          height: Math.min(
            state.currentCampaign.details[currentScreenIndex].steps[
              currentStepIndex
            ].height,
            550
          ),
        },
      };
      return {
        ...state,
        currentCampaignDraft: {
          ...state.currentCampaignDraft,
          [currentStepIndex]: {
            isSpotlight: true,
            isLinked: true,
            isSkippable: true,
            type: state.currentCampaignDraft[0].type,
            tooltipStyle:
              state.currentCampaign.details[currentScreenIndex].type == 0
                ? currentCampaignDetails.tooltipStyle || tooltipStyle
                : currentCampaignDetails.style,
            tooltipDirection:
              state.currentCampaign.details[currentScreenIndex].steps[
                currentStepIndex
              ].tooltipDirection || 0,
            showStepNumber:
              state.currentCampaign.details[currentScreenIndex].steps[
                currentStepIndex
              ].showIndexView,
            useDefaultStyle: true,
            addCustomStyle: true,
            name: state.currentCampaign.name,
            percentageExposure: state.currentCampaign.percentageExposure,
            userRules: state.currentCampaign.userRules,
            screenName:
              state.currentCampaign.details[currentScreenIndex].screenId,
            scrollToView:
              state.currentCampaign.details[currentScreenIndex].steps[
                currentStepIndex
              ].scrollToView,
            primaryText: currentCampaignDetails.content.primaryText,
            secondaryText: currentCampaignDetails.content.secondaryText,
            skipAction: currentCampaignDetails.content.skipAction,
            actionText: currentCampaignDetails.content.actionText,
            code:
              state.currentCampaign.details[state.currentScreenIndex].type == 0
                ? JSON.stringify(
                    currentCampaignDetails.tooltipStyle || tooltipStyle,
                    null,
                    2
                  )
                : JSON.stringify(currentCampaignDetails.style, null, 2),
            style: style,
            controls: {
              // TODO - Initialise with whats there in DB.
              primaryText: true,
              secondaryText: true,
              skipAction: true,
              actionText: true,
              indexView: true,
            },
          },
        },
      };

    case EDIT_CURRENT_CAMPAIGN_DRAFT_ANDROID:
      let androidDraft = state.currentCampaignDraft;

      switch (action.payload.changeType) {
        case NAME:
          androidDraft.name = action.payload.changeValue;
          break;
        case AUDIENCE:
          androidDraft.percentageExposure = action.payload.changeValue;
          break;
        case TITLE:
          androidDraft.details[action.payload.stepIndex].stepTitle =
            action.payload.changeValue;
          break;
        case DESCRIPTION:
          androidDraft.details[action.payload.stepIndex].stepDescription =
            action.payload.changeValue;
          break;
      }

      return {
        ...state,
        currentCampignIsDirty: true,
        currentCampaignDraft: androidDraft,
      };

    case EDIT_CURRENT_CAMPAIGN_DRAFT_WEB: {
      console.log("Add here", action.payload);

      let newStyle = null;
      let newControls = null;
      let newTooltipStyle = null;
      let newTooltipDirection =
        state.currentCampaignDraft[state.currentStepIndex].tooltipDirection;
      let newSpotlight =
        state.currentCampaignDraft[state.currentStepIndex].isSpotlight;

      if (action.payload.tooltipStyle) {
        let key = Object.keys(action.payload.tooltipStyle)[0];
        let remainingStyleForKey = {
          ...state.currentCampaignDraft[state.currentStepIndex].tooltipStyle[
            key
          ],
          ...action.payload.tooltipStyle[key],
        };
        newTooltipStyle = {
          ...state.currentCampaignDraft[state.currentStepIndex].tooltipStyle,
          ...{ [key]: remainingStyleForKey },
        };
      }

      if (action.payload.controls) {
        newControls = {
          ...state.currentCampaignDraft[state.currentStepIndex].controls,
          ...action.payload.controls,
        };
      }
      if (action.payload.tooltipDirection) {
        newTooltipDirection = (newTooltipDirection + 1) % 4;
      }
      if (action.payload.hasOwnProperty("isSpotlight")) {
        newSpotlight = action.payload.isSpotlight;
      }

      return {
        ...state,
        ...action.payload,
        currentCampignIsDirty:
          action.payload.controls || action.payload.type >= 0 ? false : true,
        currentCampaignDraft: {
          ...state.currentCampaignDraft,
          ...action.payload,
          [state.currentStepIndex]: {
            ...state.currentCampaignDraft[state.currentStepIndex],
            ...action.payload,
            tooltipDirection: newTooltipDirection,
            isSpotlight: newSpotlight,
            style: action.payload.code
              ? JSON.parse(action.payload.code)
              : newStyle
              ? newStyle
              : state.currentCampaignDraft[state.currentStepIndex].style,
            tooltipStyle: newTooltipStyle
              ? newTooltipStyle
              : state.currentCampaignDraft[state.currentStepIndex].tooltipStyle,
            code: JSON.stringify(
              newTooltipStyle
                ? newTooltipStyle
                : state.currentCampaignDraft[state.currentStepIndex]
                    .tooltipStyle,
              null,
              2
            ),
            controls: newControls
              ? newControls
              : state.currentCampaignDraft[state.currentStepIndex].controls,
          },
        },
      };
    }
    case EDIT_CURRENT_CAMPAIGN_DRAFT:
      // Add android
      let newStyle = null;
      let newControls = null;
      let newTooltipStyle = null;
      let newTooltipDirection =
        state.currentCampaignDraft[state.currentStepIndex].tooltipDirection;
      if (action.payload.style) {
        let key = Object.keys(action.payload.style)[0];
        let remainingStyleForKey = {
          ...state.currentCampaignDraft[state.currentStepIndex].style[key],
          ...action.payload.style[key],
        };
        newStyle = {
          ...state.currentCampaignDraft[state.currentStepIndex].style,
          ...{ [key]: remainingStyleForKey },
        };
      }

      console.log(action.payload);
      if (action.payload.tooltipStyle) {
        let key = Object.keys(action.payload.tooltipStyle)[0];
        let remainingStyleForKey = {
          ...state.currentCampaignDraft[state.currentStepIndex].tooltipStyle[
            key
          ],
          ...action.payload.tooltipStyle[key],
        };
        newTooltipStyle = {
          ...state.currentCampaignDraft[state.currentStepIndex].tooltipStyle,
          ...{ [key]: remainingStyleForKey },
        };
      }

      if (action.payload.controls) {
        newControls = {
          ...state.currentCampaignDraft[state.currentStepIndex].controls,
          ...action.payload.controls,
        };
      }
      if (action.payload.tooltipDirection) {
        newTooltipDirection = (newTooltipDirection + 1) % 4;
      }
      // FIXME - [Bug] fix left & top style change together.
      return {
        ...state,
        currentCampignIsDirty:
          action.payload.controls || action.payload.type >= 0 ? false : true,
        currentCampaignDraft: {
          ...state.currentCampaignDraft,
          ...action.payload,
          [state.currentStepIndex]: {
            ...state.currentCampaignDraft[state.currentStepIndex],
            ...action.payload,
            tooltipDirection: newTooltipDirection,
            style: action.payload.code
              ? JSON.parse(action.payload.code)
              : newStyle
              ? newStyle
              : state.currentCampaignDraft[state.currentStepIndex].style,
            tooltipStyle: newTooltipStyle
              ? newTooltipStyle
              : state.currentCampaignDraft[state.currentStepIndex].tooltipStyle,
            code:
              state.currentCampaign.details[state.currentScreenIndex].type == 0
                ? newTooltipStyle
                  ? JSON.stringify(newTooltipStyle, null, 2)
                  : state.currentCampaignDraft[state.currentStepIndex].code
                : newStyle
                ? JSON.stringify(newStyle, null, 2)
                : state.currentCampaignDraft[state.currentStepIndex].code,
            controls: newControls
              ? newControls
              : state.currentCampaignDraft[state.currentStepIndex].controls,
          },
        },
      };
    case EDIT_CAMPAIGN_STATUS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_CAMPAIGN_STATUS_REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_CAMPAIGN_STATUS_FULFILLED:
      let currentId = action.payload.campaignId;
      let newData = [];
      for (let index = 0; index < state.data.length; index++) {
        let element = state.data[index];
        if (element.id == currentId) {
          element.status = action.payload.newStatus;
        }
        newData.push(element);
      }
      return {
        ...state,
        isLoading: false,
        data: newData,
      };
    default:
      return state;
  }
};
