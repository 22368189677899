export const WEBAPP_API_BASE_URL =
  "https://asia-south1-flowdash-273907.cloudfunctions.net/flowdash-app-api/";
export const CORE_API_BASE_URL = "https://api.flowdash.ai/";
// export const CORE_API_BASE_URL = "http://192.168.99.109:9099/"
export const MEMBER_API =
  "https://8awez44qok.execute-api.ap-south-1.amazonaws.com/default/";
export const STRIPE_API_KEY =
  "pk_live_51IJbyXLyoYTcHFeh03AetM3heAz1wRn9SpvR254PBXoowSIdejrQf7TQsLKd3ycG2SooElaHytiJkn5PdbH48mut00a3OtNcOo";

export const HOUSEWARE_NOTIFY_URL = "https://8awez44qok.execute-api.ap-south-1.amazonaws.com/default/houseware/notify";
