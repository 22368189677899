import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

export default function ValueLabelComponent(props) {
    const { children, open, value, suffix } = props;

    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            placement="top"
            title={suffix ? value + suffix : value + "px"}
        >
            {children}
        </Tooltip>
    );
}